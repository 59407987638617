<template>
  <the-public-modal @close="$emit('close')">
    <div class="title">
      <img src="../../assets/public/Attention_perspective_matte.png" alt="">
      <h3>Your order has been successfully removed</h3>
    </div>

    <div class="btns">
      <button @click="$emit('close')" class="green-btn">Ok</button>
    </div>
  </the-public-modal>
</template>

<script>
import ThePublicModal from "./the-public-modal";

export default {
  name: "the-deletRequest-modal",
  components: {ThePublicModal}
}
</script>

<style scoped>
.title {
  height: 200px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.title img{
  width: 50px;
}

.btns {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  align-items: center;
}
</style>
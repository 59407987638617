<template>
  <div class="main-info">
    <span class="title-info">Personal Verification Level 2</span>
    <the-basic-info :data.sync="userInfo"/>
    <the-bankinfo :data.sync="userInfo"/>
    <the-faciallverification :data.sync="userInfo"/>
    <div class="btn-submit">

      <button @click="submit" v-if="userInfo.authenticationType !=='AUTHORIZED'" class="purple-btn">
        Submit
      </button>

      <button @click="submit" v-if="userInfo.authenticationType === 'AUTHORIZED'" class="purple-btn">
        Submit Changes
      </button>

    </div>
  </div>
</template>

<script>
import TheBasicInfo from "./basicinfo/the-basic-info";
import TheBankinfo from "./bankinfo/the-bankinfo";
import TheFaciallverification from "./faciallverification/the-faciallverification";
export default {
  name: "the-profile",
  components: {TheFaciallverification, TheBankinfo, TheBasicInfo},

  data(){
    return{
      change: 0,
      userInfo: {
        authenticationType: '',
        firstName: '',
        lastName: '',
        email: '',
        nationalCode: '',
        birthDay: '',
        mobileNumber: '',
        telephone: '',
        address: '',
        bankAccounts: [{shebaCode: "", cardNumber: "", bankName: ""}],
        image: '',
      },
    }
  },

  methods:{

    removeDash(items) {
      for (let item of items) {
        item.cardNumber = item.cardNumber.replace(/-/gi, '');
      }
    },

    bankCheck(items) {
      for (let item of items) {
        if (!item.cardNumber || !item.shebaCode) {
          this.$error('Incomplete information', "You did not enter your Sheba number or bank card");
          return false;
        } else {
          return true;
        }
      }
    },

    //get user info from server
    async getUserInfo(){
      this.state.loading = true;
      const res = await this.$axios("/users/account",{params:{'image':true}})
      if(res){
        this.userInfo = res.baseDTO;
        this.state.userInfo = res.baseDTO;
        this.change++
        this.state.userInfo.authorized = true
      }
    },

    //send user info to server
    async submit() {
      console.log('true11111111111111111')
      this.state.loading = true;
      this.removeDash(this.userInfo.bankAccounts);
      let form_data = new FormData();

      for (let key in this.userInfo) {
        if (key === 'image') {
          if (typeof this.userInfo.image !== 'string') {
            form_data.append("file", this.userInfo.image);
          }
        } else {
          form_data.append(key, this.userInfo[key]);
        }
      }

      form_data.append("bankAccountsString", JSON.stringify(this.userInfo.bankAccounts));

      if (this.bankCheck(this.userInfo.bankAccounts)) {
        try {
          let vm = this
          const config = {
            onUploadProgress: function (progressEvent) {
              vm.state.progress = Math.round((progressEvent.loaded * 100) / progressEvent.total)
            }
          }
          const res = await this.$axios.post("/users/account", form_data, config)

          this.state.userInfo.authenticationType = res.baseDTO.authenticationType
          this.state.userInfo.email = res.baseDTO.email
          this.state.userInfo.authorized = res.baseDTO.authenticationType === "AUTHORIZED"
          this.state.userInfo.pending = res.baseDTO.authenticationType === "PENDING"
          await this.$error('', 'Information saved successfully', 'success')

          if (res.message.includes("need to be verified")) {
            this.state.loading = true
            await this.$axios(`/finotech/send-sms-to-customer?email=${this.userInfo.email}`)
            this.$router.push('/fino-verifying')
          } else {
            this.$getuserInfo()
          }
        } catch (e) {
          if (e.response && e.response.data.message.includes("ShebaCode or CardNumber Is For Another User")) {
            this.$error('', ' Sheba number or card has already been entered.')
            this.duplicateBank = e.response.data.message
          }
        }
      }
      this.state.loading = false;
    },

  },

  mounted() {
    this.getUserInfo()
  }
}
</script>

<style lang="scss" scoped>
//main style
.main-info{
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 16px;

  .btn-submit{
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;

    button{
      width: 235px !important;
      height: 44px !important;
    }
  }
}

</style>

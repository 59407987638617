<template>
  <the-public-modal  @close="$emit('close')">
    <div class="Exchange">

      <span class="title">Select Currency</span>

      <div class="search">
        <img src="../../assets/panel/search.png" alt="">
        <input v-model="searchQuery" type="search" class="search-input" placeholder="Search currencies...">
      </div>

      <div class="items">
        <div  @click="selectCoin(item , index)" v-for="(item , index) in wallets" class="item" :key="index">
          <div v-if="item[0] !== 'TOMAN'" class="left-item">
            <div class="logo">
              <img  :src="require('../../assets/coinsName/'+ item[0] + '.png')" alt="">
            </div>
            <div class="text">
              <span>{{ $coinUnit[item[0]] }}</span>
              <small>{{item[0]}}</small>
            </div>
          </div>

          <div v-if="item[0] !== 'TOMAN'" class="right-item">
            <span class="value">{{item[1].wholePriceOfCredit}}</span>
          </div>

        </div>
      </div>
    </div>
  </the-public-modal>

</template>

<script>
import ThePublicModal from "./the-public-modal";

export default {
  name: "the-exchange-modal",
  components: {ThePublicModal},

  data(){
    return{
      searchQuery:"",

      wallets:[]
    }
  },

  computed: {
    resultQuery(){
      if(this.searchQuery){
        return this.coinsdata.filter((item)=>{
          return this.searchQuery.toLowerCase().split(' ').every(v => item.name.toLowerCase().includes(v))
        })
      }else{
        return this.coinsdata;
      }
    },

  },

  methods:{
    async getWallets() {
      // const coinsList="ETHEREUM,BITCOIN_CASH,TRON,RIPPLE,TETHER,TOMAN,BITCOIN"
      this.state.loading = true
      const [res,] = await this.$http.get('/wallets/customer-addresses')
      if (res) {
        let entireRes = Object.entries(res)
        const sortCoins = this.$walletSortCoins
        entireRes.sort((a, b) => sortCoins.indexOf(a[0]) - sortCoins.indexOf(b[0]))
        this.wallets = entireRes;
      }
      this.state.loading = false
    },

    async getCoins(){
      const [res,] = await this.$http.get('/coins');
      this.Allcoins = res.content
    },

    selectCoin(item , index){
      this.$emit('close');
      this.state.getselectedcoin.transferFee = this.Allcoins.find(a => a.coin === this.wallets[index][0]).transferFee;
      this.state.getselectedcoin.coin = this.Allcoins.find(a => a.coin === this.wallets[index][0]).coin;
      this.state.getselectedcoin.walletAdreess = this.wallets[index][1].address;
      this.state.getselectedcoin.maxWithdrawAmount = this.Allcoins.find(a => a.coin === this.wallets[index][0]).maxWithdrawAmount;
      this.state.getselectedcoin.logo = this.$coinUnit[this.wallets[index][0]];
      this.state.getselectedcoin.Network = (this.$coinUnit[this.wallets[index][0]])+ ' ' +'('+(this.wallets[index][0])+')';
      this.state.getselectedcoin.wholePriceOfCredit = this.wallets[index][1].wholePriceOfCredit;
      if(item[0] === 'TETHER'){
        this.state.getselectedcoin.transferFee = 0;
        this.state.getselectedcoin.coin = this.Allcoins.find(a => a.coin === this.wallets[index][0]).coin;
        this.state.getselectedcoin.walletAdreess = null;
        this.state.getselectedcoin.maxWithdrawAmount = 0;
        this.state.getselectedcoin.logo = this.$coinUnit[this.wallets[index][0]];
        this.state.getselectedcoin.Network = null;
        this.state.getselectedcoin.wholePriceOfCredit = this.wallets[index][1].wholePriceOfCredit;
      }
      console.log(this.state.getselectedcoin)
    }

  },

  mounted() {
    this.getWallets();
    this.getCoins();
  }
}
</script>

<style lang="scss" scoped>
.title {
  font-size: 20px;
  color: rgba(72, 77, 86, 1);
  font-weight: 600;
}

.search {
  border: 1px solid #4613A3;
  border-radius: 4px;
  width: 100%;
  height: 40px;
  margin: 24px 0;
  padding: 0 28px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;

  .search-input {
    text-align: left;
    width: 90%;
    margin-left: 10px;
    border: none;
    color: rgb(189, 189, 189);
    outline: none;
  }
}

.items {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor:pointer;

    .left-item {
      display: flex;
      flex-direction: row;
      gap: 16px;

      .logo {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          Width: 32px;
          Height: 32px
        }
      }

      .text {
        display: flex;
        flex-direction: column;

        span {
          color: rgba(72, 77, 86, 1);
          font-size: 16px;
        }

        small {
          color: rgba(72, 77, 86, 1);
        }
      }
    }

    .right-item {

      .value {
        color: rgba(72, 77, 86, 1);
        font-size: 14px;
        font-weight: 600;
      }
    }
  }
}
</style>
<template>
  <div class="Exchange">
    <div class="layer">
      <!--      the title section -->
      <h2 class="titr">Buy and Sell Cryptocurrencies</h2>
      <!--      router view section -->
      <router-view/>
    </div>
  </div>
</template>

<script>

export default {
  name: "the-exchange",
}
</script>

<style lang="scss" scoped>
.Exchange {
  background-image: url("../../assets/public/modal-bg.png");
  background-position: center;
  background-size: cover;
  height: 100vh;

  .layer {
    display: flex;
    flex-direction: column;
    background-color: rgba(70, 19, 163, 0.7);
    justify-content: center;
    align-items: center;
    height: 100vh;

    .titr {
      color: #FFFFFF;
      margin-bottom: 50px;
      text-align: center;
    }


  }
}

@media (max-width: 400px) {
  .box-exchange {
    width: min-content !important;
  }
}
</style>
<template>
  <div class="box-dashboard">
    <div class="header">
      <span class="title">Open Orders</span>
    </div>
    <table class="public-table">
      <tr>
        <th>Date</th>
        <th class="dont-show-630">Pair</th>
        <th class="dont-show-690 dont-show-890 dont-show-992 dont-show-1200">Type</th>
        <th class="dont-show-460">Amount</th>
        <th>Price</th>
        <th class="dont-show-992">Filled</th>
        <th>Operation</th>
      </tr>

      <tr v-if="!tableContents.length">
        <td colspan="8">
          <p class="no-data"> Custom is not registered. </p>
        </td>
      </tr>

      <tr v-for="(item , index) in tableContents" :key="index">
        <td>{{ item.createdAtDateTime }}</td>
        <td class="dont-show-630">{{ item.destinationCoin }}</td>
        <td class="dont-show-690 dont-show-890 dont-show-992 dont-show-1200"
            :class="{
           'success' : item.orderType === 'BUY, LIMITED_BUY,MARKET_BUY,STOP_LIMIT_BUY'  ,
            'danger' : item.orderType === 'LIMITED_SELL , MARKET_SELL, SELL,STOP_LIMIT_SELL'
        }"
        > {{ item.orderType }}
        </td>
        <td class="dont-show-460">{{ item.amount }}</td>
        <td>{{ item.unitPrice }}</td>
        <td class="dont-show-992">{{ item.wholePrice }}</td>
        <td>{{ item.Operation }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  name: "the-order-table",

  data() {
    return {
      tableContents: []
    }
  },

  methods: {
    async getActiveOrders() {
      const res = await this.$axios.get('/orders', {
        params: {
          orderStatus: 'IS_OPEN',
          market: this.state.MarketType,
        }
      })
      this.tableContents = res.content || [];
      this.$emit('update:activeLength', this.tableContents.length)
      this.state.loading = false
    },
  },

  mounted() {
    this.getActiveOrders()
  }
}
</script>

<style lang="scss" scoped>
.success {
  color: rgba(93, 204, 141, 1);
}

.danger {
  color: rgba(242, 53, 126, 1);
}

.box-dashboard {
  display: flex;
  flex-grow: 2;
  flex-basis: 100px;
  flex-direction: column;
  padding: 10px;
  max-height: 293px;
  overflow: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  .header {
    width: 100%;
    margin-bottom: 10px;

    .title {
      color: rgba(102, 102, 102, 1);
      font-size: clamp(1rem, 2.5vw, 1.5rem);
    }
  }

  .public-table {
    table-layout: auto;
  }
}

//  media query
@media (max-width: 1200px) {
  .dont-show-1200 {
    display: none;
  }

}

@media (max-width: 992px) {
  .dont-show-992 {
    display: none;
  }

}

@media (max-width: 890px) {
  .dont-show-890 {
    display: none;
  }

  .box-dashboard {
    flex-basis: auto !important;
  }

}

@media (max-width: 690px) {
  .dont-show-690 {
    display: none;
  }
}

@media (max-width: 630px) {
  .dont-show-630 {
    display: none;
  }
}

@media (max-width: 460px) {
  .dont-show-460 {
    display: none;
  }
}

@media (max-width: 350px) {
  .dont-show-350 {
    display: none;
  }

  table td {
    font-size: 10px !important;
  }
}
</style>
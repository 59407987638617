<template>
  <div class="main-password-change">
    <the-otp-panel-modal :qr-code="qrCode" :qr-link="qrLink" v-if="otppanel === true" @close="otppanel = false" @success="succesmodal =true"/>
    <span class="title-info">
       Security Settings
   </span>

    <div class="setting box-dashboard">
      <div class="row-setting">
        <div class="lable">
          <img src="../../../../assets/panel/lock.png" alt="">
          <span>Password</span>
        </div>
        <div class="range-password">
          <VuePassword
              v-model="password1"
              id="password1"
              :strength="strength"
              type="text"
              :strengthMessages="strengthMessages"
          />

          <div>
            <span v-if="strength === 1">{{ strengthMessages[0] }}</span>
            <span v-if="strength === 2">{{ strengthMessages[1] }}</span>
            <span v-if="strength === 3">{{ strengthMessages[2] }}</span>
            <span v-if="strength === 4">{{ strengthMessages[3] }}</span>
          </div>

        </div>
        <div class="btn">
          <button @click="$emit('resetpassmodal')">Reset</button>
        </div>
      </div>

      <div class="border"></div>


      <div class="row-setting">
        <div class="lable">
          <img src="../../../../assets/panel/totp.png" alt="">
          <span>TOTP Authentication</span>
        </div>
        <div class="range-password">
          <span class="set">Set</span>
        </div>
        <div class="btn">
          <button @click="showModal">Change</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VuePassword from 'vue-password';
import TheOtpPanelModal from "./the-otp-panel-modal";
export default {
  name: "the-security-setting",
  components: {
    TheOtpPanelModal,
    VuePassword,
  },

  data(){
    return{
      twoStep: 'GOOGLE_AUTHENTICATOR',
      qrCode: '',
      qrLink:'',
      resetpassmodal:false,
      otppanel : false,
      succesmodal:false,
      password1:"",
      strength:2,
      strengthMessages:['weak', 'medium', 'good', 'great'],
      modal:false,
    }
  },

  methods:{

    async getTwoStepAuth() {
      this.twoStep = this.state.userInfo.towStepAuthenticationType
    },

    async showModal() {
      this.state.loading = true
      const res = await this.$axios.post(`/users/send-verification-code?twoStepAuthorization=GOOGLE_AUTHENTICATOR`).catch(() => {
        this.getTwoStepAuth()
      })
      if (res) {
        this.qrCode = res.baseDTO.file || ''
        this.qrLink = res.baseDTO.qrCodeLink || ''
        this.otppanel = true
      }
    },
  },

}
</script>

<style lang="scss" scoped>
.main-password-change {
  display: flex;
  flex-direction: column;
  gap: 40px;

  .setting{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }

  .row-setting {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px 19px;
    flex-wrap: wrap;
    gap: 20px;

    .lable {
      display: flex;
      justify-content: start;
      align-items: center;
      gap: 10px;
      flex-grow: 1;
      flex-basis: 100px;

      span {
        color: rgba(102, 102, 102, 1);
        font-size: 16px;
      }

      img {
        opacity: 0.7;
      }
    }

    .range-password {
      display: flex;
      align-items: center;
      justify-content: start;
      flex-grow: 3;
      flex-basis: 100px;
      flex-wrap: wrap;
      gap: 10px;

      span{
        color: #484D56;
        font-size: clamp(0.7rem, 2.5vw, 0.9rem);
        margin-left: 33px;
      }
    }

    .btn {
      display: flex;
      align-items: center;
      justify-content: end;
      flex-grow: 1;
      flex-basis: 100px;

      button {
        width: 104px;
        height: 32px;
        text-align: center;
        border: 1px solid var(--main-background-color);
        color: var(--main-background-color);
        border-radius: 5px;
        background-color: #FFFFFF;
        cursor: pointer;
      }
    }
  }

  .border{
    border-bottom: 1px solid #F2F2F2;
  }
}

@media (max-width: 592px) {
  .row-setting{
    gap: 40px;
  }
}
</style>
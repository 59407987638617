<template>
  <div class="main-notfications">
    <div class="notif">

      <div class="header">
        <span class="title">Notifications</span>
      </div>

      <p v-if="readmessages || unreadmessages === null " class="noMessage">
        There is no message to display
      </p>

      <div class="drop-items" v-for="(item , index) in readmessages" :key="index">
        <the-drop-notif :items="item" @explainFn="explain = !explain"/>
      </div>

      <div class="drop-items" v-for="(item , index) in unreadmessages" :key="index">
        <the-drop-notif :items="item" @explainFn="explain = !explain"/>
      </div>

    </div>
  </div>
</template>

<script>
import TheDropNotif from "./the-drop-notif";
export default {
  name: "the-notifications",
  components: {TheDropNotif},
  data() {
    return {
      readmessages:[],
      unreadmessages:[]
    }
  },

  methods:{
    async getUnreadMessages(page = this.page, perPage = this.perPage) {
      this.state.loading = true
      const res = await this.$axios.get('/users/notifications',
          {
            params: {
              read: false, page: page, size: perPage,
            }
          })
      this.unreadmessages = res.content
    },
    async getReadMessages(page = this.page, perPage = this.perPage) {
      this.state.loading = true
      const res = await this.$axios.get('/users/notifications',
          {
            params: {
              read: true, page: page, size: perPage,
            }
          })
      this.readmessages = res.content
    },
  },

  mounted() {
    this.getUnreadMessages();
    this.getReadMessages()
  }
}
</script>

<style lang="scss" scoped>
.main-notfications {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .notif {
    gap: 16px;
    width: 90%;
    flex-direction: column;
    display: flex;
    margin: 80px 0;

    .header{
      display: flex;
      justify-content: start;
      margin-bottom: 30px;

      .title{
        font-size: clamp(20px, 2vw , 32px);
        font-weight: bold;
      }
    }
  }
}
</style>
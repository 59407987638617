<template>
  <div class="main-modal"  @click.self="$emit('close')">
    <div class="modal">
      <img @click="$emit('close')" class="close" src="../../assets/public/close.png" alt="">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "the-public-modal",
}
</script>

<style lang="scss" scoped>
.main-modal{
  background-color: rgba(70, 19, 163, 0.7);
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  inset: 0;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  .modal{
    display: flex;
    flex-direction: column;
    gap: 16px;
    background-color: #FFFFFF;
    border-radius: 12px;
    position: relative;
    padding: 16px;
    height: auto;
    width: 33%;
    z-index: 11;
    min-height: 300px;
    max-height: 450px;
    overflow: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    .close{
      position: absolute;
      top: 21px;
      right: 16px;
      width: 15px;
      height: 15px;
      cursor: pointer;
    }
  }

}

@media (max-width: 992px) {
  .modal{
    width: 50% !important;
  }
}

@media (max-width: 760px) {
  .modal{
    width: 60% !important;
  }
}

@media (max-width: 590px) {
  .modal{
    width: 70% !important;
  }
}

@media (max-width: 490px) {
  .modal{
    width: 90% !important;
  }
}
</style>
<template>
  <div class="item top-table">
    <table class="table-1">
      <tr>
        <th class="left-align">Pair</th>
        <th class="center-align">Price</th>
        <th class="right-align">Change</th>
      </tr>

      <tr v-if="!datatable1.length">
        <td colspan="8">
          <p class="no-data"> Custom is not registered. </p>
        </td>
      </tr>

      <tr @click="selectMarketType(item.sourceCoinSymbol)" :class="{'select' : select === item.sourceCoinSymbol}" v-for="(item , index) in datatable1" :key="index">
        <td class="left-align">
          {{ $coin3Snake[item.sourceCoinSymbol] + '/USDT' }}
        </td>

        <td class="center-align">
          {{$toLocal(Number(item.price).toFixed(3))}}
        </td>

        <td :class="{'red' : item.price24hrChangePercent <= 0 , 'green':item.price24hrChangePercent > 0}" class="right-align">
          {{Number(item.price24hrChangePercent).toFixed(3) + '%' }}
        </td>
      </tr>
    </table>
  </div>

</template>

<script>
export default {
  name: "top-table",

  data() {
    return {
      datatable1: [],
      select:""
    }
  },

  methods:{

    selectMarketType(value){
      console.log(value)
      this.state.MarketType = this.$coin2Snake[value];
      this.select = value;
      this.$router.push(value)
      console.log(this.state.MarketType)
    },

    async getMarket() {
      const res = await this.$axios.get('/coins/price/usdt');
      this.datatable1 = res;
      console.log(this.datatable1)
    },

  },

  mounted() {
    this.getMarket();
  }
}
</script>

<style lang="scss" scoped>


.green{
  color: #00A35F !important;
}
.red{
  color: #cc2d6a !important;
}

table {
  margin: 0 auto;
  width: 93%;
  table-layout: fixed;
  overflow: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  cursor: pointer;
}

.table-1 {
  padding: 10px;
  border-collapse: collapse;

  .select{
    background-color: rgb(201, 174, 76, 0.1);
  }

  th {
    color: rgba(189, 189, 189, 1);
    margin-top: 10px;
  }

  td {
    color: rgba(64, 64, 64, 1);
    padding: 5px !important;

  }
}

.top-table {
  flex-grow: 1;
  overflow: auto;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

  th {
    color: rgba(189, 189, 189, 1);
    font-size: clamp(12px, 1vw, 14px);
  }

  td{
    font-size: clamp(10px, 1vw, 13px);
  }
}

.no-data {
  color: #cc2d6a;
  font-weight: bold;
  text-align: center;
  width: 100%;
}
</style>
<template>
  <div class="otc">
    <!--      the modals show-->
    <the-exchange-modal
        v-if="showfrom === true"
        @close="showfrom = false"
        @selectcoin="Receivedatafrom"
    />

    <the-exchange-modal
        v-if="showto === true"
        @close="showto = false"
        @selectcoin="Receivedatato"
    />

    <the-succes-modal v-if="showmodlsuccess === true" @close="showmodlsuccess = false"
                      title1="Convert Succeeded" otp="otp" btn-text="Done"
    />

    <div class="layer">
      <!--      the title section -->
      <h2 class="titr">Convert & OTC Portal</h2>

      <small class="small">Trade Bitcoin, BNB, and other Cryptocurrencies easily in one go</small>

      <!--      the exchange box section -->
      <div class="box-exchange">

        <!--     the inputs box-exchange-->
        <div class="inputs">
          <div class="info">
            <div class="texts">
              <span class="left-text">From</span>
              <span class="right-text">Available:</span>
            </div>
            <the-otc-input @change="enable = true" placeholder="Please enter 20-20,000" :data="from" @showotcModal="showfrom = true"/>
          </div>

          <div class="btn">
            <img src="../../assets/panel/change-btn.png" alt="">
          </div>

          <div class="info">
            <span class="right-text">To</span>
            <the-otc-input placeholder="Please enter 0.0004-2" :data="to" @showotcModal="showto = true"/>
            <div class="texts">
              <span class="left-text">Price</span>
              <span class="left-text">1 USDT  = 0.00002913 BTC</span>
            </div>

            <div class="texts">
              <span class="left-text">Inverse Price</span>
              <span class="left-text">1 BTC  = 38,761,018 USDT</span>
            </div>

            <div class="texts" v-if="enable === true">
              <span class="left-text">You will Recieve</span>
              <span class="left-text-gold">1 BTC  = 38,761,018 USDT</span>
            </div>

          </div>
          <button @click="showmodlsuccess = true" :class="{'disable': enable === false}" class="gold-btn">
            <span v-if="enable === false">Preview Convert</span>
            <span v-if="enable === true">Convert(5)</span>
          </button>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import TheOtcInput from "./the-otc-input";
import TheExchangeModal from "../tools/the-exchange-modal";
import TheSuccesModal from "../tools/the-succes-modal";

export default {
  name: "the-convert",
  components: {TheSuccesModal, TheExchangeModal, TheOtcInput},

  data() {
    return {
      showfrom: false,
      showto: false,
      enable:false,
      showmodlsuccess:false,
      from: {name: "Bitcoin", nickname: "BTC", value: 34277927, logo: "BCH.png"},
      to: {name: "Bitcoin", nickname: "BTC", value: 34277927, logo: "BCH.png"}
    }
  },

  methods: {
    Receivedatafrom(e) {
      this.from = e
    },

    Receivedatato(e) {
      this.to = e
    }
  }
}
</script>

<style lang="scss" scoped>
.otc {
  background-image: url("../../assets/Exchange/Exchange-bg.png");
  background-position: center;
  background-size: cover;
  height: 130vh;

  .layer {
    display: flex;
    flex-direction: column;
    background-color: rgba(70, 19, 163, 0.7);
    justify-content: center;
    align-items: center;
    height: 130vh;

    .titr {
      color: #FFFFFF;
      margin-bottom: 10px;
      text-align: center;
    }

    .small{
      margin-bottom: 50px;
      color: #FFFFFF;
    }

    .box-exchange {
      background-color: #FFFFFF;
      border-radius: 12px;
      display: flex;
      flex-direction: column;
      padding: 16px;
      width: auto;

      .disable{
        @extend .gold-btn;
        opacity: 0.5;
        cursor: not-allowed;
      }

      .gold-btn{
        color: #FFFFFF;
      }

      .inputs {
        display: flex;
        flex-direction: column;
        gap: 30px;

        .btn{
          display: flex;
          justify-content: center;
          align-items: center;

          img{
            cursor: pointer;
          }
        }

        .info {
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          gap: 8px;

          .texts {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: 16px;

            .left-text {
              color: rgba(72, 77, 86, 1);
              font-size: 14px;
            }

            .left-text-gold{
              @extend .left-text;
              color: #C2A748 !important;
              font-weight: 600;
            }

            .right-text {
              color: rgba(130, 130, 130, 1);
              font-size: 14px;
            }
          }
        }
      }

      .box-input {
        width: 100%;
        background-color: rgba(242, 242, 242, 1);
        display: flex;
        flex-direction: column;
        gap: 24px;
        border-radius: 8px;
        padding: 12px;
      }
    }
  }
}

@media (max-width: 400px) {
  .box-exchange {
    width: min-content !important;
  }
}
</style>
<template>
  <the-public-modal @close="$emit('close')">
    <div class="header-auth">
      <p>Security verification </p>
    </div>

    <p class="text">
      Please enter the 6-digit verification code that was sent to {{ state.loginEmail }} The code is valid for
      {{ countDown }}s.
    </p>

    <div class="cod-input">
      <CodeInput
          :loading="false"
          :fields="6"
          @complete="submit"
          @change="onChange"
          ref="code-input"
      />
    </div>

    <button :disabled="countDown > 0"  :class="{'disable': countDown > 0}" @click.prevent="send" class="gold-btn">Resend
      Code
    </button>
  </the-public-modal>
</template>

<script>
import CodeInput from "vue-verification-code-input"
import ThePublicModal from "../../../tools/the-public-modal";
export default {
  name: "the-otp-withdraw",
  components: {ThePublicModal , CodeInput},

  data(){
    return{
      countDown: 120,

      code:0
    }
  },

  methods:{

    countDownTimer() {
      if (this.countDown > 0) {
        this.timeOut = setTimeout(() => {
          this.countDown--
          this.countDownTimer()
        }, 1000)
      } else {
        this.$error('Your time is up', '')
      }
    },

    onChange(e){
      this.code = e
    },

    //resend code
    async send(){
      const res = await this.$axios.post(`/users/send-verification-code?twoStepAuthorization=EMAIL`)
      if (res.message === 'Verification Code Sent') {
        this.$error('', 'The code was sent to your email', 'success')
        if (this.countDown === 0)
          this.countDown = 120
        this.countDownTimer()
      }

    },

    //when compelet input send data to parent
    submit(){
      this.$emit('giveVerifyCode' , this.code )
    }

  },

  mounted() {
    //refresh code timer
    this.countDownTimer()
  }
}
</script>

<style scoped>
.cod-input{
  display: flex;
  justify-content: center;
}
</style>
<template>
  <div class="main-about">
    <div class="header">
      <!-- liner color div-->
      <div class="layer">
        <!--top header section-->
        <div class="top-header">
          <h1>About Binance</h1>
          <p>Via Blockchain, Making The World A Better Place</p>
        </div>
        <!--botom header section-->
        <div class="bottom-header">
          <div class="item">
            <p class="item-title">{{$toLocal(tradprice)}}</p>
            <p class="text">Average daily volume</p>
          </div>
          <div class="item">
            <p class="item-title">{{$toLocal(totaltrad)}}</p>
            <p class="text">Transactions per second</p>
          </div>
          <div class="item">
            <p class="item-title">{{$toLocal(userscount)}}</p>
            <p class="text">Verified Users</p>
          </div>
        </div>
      </div>
    </div>
    <!--body section-->
    <div class="body">
      <!--row1 section-->
      <div class="row-1">
        <h2 class="title">About Us</h2>
        <p>{{ about }}</p>
      </div>
      <!--row2 section-->
      <div class="row-2">
        <h2 class="title">Get in touch. Stay in touch.</h2>
        <!--options App-->
        <div class="main-options">
          <div class="boxes">
            <div class="item-options">
              <img src="../../assets/home/Fast.png" alt="">
              <p class="title-text-option">Instant delivery</p>
              <p class="small-text-option">Place your order and get your coins instantly. No deposits required</p>
            </div>

            <div class="item-options">
              <img src="../../assets/home/conversation.png" alt="">
              <p class="title-text-option">24/7 Support</p>
              <p class="small-text-option">Place your order and get your coins instantly. No deposits required</p>
            </div>

            <div class="item-options">
              <img src="../../assets/home/Wallet.png" alt="">
              <p class="title-text-option">Secure Wallets</p>
              <p class="small-text-option">Place your order and get your coins instantly. No deposits required</p>
            </div>

            <div class="item-options">
              <img src="../../assets/home/Safe.png" alt="">
              <p class="title-text-option">Privacy</p>
              <p class="small-text-option">Place your order and get your coins instantly. No deposits required</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<!--scripts components-->
<script>
export default {
  name: "the-about-us",
  data() {
    return {
      userscount: '0',
      tradprice: '0',
      totaltrad: '0',
      //this is a about text section
      about: "CoinEx core team members are from world leading internet and finance companies, including the earliest adopters/professionals of cryptocurrency who boast rich experience in R&D, global operations and services in the industry. With our proprietary trade matching system and excellent user experience, we are dedicated to building a highly secure, stable and efficient cryptocurrency exchange for global users.\n" +
          "Currently, we support multiple languages like Chinese, English, Japanese, Korean, Russian, etc. and are providing global trading services in over 100 countries/regions.\n" +
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
    }
  },

  methods: {
    async getInfo() {
      const res = await this.$axios('trades/market-info-home')
      this.userscount = res.baseDTO.userCount
      this.tradprice = res.baseDTO.tradesValue
      this.totaltrad = res.baseDTO.tradesAmount
    },
  },

  mounted() {
    this.getInfo()
  }
}
</script>

<!--styles components-->
<style lang="scss" scoped>
//general class
.title {
  color: var(--main-background-color);
}

//main styles
.main-about {
  display: flex;
  flex-direction: column;

  //header styles
  .header {
    display: flex;
    flex-direction: column;
    background-image: url("../../assets/public/about-header-bg.png");
    height: 700px;

    .layer {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-image: linear-gradient(180deg, #4613A3 0%, rgba(70, 19, 163, 0.4) 133.64%);
      height: 100%;
      gap: 100px;
    }

    .top-header {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 5px auto;
      color: white;
      text-align: center;
    }

    .bottom-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: 5px auto;
      width: 70%;
      flex-wrap: wrap;
      align-items: baseline;

      .item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-basis: 100px;
        flex-grow: 1;

        .item-title {
          font-size: 25px;
          font-weight: bold;
          color: white;
        }

        .text {
          font-size: medium;
          color: rgba(242, 242, 242, 1);
        }
      }
    }
  }

  //body styles
  .body{
    background-color: #E5E5E5;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 50px;

    .row-1{
      margin: 0 auto;
      width: 90%;
      color: rgba(72, 77, 86, 1);
    }

    .row-2{
      margin: 0 auto;
      width: 90%;

      //options App
      .main-options {
        display: flex;
        flex-direction: row;
        width: 90%;
        margin: 20px auto;
        gap: 20px;

        .boxes {
          display: flex;
          flex-direction: row;
          gap: 20px;
          flex-wrap: nowrap;

          .item-options {
            display: flex;
            flex-direction: column;
            text-align: center;
            align-items: center;
            justify-content: center;
            flex-grow: 1;

            img {
              width: 80px;
              height: 80px;
            }

            .title-text-option {
              font-size: large;
              color: var(--main-background-color);
            }

            .small-text-option {
              color: rgba(72, 77, 86, 1);
              font-size: small;
            }
          }
        }
      }

    }
  }
}

@media (max-width: 600px) {
  .boxes{
    flex-wrap: wrap !important;
  }
}
</style>
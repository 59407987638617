
<template>
  <the-public-modal @close="$emit('close')">
    <div class="main-otp">
      <div class="header-auth">
        <h3>TOTP Authentication</h3>
      </div>

      <p>
        To enable Google Authenticator for your account, please download the Googel Authenticator App and scan the QR
        code below.
      </p>

      <div class="qr-code">
        <img style="width: 200px" :src="'data:image/png;base64,'+qrCode" alt="">
        <span class="qrLink">{{qrLink}}</span>
        <button @click="copyLink" class="gold-btn">copy</button>
      </div>

      <p>
        Afret scanning the QR code, please enter the 6-digit code generated by the Google Authenticator app.
      </p>

      <div class="cod-input">

        <CodeInput
            :loading="false"
            :fields="6"
            @input="$emit('update' , value)"
            @change="onChange"
            @complete="submit"
            ref="code-input"
        />
      </div>

      <div class="divBtn">
        <button class="gold-btn" @click="submit">Submit</button>
      </div>


    </div>
  </the-public-modal>
</template>

<script>
import ThePublicModal from "../../../tools/the-public-modal";
import CodeInput from "vue-verification-code-input"
import {Copy,setPlaceholder,reset,paste} from "@/lib/reuseableFunction";
export default {
  name: "the-otp-panel-modal",
  components: {ThePublicModal , CodeInput},
  props: ['qrCode', 'qrLink'],
  data(){
    return{
      countDown: 120,

      otp:{
        code: '',
      },
    }
  },

  methods:{

    //copy link
    copyLink(){
      navigator.clipboard.writeText(this.qrLink);
      // this.alertlink = true;
      // setTimeout(() => {this.alertlink = false;} , 1500)
    },

    onChange(e) {
      this.otp.code = e
      this.state.otpcode = e
    },

    //countDownTimer
    countDownTimer() {
      if (this.countDown > 0) {
        this.timeOut = setTimeout(() => {
          this.countDown--
          this.countDownTimer()
        }, 1000)
      } else {
        this.$error('Your time is up', '')
      }
    },

    async submit() {
      const res = await this.$axios.post(`/users/change-otp?twoStepAuthorization='GOOGLE_AUTHENTICATOR' & code=${this.otp.code}`)

      if(res){
        this.reset()
        this.$emit('close')
        this.$emit('submit', 'Identify your two steps ' + 'GOOGLE_AUTHENTICATOR' + ' Changed.')
      }
    },
    Copy, paste,setPlaceholder,reset
  },

  beforeDestroy() {
    clearTimeout(this.timeOut)
  },
}
</script>

<style lang="scss" scoped>
.main-otp {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 16px;
  justify-content: space-between;

  .divBtn{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .header-auth {
    justify-content: start !important;
    align-items: start !important;
    color: rgba(72, 77, 86, 1);
    font-size: large;
  }

  .qr-code {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .cod-input{
    display: flex;
    justify-content: center;
  }

  .qrLink{
    color: rgba(72, 77, 86, 1);
    font-size: clamp(12px , 2vw , 15px);
    text-align: center;
  }

  .gold-btn{
    color: #FFFFFF;
    width: 20%;
    margin-top: 20px;
  }
}
</style>
<template>
  <div class="basic-info">
    <span class="title-info">Basic Info</span>
    <div class=" box-info">

      <label class="label-input">
        <input :class="{'disable' : state.userInfo.authenticationType === 'AUTHORIZED'}" :disabled="state.userInfo.authenticationType === 'AUTHORIZED'" @input="s2fa" v-model="data.firstName" placeholder="First Name"  class="global-input">
        <span class="spn-valu">First Name</span>
      </label>

      <label class="label-input">
        <input :class="{'disable' : state.userInfo.authenticationType === 'AUTHORIZED'}" :disabled="state.userInfo.authenticationType === 'AUTHORIZED'" @input="s2fa" v-model="data.lastName" placeholder="Last Name" class="global-input">
        <span class="spn-valu">Last Name</span>
      </label>

      <label :class="{'disable' : state.userInfo.authenticationType === 'AUTHORIZED'}" class="label-input" for="birthday">

        <date-picker
            :disabled="state.userInfo.authenticationType === 'AUTHORIZED'"
            id="birthday"
            color="rgb(71, 21, 164)"
            v-model="data.birthDay"
            placeholder="Birth Date"
            :format="$dateFormat['en']"
            :display-format="$dateFormat['en']"
            :clearable="!state.userInfo.authorized"
        />

        <span class="spn-valu">Birth Date</span>
      </label>

      <label class="label-input">
        <input :class="{'disable' : state.userInfo.authenticationType === 'AUTHORIZED'}" :disabled="state.userInfo.authenticationType === 'AUTHORIZED'" @input="s2fa" v-model="data.nationalCode" placeholder="ID Number" class="global-input">
        <span class="spn-valu">ID Number</span>
      </label>

      <label class="label-input">
        <input :class="{'disable' : state.userInfo.authenticationType === 'AUTHORIZED'}" :disabled="state.userInfo.authenticationType === 'AUTHORIZED'" @input="s2fa" v-model="data.email" placeholder="Email" class="global-input">
        <span class="spn-valu">Email</span>
      </label>

      <label class="label-input">
        <input @input="s2fa" v-model="data.mobileNumber" placeholder="Cell Number" class="global-input">
        <span class="spn-valu">Cell Number</span>
      </label>

      <label class="label-input address-input">
        <input @input="s2fa" v-model="data.address" placeholder="Address" class="global-input">
        <span class="spn-valu">Address</span>
      </label>

    </div>
  </div>
</template>

<script>

export default {

  components: {},

  name: "the-basic-info",

  props: ['data'],

  computed: {
    readonly() {
      return ['AUTHORIZED', "INFORMATION_CHANGED", 'INFORMATION_CHANGED_PENDING'].includes(this.state.userInfo.authenticationType)
    }
  },

  methods:{
    s2fa(){
      // eslint-disable-next-line vue/no-mutating-props
      this.data.firstName = this.$S2Fa(this.data.firstName)
      // eslint-disable-next-line vue/no-mutating-props
      this.data.lastName = this.$S2Fa(this.data.lastName)
      // eslint-disable-next-line vue/no-mutating-props
      this.data.birthDay = this.$S2Fa(this.data.birthDay)
    },
  },


}
</script>

<style lang="scss" scoped>
.disable{
  color: rgb(221 208 208) !important;
  cursor: not-allowed;
}

.box-info {
  justify-content: flex-start;
  align-items: center;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.spn-valu:focus {
  color: rgba(130, 130, 130, 1) !important;
}


.global-input:focus + .spn-valu {
  color: rgba(130, 130, 130, 1);
  position: absolute;
  bottom: 75%;
  left: 8px;
  padding: 0 5px;
  width: fit-content;
  background-color: #FFFFFF;
  font-size: 14px;
}

.global-input:focus::placeholder {
  color: transparent;
}

.address-input{
  position: relative;
  width: 530px !important;
  max-width: 530px !important;
}
.label-input {
  position: relative;
  width: 260px;
  max-width: 260px;
  input {
    text-align: left;
  }

  .global-input {
    background-color: transparent;
    border: 2px solid #BDBDBD;
    border-radius: 5px;
    padding: 10px;
    color: rgba(130, 130, 130, 1);
    font-size: small;
    font-family: IBMPlexSans, serif;
    width: 100%;
    position: relative;
    outline: none;


    &:focus {
      border: 2px solid #BDBDBD;
      border-radius: 5px;
      padding: 10px;
      background-color: transparent;
    }

  }

  .global-input:focus + .spn-valu{
    display: inline;
  }


  .spn-valu {
    color: rgba(130, 130, 130, 1);
    position: absolute;
    bottom: 75%;
    left: 8px;
    padding: 0 5px;
    width: fit-content;
    background-color: #FFFFFF;
    font-size: 12px;
    display: none;
  }
}


.visibility {
  position: absolute;
  left: 91%;
  top: 25%;
  cursor: pointer;
  z-index: 1;
}


@media (max-width: 992px) {
  .email-input {
    width: 100% !important;
  }

  .global-input {
    width: 100% !important;
  }

  .label-input{
    max-width: 200px !important;
  }
}

</style>

<style>
.vpd-input-group input{
  background-color: transparent!important;
  border: 2px solid #BDBDBD!important;
  border-radius: 5px!important;
  padding: 10px!important;
  color: #828282!important;
  font-size: small!important;
  font-family: IBMPlexSans, serif!important;
  width: 100%!important;
  position: relative!important;
  outline: none!important;
  left:0;
  line-height: 0!important;
}

.vpd-icon-btn{
  display: none !important;
}
</style>

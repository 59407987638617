<template>
  <div class="main-global-input">
    <img @click="changetype" v-if="logo" class="visibility" src="../../assets/public/Eye.png" alt="">
    <label class="label-input">
      <input  v-model="inputdata" @input="$emit('updateValue' , inputdata)" :placeholder="value" :style="{'width' : width+'px'}" :type="typeme" class="global-input">
    </label>
    <span v-if="errorText !== '' " class="erroe">{{errorText}}</span>
  </div>
</template>

<script>
export default {
  name: "the-input",
  props: ["type", "value", "logo", "width" , "errorText"],
  data() {
    return {
      active: false,
      typeme: this.type,
      inputdata:"",

    }
  },

  methods: {
    changetype(){
      if (this.typeme === 'password'){
        this.typeme = 'text'
      }
      else{
        this.typeme = 'password'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.erroe{
  color: #cc2d6a;
  font-size: clamp(12px , 2vw , 15px);
}
.global-input:focus + .spn-valu{
  display: inline-block !important;
}

.global-input:focus::placeholder {
  color: transparent;
}

.main-global-input {
  display: flex;
  position: relative;
  flex-direction: column;

  .label-input {
    position: relative;
    width: 100%;

    input{
      text-align: left;
    }

    .global-input {
      background-color: transparent;
      border: 2px solid #BDBDBD;
      border-radius: 5px;
      padding: 10px;
      color: rgba(130, 130, 130, 1);
      font-size: small;
      font-family: IBMPlexSans,serif;
      width: 100%;
      position: relative;
      outline: none;


      &:focus {
        border: 2px solid var(--main-background-color);
        border-radius: 5px;
        padding: 10px;
        background-color: transparent;
      }


    }


    .spn-valu {
      color: var(--main-background-color);
      position: absolute;
      bottom: 75%;
      left: 8px;
      padding: 0 5px;
      width: fit-content;
      background-color: #FFFFFF;
      font-size: 14px;
      display: none;
    }
  }

}


.visibility {
  position: absolute;
  left: 91%;
  top: 25%;
  cursor: pointer;
  z-index: 1;
}


@media (max-width: 992px) {
  .email-input {
    width: 100% !important;
  }
}

@media (max-width: 992px) {
  .global-input {
    width: 100% !important;
  }
}


</style>
<template>
  <div class="register">
    <div class="header-auth">
      <p>Create a free account</p>
      <small>Welcome to Binance</small>
    </div>
    <the-input @updateValue="emailvalue" value="Email" type="email" :errorText="emailError"/>
    <the-input @updateValue="passwordvalue" value="Password" type="Password" logo="logo"  :errorText="passError"/>
    <the-input @updateValue="referallvalue" value="Referral ID (Optional)" type="text" />
    <label class="check-box-label" :class="{'checked' : check}">
      <input  type="checkbox" v-model="agree" hidden>
      <span  class="check-box-icon"/>
      <span class="text-terms"> I have read and agree to the Terms of Service.
      <router-link to="#" class="link-terms"> Binance's Terms</router-link>
      </span>
    </label>
    <button :disabled="agree === false || data.password === '' || data.email === '' " :class="{'disale': agree === false || data.password === '' || data.email === '' }"  @click="send" class="gold-btn">Create Account</button>
    <span class="login-text">
      Already registered? <a class="login-link" @click="$router.push('login')">Log In</a>
    </span>
  </div>
</template>

<script>
import TheInput from "../../tools/the-input";

export default {
  name: "the-register",
  components: {TheInput},
  data(){
    return{
      check:'',

      data: {
        email: '',
        password: '',
        osType: '',
        deviceType: '',
        browser: '',
        referralCode: ''
      },

      passError:"",
      emailError:"",
      agree:false
    }
  },

  computed: {
    disable() {
      return !this.data.email || !this.data.password || this.emailError.length > 0 || this.passError.length > 0 || !this.agree
    },
  },

  methods:{
    emailvalue(value){
      this.data.email = value
    },

    passwordvalue(value){
      this.data.password = value
    },

    referallvalue(value){
      this.data.referralCode = value
    },

    async send() {
      await this.checkEmail()
      await this.check2Pass()
      this.state.loading = true
      const res = await this.$axios.post('/users/register', this.data)
      if (res.message === 'user created successfully , verification email sent') {
        if (res.baseDTO.towStepAuthenticationType === 'EMAIL') {
          this.$cookies.set('username', this.data.email)
          this.state.userInfo.email = this.data.email
          await this.$router.push({name:"OtpRegister"})
        }
      }
      else if (res.token) {
        this.$setToken(res.token, this.state.userInfo.remember)
        await this.$router.push('/profile')
      }

      this.state.registerEmail = this.data.email
    },

    async check2Pass() {
      if (!this.$validPass(this.data.password) || this.data.password.length < 8) {
        this.passError = 'The password must be at least 8 characters long and contain uppercase, lowercase, and numbers'
        this.$error('The information entered is incorrect', 'The password must be at least 8 characters long and contain uppercase, lowercase, and numbers')
        return Promise.reject('wrong pass : ' + this.data.password)
      }else{
        this.passError = ''
      }
    },

    async checkEmail() {
      if (!this.$validEmail(this.data.email) || !this.data.email) {
        this.emailError = 'Enter the correct email'
        this.$error('The information entered is incorrect', 'Enter the correct email')
        return Promise.reject('repeat failed : ' + this.data.email)
      }else{
        this.emailError = ''
      }
    },

    getData() {
      this.data.osType = this.$detectOs()
      this.data.browser = this.$detectBrowser() || 'CHROME'
      this.data.deviceType = this.$detectDevice()
    },

  },

  mounted() {
    this.getData()
  }
}
</script>

<style lang="scss" scoped>
.register {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .gold-btn{
    color: #FFFFFF;
  }

  .login-text{
    text-align: center;
    color: #484D56;
    margin-bottom: 10px;

    .login-link{
      color: rgba(241, 207, 82, 1);
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .header-auth {
    small {
      color: rgba(72, 77, 86, 1);
      font-size: 14px;
      margin-bottom: 30px;
    }
  }

  .disale{
    opacity: 0.7;
    cursor: not-allowed;
  }

  .check-box-label {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: 13px;
    width: 80%;

    .check-box-icon {
      display: block;
      width: 16px;
      height: 16px;
      border-radius: 2px;
      border: 1px solid rgba(241, 207, 82, 1);
      background-color: #FFFFFF;
      cursor: pointer;
    }

    .text-terms {
      color: #484D56;
      text-decoration: none;
    }

    .link-terms {
      color: rgba(241, 207, 82, 1);

    }

    &.checked {
      .check-box-icon {
        background-color: #fff;
      }
    }

    input[type="checkbox"]:checked {
      border: rgba(241, 207, 82, 1);

      + {
        .check-box-icon {
          background-image: url("../../../assets/public/check-icon.png");
          background-position: center;
          background-size: cover;
        }
      }
    }

  }
}
</style>
<template>
  <div class="main-market">
    <h1>Order History</h1>
    <!--  public table-->
    <div class="table-div">
      <table class="public-table">
        <tr>
          <th :class="item.class" v-for="(item , index) in headerData" :key="index">
            <div class="th-table">
              {{ item.name }}
              <div class="arrows">
                <inline-svg v-if="item.sort" class="hover-svg"
                            :src="require('../../../../assets/public/arrow-top.svg')"/>
                <inline-svg v-if="item.sort" class="hover-svg"
                            :src="require('../../../../assets/public/arrow-bottom.svg')"/>
              </div>
            </div>
          </th>
        </tr>

        <tr v-if="!tableContents.length">
          <td colspan="8">
            <p class="no-data"> Custom is not registered. </p>
          </td>
        </tr>

        <tr v-for="(item , index) in tableContents" :key="index">
          <td>{{ item.Date }}</td>
          <td :class="item.class">{{ item.Pair }}</td>
          <td :class="item.orderType.includes('BUY') ? 'var(--successful-color)':'var(--Unsuccessful-color)'">
            {{ item.marketType }}
          </td>
          <td :class="item.class">{{ item.amount }}</td>
          <td>{{ item.unitPrice }}</td>
          <td :class="item.class">{{ item.wagePercent + '%' }}</td>
          <td :class="item.class">{{ item.Filled }}</td>
          <td :class="item.class">{{ item.wagePercent }}</td>
          <td>{{ item.wholePrice }}</td>
          <td :class="item.class">{{ item.orderStatus }}</td>
        </tr>

      </table>
    </div>

  </div>
</template>

<script>

export default {
  name: "the-orderhistory",

  data(){
    return{
      //header data
      headerData: [
        {name: "Date", sort: true},
        {name: "Pair", sort: true, class: "hide"},
        {name: "Type", sort: true, class: "hide"},
        {name: "Amount", sort: false, class: "hide"},
        {name: "Price", sort: false},
        {name: "Filled", sort: false, class: "hide"},
        {name: "Fee", sort: false, class: "hide"},
        {name: "Total", sort: true},
        {name: "Status", sort: false, class: "hide"},
      ],

      tableContents: [],
      loop:''
    }
  },

  methods: {
    async getLastOrders() {
      let res = await this.$axios.get('/orders', {
        params: {
          market: this.state.MarketType,
          page: 1,
          size: 20
        }
      })
      if (res.content)
        res.content = res.content.filter(i => i.orderStatus !== 'IS_OPEN');
      this.tableContents = res.content || [];
      this.loading=false
    },
  },

  mounted() {
    this.loading = true
    this.getLastOrders()

    // if (this.state.loop) {
    //   this.loop = new Loop(this.getLastOrders,5000,'lastOrder')
    //   this.loop.start()
    // } else {
    //   this.getLastOrders()
    // }
  },

  // beforeDestroy() {
  //   this.loop.stop()
  // }

}
</script>

<style lang="scss" scoped>
//general styles
.no-data {
  color: #cc2d6a;
  font-weight: bold;
  text-align: center;
  width: 100%;
}

.td-first {
  text-align: left !important;
}

.table-div {
  display: flex;
  justify-content: left;
  align-items: center;
}

.th-table {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
}

.td-last {
  text-align: right !important;
}

.public-table {
  table-layout: auto !important;
}

.right {
  justify-content: right !important;
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
}

.success {
  color: rgba(93, 204, 141, 1);
}

.danger {
  color: rgba(242, 53, 126, 1);
}

//main styles
.main-market {
  width: 100%;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  padding: 0 16px;

  h1 {

  }


  .hover-svg {
    fill: #BDBDBD !important;
    width: 8px;
    height: 8px;
    cursor: pointer;
  }

  .hover-svg:hover {
    fill: var(--main-background-color) !important;
  }


  th {
    .arrows {
      display: flex;
      flex-direction: column;
      gap: 1px;
    }

    .item {
      display: flex;
      flex-direction: row;
      gap: 5px;
      align-items: center;
      justify-content: center;
    }
  }

}

@media (max-width: 690px) {
  .hide {
    display: none !important;
  }
}

//@media (max-width: 500px) {
//  .hid-500 {
//    display: none !important;
//  }
//}
//
//@media (max-width: 450px) {
//  .hid-450 {
//    display: none !important;
//  }
//}
//
//@media (max-width: 390px) {
//  .hid-390 {
//    display: none !important;
//  }
//}
//
//@media (max-width: 350px) {
//  .hid-350 {
//    display: none !important;
//  }
//}
</style>

<template>
  <div class="main-market">
    <h1>Trade History</h1>
    <!--  public table-->
    <div class="table-div">
      <table class="public-table">
        <tr>
          <th :class="item.class" v-for="(item , index) in headerData" :key="index">
            <div class="th-table">
              {{ item.name }}
              <div class="arrows">
                <inline-svg v-if="item.sort"  class="hover-svg"
                            :src="require('../../../../assets/public/arrow-top.svg')"/>
                <inline-svg v-if="item.sort"  class="hover-svg"
                            :src="require('../../../../assets/public/arrow-bottom.svg')"/>
              </div>
            </div>
          </th>
        </tr>

        <tr v-if="!tableContents.length">
          <td colspan="8">
            <p class="no-data"> Custom is not registered. </p>
          </td>
        </tr>

        <tr v-for="(item , index) in tableContents" :key="index">
          <td>{{ item.createdAtDateTime }}</td>
          <td :class="item.class">{{ item.destinationCoin }}</td>
          <td  :class="item.orderType.includes('BUY') ? 'var(--successful-color)':'var(--Unsuccessful-color)'">{{ item.Type }}</td>
          <td :class="item.class">{{ item.Amount }}</td>
          <td>{{ item.Price }}</td>
          <td>{{ item.Fee }}</td>
          <td :class="item.class">{{ item.Total }}</td>
        </tr>

      </table>
    </div>

  </div>
</template>

<script>

export default {
  name: "the-tradehistory",

  data() {
    return {
      datesort: [],
      sort: [],
      //header data
      headerData: [
        {name: "Date", sort: true},
        {name: "Pair", sort: true,class:"hide"},
        {name: "Type", sort: true},
        {name: "Amount", sort: false,class:"hide"},
        {name: "Price", sort: false,class:"hide"},
        {name: "Fee", sort: false},
        {name: "Total", sort: true,class:"hide"},
      ],

      //data table
      tableContents:[
        /*{
                     amount: 0,
                     calculatedPriceInTomanMarket: 0,
                     createdAtDateTime: '',
                     destinationCoin: "BINANCE_COIN",
                     executedPercent: 0,
                     id: 0,
                     marketType: "BINANCE_COIN_TETHER",
                     orderType: '',
                     receivedAmount: 0,
                     sourceCoin: "BINANCE_COIN",
                     unitPrice: 0,
                     wagePercent: 0,
                     wholePrice: 0
                 }*/
      ]
    }
  },

  methods: {
    async getLastTrades() {
      const res = await this.$axios.get('/trades/last-trades', {
        params: {
          marketType: this.state.MarketType,
          size: 20
        }
      })
      this.tableContents = res.content
      this.state.loading = false
    },
  },

  mounted() {
    this.state.loading = true
    this.getLastTrades();

  }
}
</script>

<style lang="scss" scoped>
//general styles
.no-data {
  color: #cc2d6a;
  font-weight: bold;
  text-align: center;
  width: 100%;
}

.td-first {
  text-align: left !important;
}

.table-div{
  display: flex;
  justify-content: left;
  align-items: center;
}

.th-table {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
}

.td-last {
  text-align: right !important;
}

.public-table {
  table-layout: auto !important;
}

.right {
  justify-content: right !important;
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
}

.success {
  color: rgba(93, 204, 141, 1);
}

.danger {
  color: rgba(242, 53, 126, 1);;
}

//main styles
.main-market {
  width: 100%;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  padding: 0 16px;

  h1{

  }


  .hover-svg {
    fill: #BDBDBD !important;
    width: 8px;
    height: 8px;
    cursor: pointer;
  }

  .hover-svg:hover {
    fill: var(--main-background-color) !important;
  }


  th {
    .arrows {
      display: flex;
      flex-direction: column;
      gap: 1px;
    }

    .item {
      display: flex;
      flex-direction: row;
      gap: 5px;
      align-items: center;
      justify-content: center;
    }
  }

}

@media (max-width: 690px) {
  .hide {
    display: none !important;
  }
}

</style>
<template>
  <div class="item">
    <the-alert-modal v-if="showAlertModal === true" @close="showAlertModal = false"/>
    <div class="buttons">
      <button class="Spot">Spot</button>
      <button @click="showAlertModal = true" class="Cross">Cross 3x</button>
      <button @click="showAlertModal = true" class="Isolated">Isolated 10x</button>
    </div>
    <the-header-table selected="Limit" @selectitem="consol" :items="tradeHeader"/>
    <div class="main-trade-item">
      <the-change-component :select="selecteditem" buy="buy"/>
      <the-change-component :select="selecteditem" sell="sell"/>
    </div>
  </div>
</template>

<script>

import TheHeaderTable from "../../../../tools/the-headerTable";
import TheChangeComponent from "./the-change-component";
import TheAlertModal from "../../../../tools/the-alert-modal";

export default {
  name: "the-change-coin",

  components: {TheAlertModal, TheChangeComponent,  TheHeaderTable},

  data() {
    return {
      balanced: 1826.82,
      inputValue: 0,
      selecteditem:'Limit',
      tradeHeader: ["Limit", "Market", "Stop - Limit"],
      showAlertModal:false
    }
  },

  methods: {
    inputrangevalue(e) {
      this.inputValue = e;
    },

    consol(e){
      this.selecteditem = e;
    },

  }
}
</script>

<style lang="scss" scoped>

.buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  width: 92%;
  margin: 10px auto;


  button {
    background-color: rgba(242, 242, 242, 1);
    color: rgba(70, 19, 163, 1);
    border: 0;
    border-radius: 3px;
    width: 110px;
    cursor: pointer;
    height: 22px;

    &:focus {
      background-color: var(--main-background-color);
      color: #FFFFFF;
    }
  }

  .Spot {

    border-radius: 3px 0 0 3px !important;
  }

  .Cross {
    border-radius: 0 !important;
  }

  .Isolated {
    border-radius: 0 3px 3px 0 !important;
  }
}

.balanced {
  display: flex;
  justify-content: space-between;
  font-size: 14px;

  .blnc-1 {
    color: rgba(189, 189, 189, 1);
  }

  .blnc-2 {
    color: rgba(189, 189, 189, 1);
  }
}

.red-btn {
  color: #FFFFFF;
}

.green-btn {
  color: #FFFFFF;
}


.main-trade-item {
  display: flex;
  flex-direction: row;
  gap: 30px;
  margin: 20px;
  flex-wrap: wrap;

  .gray-btn {
    color: var(--main-background-color);
    font-weight: 500;
  }

  .left-div-item {
    flex-grow: 1;
    flex-basis: 100px;
    flex-direction: column;
    display: flex;
    gap: 20px;

  }

  .right-div-item {
    flex-grow: 1;
    flex-basis: 100px;
    flex-direction: column;
    display: flex;
    gap: 20px;
  }
}

@media (max-width:500px) {
  .item{
    height: auto !important;
    max-height: fit-content !important;
  }
}
</style>
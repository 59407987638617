<template>
  <div class="box-dashboard">
    <div class="header">
      <span class="title">Referral</span>
      <p class="text">Earn up to 40% commission every time your friends make a trade on Binance.</p>
    </div>

    <div class="row-1">
      <span class="row1-title">Your Referral Code</span>
      <div class="row1-group">
        <p class="ref-code">{{data.referralCode}}</p>
        <img @click="copyCode" src="../../../../../assets/panel/content_copy.png" alt="">
        <transition name="fade">
          <span v-if="alertcode" class="copy-alert">Your code was successfully copied</span>
        </transition>
      </div>
    </div>

    <div class="row-1">
      <span class="row1-title">Your Referral Link</span>
      <div class="row1-group">
        <p class="ref-code">{{$refURL+data.referralCode}}</p>
        <img @click="copyLink" src="../../../../../assets/panel/content_copy.png" alt="">
      </div>
      <transition name="fade">
        <span v-if="alertlink" class="copy-alert">Your code was successfully copied</span>
      </transition>
    </div>

  </div>
</template>

<script>
export default {
  name: "the-referal",

  data(){
    return{
      data:{
        countInvitees:0,
        profit:'',
        referralCode: '',
      },

      alertlink:false,
      alertcode:false

    }
  },

  methods:{
    copyCode(){
      navigator.clipboard.writeText(this.links[0].refCode);
      this.alertcode = true;
      setTimeout(() => {this.alertcode = false;} , 1500)
    },

    copyLink(){
      navigator.clipboard.writeText(this.links[0].refLink);
      this.alertlink = true;
      setTimeout(() => {this.alertlink = false;} , 1500)
    },
  },

  async mounted() {
    const res = await this.$axios.get('/users/invites')
    this.data = res.baseDTO
  }

}
</script>

<style lang="scss" scoped>
//general style
.copy-alert{
  width: auto;
  height: auto;
  background-color: #00A35F;
  color: #FFFFFF;
  border-radius: 10px;
  padding: 10px;
  opacity: 0.9;
  font-size: clamp(12px, 1vw, 14px);
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

//main style
.box-dashboard {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding: 10px;
  gap: 30px;
  flex-basis: 100px;
  background-image: url("../../../../../assets/panel/Layer.png");
  background-position: center;
  background-size: cover;

  .title {
    color: rgba(102, 102, 102, 1);
    font-size: clamp(1rem, 2.5vw, 1.5rem);
  }

  .text {
    color: rgba(102, 102, 102, 1);
    font-size: clamp(0.7rem, 2.5vw, 1rem);
  }

  .row-1{
    display: flex;
    flex-direction: column;


    .row1-title{
      color: rgba(130, 130, 130, 1);
      font-size: clamp(0.6rem, 2.5vw, 0.9rem);
    }

    .row1-group{
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;

      img{
        width: 20px;
        height: 20px;
        cursor: pointer;
      }

      .ref-code{
        font-size: clamp(1rem, 2.5vw, 1.5rem);
        color: rgba(72, 77, 86, 1);
      }
    }
  }
}
</style>
<template>
  <div class="referral">
    <div class="top-row">

      <div class="row-1">
        <span class="title">Referral</span>
        <p class="text">Earn up to 40% commission every time your friends make a trade on Binance.</p>
      </div>

      <div class="row-2">
        <p>Your Referral Link</p>
        <div class="copy-link">
          <div class="link">
            <span>{{$refURL+data.referralCode}}</span>
            <img @click="copyLink"  src="../../../assets/public/content_copy.png" alt="">
            <transition name="fade">
              <span v-if="alertlink" class="copy-alert">Your code was successfully copied</span>
            </transition>
          </div>

          <div class="code">
            <span>{{data.referralCode}}</span>
            <img @click="copyCode" src="../../../assets/public/content_copy.png" alt="">
            <transition name="fade">
              <span v-if="alertcode" class="copy-alert">Your code was successfully copied</span>
            </transition>
          </div>
        </div>
      </div>

    </div>
    <div class="bottom-row">
      <div class="box">
        <div class="text-box">
          <p class="number">
            {{ data.countInvitees }}
          </p>
          <p class="text">
            Number of friends
          </p>
        </div>
        <img src="../../../assets/panel/Smileyface_Monochromatic.png" alt="">
      </div>

      <div class="box">
        <div class="text-box">
          <p class="number">
            {{ data.profit }}
          </p>
          <p class="text">
            You earned
          </p>
        </div>
        <img src="../../../assets/panel/Moneyjar_Monochromatic.png" alt="">
      </div>

      <div class="box">
        <div class="text-box">
          <p class="number">

          </p>
          <p class="text">
            Referral ratio
          </p>
        </div>
        <img src="../../../assets/panel/Bitcoin_Monochromatic.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "the-referral",
  data() {
    return {
      data:{
        countInvitees:0,
        profit:'',
        referralCode: '',
      },
      alertlink:false,
      alertcode:false

    }
  },

  methods:{
    copyCode(){
      navigator.clipboard.writeText(this.$refURL+this.data.referralCode);
      this.alertcode = true;
      setTimeout(() => {this.alertcode = false;} , 1500)
    },

    copyLink(){
      navigator.clipboard.writeText(this.data.referralCode);
      this.alertlink = true;
      setTimeout(() => {this.alertlink = false;} , 1500)
    }
  },

  async mounted() {
    const res = await this.$axios.get('/users/invites')
    this.data = res.baseDTO
  }
}
</script>

<style lang="scss" scoped>
//general style
.copy-alert{
  width: auto;
  height: auto;
  background-color: #00A35F;
  color: #FFFFFF;
  border-radius: 10px;
  padding: 10px;
  opacity: 0.9;
  font-size: clamp(12px, 1vw, 14px);
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
}

.referral {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;

  .top-row {
    background-color: #4613A3;
    flex-grow: 1;
    border-radius: 12px;
    background-image: url("../../../assets/panel/Money tree _Isometric 1.png");
    background-position: right;
    background-size: auto;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    padding: 16px;
    color: #FFFFFF;
    gap: 32px;
    flex-wrap: wrap;

    .row-1 {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;

      .title {
        font-size: clamp(20px, 1vw, 32px);
      }

      .text {
        font-size: clamp(15px, 1vw, 20px);
      }

    }

    .row-2 {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;

      span {
        opacity: 0.7;
      }

      .copy-link {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .link {
          display: flex;
          flex-grow: 1;
          flex-basis: 100px;
          gap: 10px;
          align-items: center;
          font-size: clamp(12px, 2vw, 20px);

          img {
            width: 20px;
            height: 20px;
            cursor: pointer;
          }


        }

        .code {
          display: flex;
          gap: 10px;
          flex-wrap: wrap;
          flex-basis: 100px;
          flex-grow: 1;
          align-items: center;
          font-size: clamp(14px, 2vw, 20px);

          img {
            width: 20px;
            height: 20px;
            cursor: pointer;
          }
        }
      }
    }
  }

  .bottom-row {
    flex-grow: 1;
    font-size: clamp(14px, 1vw, 20px);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 16px;
    row-gap: 20px;

    .box {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-grow: 1;
      align-items: center;
      flex-basis: 100px;
      background: #FFFFFF;
      box-shadow: 0 0 4px rgba(70, 19, 163, 0.15);
      border-radius: 12px;
      padding: 16px;

      .text-box {
        display: flex;
        flex-direction: column;

        .number {
          font-size: clamp(16px, 2vw, 32px);
          color: #828282;
        }

        .text {
          font-size: clamp(14px, 1vw, 17px);
          color: #828282;
        }
      }
    }
  }
}
</style>
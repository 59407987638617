<template>
  <div class="main-fee">
    <div class="main">
      <div class="text-1">
        <!-- the title text section-->
        <p>{{ text1 }}</p>
      </div>

      <div class="text2">
        <h2 class="title">Trading Fees</h2>
        <!--this is a first table-->
        <div class="table">
          <table>
            <tr>
              <th class="th-level">Level</th>
              <th class="th-Volume">3M Trading Volume (USD)</th>
              <th class="th-fee">Fee</th>
            </tr>

            <tr v-for="(item , index) in $accountLevel" :key="index">
              <td class="td-level">{{ item.userAccountLevel }}</td>
              <td class="td-volume">{{item.tradeAmount}}</td>
              <td class="td-fee">{{item.wagePercent }}</td>
            </tr>
          </table>

        </div>
      </div>
      <div class="text3">
        <h2 class="title">Deposit/Withdrawal Fees</h2>
        <!--this is a secound table-->
        <div class="table">
          <table>
            <tr>
              <th class="th-Coin">Coin/Token</th>
              <th class="th-Network">Network</th>
              <th class="th-Minimum">Minimum Withdrawal</th>
              <th class="th-Withdrawal">Withdrawal Fee</th>
            </tr>

            <tr v-for="(item , index) in coins" :key="index">
              <td class="td-Coin">
                <div class="div-coin">
                  <img :src="require('../../assets/coinsName/'+item.coin+'.png')" alt="">
                  <span>{{ item.coin }}</span>
                </div>
              </td>
              <td  class="td-Network">{{ item.coin }}</td>
              <td class="td-Minimum">{{ item.minWithdrawAmount }}</td>
              <td class="td-Withdrawal">{{ item.transferFee }}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<!--script component-->
<script>
export default {
  name: "the-fees",
  data() {
    return {
      //title data
      text1: "Our trading fees are volume-based: get more discounts as your trading volume increases. For even more savings, stake CRO and pay your trading fees in CRO. As a bonus, you even get earnings of 10% p.a. on your CRO stake!\n" +
          "Trading Fees — the primary source of revenue for exchanges. They are typically charged on both fiat-crypto trades as well as crypto-crypto trades.\n" +
          "Deposit/Withdrawal Fees — some exchanges charge fees for deposits and/or withdrawals. Deposit fees vary based on the type of deposit but are less common than withdrawal fees since exchanges want to incentivize users to fund their account. For cryptocurrency withdrawals, many exchanges limit fees to any blockchain transaction costs (usually a flat fee regardless of the amount withdrawn), but some will take an additional fee based on your country or the type of withdrawal (crypto vs fiat)\n",

      //screen width
      width: 0,


      coins:[],

    }
  },

  methods: {
    async getData() {
      const res = await this.$axios.get('/coins');
      this.coins = res.content;
    },

  },
  mounted() {
    this.$getFees()
    this.getData()
  }
}
</script>

<!--style component-->
<style lang="scss" scoped>

//th and td table style
.th-level {
  text-align: left
}

.th-fee {
  text-align: right
}

.th-Volume {
  text-align: center
}

.td-fee {
  text-align: right
}

.td-level {
  text-align: left
}

.td-volume {
  text-align: center
}

.td-Minimum {
  text-align: center
}

.th-Minimum {
  text-align: center
}

.th-Coin {
  text-align: left
}

.th-Network {
  text-align: left
}

.th-Withdrawal {
  text-align: right
}

.td-Withdrawal {
  text-align: right
}

//main styles
.div-coin {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

.main-fee {
  display: flex;
  flex-direction: column;
  background-color: #E5E5E5;

  .main {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 20px auto;
    gap: 20px;

    .title {
      color: var(--main-background-color);
    }

    //table style
    .table {
      background-color: #dbdbdb;
      width: 100%;
      border-radius: 10px;
      padding: 10px;

      table {
        width: 100%;
        border-collapse: collapse;

        tr {
          border-bottom: 1px solid #a8a8a8;

          &:last-child {
            border-bottom: none;
          }
        }

        th {
          font-size: 12px;
          color: rgba(130, 130, 130, 1);
          padding: 10px;
        }

        td {
          font-size: small;
          color: rgba(72, 77, 86, 1);
          padding: 10px;

          img {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }
}
</style>
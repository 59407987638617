<template>
  <div class="resetpass">
    <the-successful-modal v-if="show" @close="show = false"/>
    <div class="header-auth">
      <p>Reset Your Password </p>
    </div>
    <the-input @updateValue="getPassword" value="New password" type="text" logo="logo" :errorText="passError"/>
    <the-input @updateValue="getPassword" value="Confirm password" logo="logo" type="text" :errorText="passError"/>
    <button :disabled="otp.password === ''" :class="{'disable':otp.password === ''}" @click="submit" class="gold-btn">Submit</button>
  </div>
</template>

<script>
import TheInput from "../../tools/the-input";
import TheSuccessfulModal from "../the-successful-modal";

export default {
  name: "the-resetpassword",
  components: {TheSuccessfulModal, TheInput},
  data() {
    return {
      show: false,
      otp: {
        code:this.state.otpcode,
        email: this.state.userInfo.email || this.$cookies.get('username') || '',
        password: ''
      },
      passError:""
    }
  },

  computed: {
    disable() {
      return !this.otp.password || this.passError !== '' || this.otp.code.length !== 6 || (this.pathMatch !== 'google-auth' && this.countDown === 0) || this.state.loading
    }
  },

  methods: {

    getPassword(e) {
      this.otp.password = e
    },

    //valid password
    validPass(password) {
      let re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/;
      return re.test(password);
    },

    //checkPass
    checkPass() {
      if (!this.otp.password ) {
        this.passError = 'Mandatory password';
      } else {
        if (this.otp.password.length < 8) {
          this.passError = 'At least 8 characters';
        } else if (!this.validPass(this.otp.password)) {
          this.passError = 'A combination of uppercase and lowercase letters and numbers';
        } else {
          this.passError = ''
        }
      }
      if (!this.passError.length) {
        return true;
      }
    },


    async submit() {
      this.state.loading = true
      if (this.checkPass(this.otp.password )) {
        this.state.loading = true
        let [res,] = await this.$http.post('/users/reset-password', this.otp)
        if (res) {
          this.show = true
        }
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.resetpass {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .gold-btn {
    color: white;
    margin: 20px 0;
  }

  .disable{
    opacity: 0.7;
    cursor: not-allowed;
  }
}
</style>
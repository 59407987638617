<template>
  <div  class="main-modal" @click.self="$emit('close')">
    <div class="modal">
      <img class="success" src="../../assets/public/Check.png" alt="">
      <P>Password Changed</P>
      <small>Please log in with the new password.</small>
      <button @click="$router.push({name:'Login'})" class="gold-btn">Log In</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "the-successful-modal"
}
</script>

<style scoped>
.main-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  position: fixed;
  inset: 0;
  background: rgba(4, 4, 5, 0.73)
}

.success{
  width: 150px;
  height: 150px;
}

.modal {
  background-color: #FFFFFF;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  width: max-content;
  padding: 30px 50px;
  height: auto;
  position: relative;
  align-items: center;
}

.gold-btn{
  color: #FFFFFF;
  margin: 20px 0;
}
</style>
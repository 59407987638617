import home from "@/components/home/The-home"
import faq from "@/components/innerpage/the-faq"
import howtouse from "@/components/innerpage/the-how-to-use"
import privacy from "@/components/innerpage/the-privacypolicy"
import fees from "@/components/innerpage/the-fees"
import notifications from "@/components/innerpage/Notfications/the-notifications"
import market from "@/components/innerpage/the-market"
import aboutus from "@/components/innerpage/the-about-us"
import authentication from "@/components/authentication/the-authentication"
import login from "@/components/authentication/login/the-login"
import forgetpassword from "@/components/authentication/login/the-forgetpassword"
import otplogin from "@/components/authentication/login/the-otp-login"
import otpregister from "@/components/authentication/register/the-otp"
import resetpassword from "@/components/authentication/login/the-resetpassword"
import register from "@/components/authentication/register/the-register"
import trade from "@/components/trade/the-trade"
import panel from "@/components/panel/the-panel"
import dashboard from "@/components/panel/dashboard/the-dashboard"
import profile from "@/components/panel/profile/the-profile"
import security from "@/components/panel/security/the-security"
import assets from "@/components/panel/assets/the-assets"
import history from "@/components/panel/history/the-history"
import referral from "@/components/panel/referral/the-referral"
import mainassets from "@/components/panel/assets/components/the-main-assets"
import fnDeposite from "@/components/panel/assets/components/the-fn-de"
import fnWithdraw from "@/components/panel/assets/components/the-fn-wi"
import openorders from "@/components/panel/history/components/the-openorders"
import tradehistory from "@/components/panel/history/components/the-tradehistory"
import orderhistory from "@/components/panel/history/components/the-orderhistory"
import FinoVerifying from "../components/panel/profile/FinoVerifying"
import creditdebitcard from "@/components/tools/the-credit-debit-card"
import boxexchange from "@/components/Exchange/the-box-exchange"
import addcard from "@/components/tools/the-add-card"
import exchangehistory from "@/components/panel/history/components/the-exchangehistory"
import transactionhistory from "@/components/panel/history/components/the-transactionhistory"
import transactionfiat from "@/components/panel/history/components/the-transactionfiat"
import transactioncrypto from "@/components/panel/history/components/the-transactioncrypto"
import exchange from "@/components/Exchange/the-exchange"
import otc from "@/components/OTC/the-convert"



export default [{
        path: '/',
        name: 'Home',
        component: home
    },

    {
        path: '/faq',
        name: 'FAQ',
        component: faq
    },

    {
        path: '/howtouse',
        name: 'HowToUse',
        component: howtouse
    },

    {
        path: '/notifications',
        name: 'Notifications',
        component: notifications
    },

    {
        path: '/privacy',
        name: 'Privacy',
        component: privacy
    },

    {
        path: '/fees',
        name: 'Fees',
        component: fees
    },

    {
        path: '/market',
        name: 'Market',
        component: market
    },

    {
        path: '/aboutus',
        name: 'AboutUs',
        component: aboutus
    },

    {
        path: '/trade/:coin',
        name: 'Trade',
        component: trade,
        props: true
    },

    {
        path: '/trade',
        redirect: '/trade/bitcoin',
    },

    {
        path: 'exchange',
        name: 'Exchange',
        component: exchange,
        redirect: {
            name: 'BoxExchange'
        },
        children: [{
                path: '/boxexchange',
                name: 'BoxExchange',
                component: boxexchange,
            },
            {
                path: '/creditdebitcard',
                name: 'CreditdebitCard',
                component: creditdebitcard,
            },
            {
                path: '/addcard',
                name: 'AddCard',
                component: addcard,
            },
        ]
    },

    {
        path: '/otc',
        name: 'OTC',
        component: otc
    },

    {
        path: '/authentication',
        name: 'Authentication',
        component: authentication,
        children: [{
                path: '/Authentication',
                redirect: '/Authentication/login'
            },
            {
                path: 'login',
                name: 'Login',
                component: login
            },
            {
                path: 'forgetpassword',
                name: 'ForgetPassword',
                component: forgetpassword
            },
            {
                path: 'otplogin',
                name: 'OtpLogin',
                component: otplogin
            },
            {
                path: 'otpregister',
                name: 'OtpRegister',
                component: otpregister
            },
            {
                path: 'resetpassword',
                name: 'ResetPassword',
                component: resetpassword
            },
            {
                path: '/fino-verifying',
                name: 'FinoVerifying',
                component: FinoVerifying
            },
            {
                path: 'register',
                name: 'Register',
                component: register
            }
        ]
    },

    {
        path: '/panel',
        name: 'Panel',
        component: panel,
        redirect: {
            name: 'Dashboard'
        },
        children: [{
                path: 'dashboard',
                name: 'Dashboard',
                component: dashboard
            },
            {
                path: 'profile',
                name: 'Profile',
                component: profile
            },
            {
                path: 'security',
                name: 'Security',
                component: security
            },
            {
                path: 'assets',
                name: 'Assets',
                component: assets,
                redirect: {
                    name: 'MainAssets'
                },
                children: [{
                        path: 'mainassets',
                        name: 'MainAssets',
                        component: mainassets,
                    },
                    {
                        path: 'fnDeposite',
                        name: 'FnDeposite',
                        component: fnDeposite,
                    },
                    {
                        path: 'fnWithdraw',
                        name: 'FnWithdraw',
                        component: fnWithdraw,
                    }
                ]
            },
            {
                path: 'history',
                name: 'History',
                component: history,
                redirect: {
                    name: 'OpenOrders'
                },
                children: [{
                        path: 'openorders',
                        name: 'OpenOrders',
                        component: openorders,
                    },

                    {
                        path: 'tradehistory',
                        name: 'TradeHistory',
                        component: tradehistory,
                    },

                    {
                        path: 'orderhistory',
                        name: 'OrderHistory',
                        component: orderhistory,
                    },

                    {
                        path: 'exchangehistory',
                        name: 'ExchangeHistory',
                        component: exchangehistory,
                    },

                    {
                        path: 'transactionhistory',
                        name: 'TransactionHistory',
                        component: transactionhistory,
                        redirect: {
                            name: 'TransactionFiat'
                        },
                        children: [{
                                path: 'transactionfiat',
                                name: 'TransactionFiat',
                                component: transactionfiat,
                            },

                            {
                                path: 'transactioncrypto',
                                name: 'TransactionCrypto',
                                component: transactioncrypto,
                            }
                        ]
                    },
                ]

            },
            {
                path: 'referral',
                name: 'Referral',
                component: referral
            }
        ]
    },

]
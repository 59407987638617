import Vue from 'vue';

const that = Vue.prototype

that.$callBackURL = window.location.origin + '/redirect-from-bank'
that.$google_sso = that.$axios.defaults.baseURL + '/users/google-sso'
that.$refURL = window.location.origin + '/register?ref='
that.$walletSortCoins = ['TOMAN', 'TETHER', 'BITCOIN', 'ETHEREUM', 'BITCOIN_CASH', 'LITE_COIN', 'RIPPLE', 'STELLAR', 'CLASSIC_ETHEREUM', 'DASH', 'DOGE_COIN', 'TRON', 'BINANCE_COIN', 'BINANCE_COIN_BSC', 'CELO', 'MATIC']

that.$etcChannelCoin = ['ETHEREUM', 'CLASSIC_ETHEREUM', 'TETHER']

that.$coinLabel = {
    TOMAN: 'تومان',
    BITCOIN: 'بیتکوین',
    BITCOIN_CASH: 'بیتکوین کش',
    ETHEREUM: 'اتریوم',
    CLASSIC_ETHEREUM: 'اتریوم کلاسیک',
    LITE_COIN: 'لایت کوین',
    BINANCE_COIN: 'بایننس کوین',
    EOS: 'ایاس',
    STELLAR: 'استلار',
    RIPPLE: 'ریپل',
    TRON: 'ترون',
    DASH: 'دش',
    DOGE_COIN: 'دوج',
    TETHER: 'تتر',
    BTC: 'بیتکوین',
    BCH: 'بیتکوین کش',
    ETH: 'اتریوم',
    ETC: 'اتریوم کلاسیک',
    LTC: 'لایت کوین',
    BNB: 'بایننس کوین',
    // EOS: 'ایاس',
    XLM: 'استلار',
    XRP: 'ریپل',
    TRX: 'ترون',
    // DASH: 'دش',
    DOGE: 'دوج',
    USDT: 'تتر',
    bitcoin: 'بیتکوین',
    'bitcoin-cash': 'بیتکوین کش',
    ethereum: 'اتریوم',
    etc: 'اتریوم کلاسیک',
    'ethereum-classic': 'اتریوم کلاسیک',
    litecoin: 'لایت کوین',
    binancecoin: 'بایننس کوین',
    eos: 'ایاس',
    stellar: 'استلار',
    ripple: 'ریپل',
    tron: 'ترون',
    dash: 'دش',
    dogecoin: 'دوج',
    tether: 'تتر',

}

that.$coinUnit = {
    TOMAN: 'تومان',
    BITCOIN: 'BTC',
    BITCOIN_CASH: 'BCH',
    ETHEREUM: 'ETH',
    CLASSIC_ETHEREUM: 'ETC',
    LITE_COIN: 'LTC',
    BINANCE_COIN: 'BNB',
    EOS: 'EOS',
    STELLAR: 'XLM',
    RIPPLE: 'XRP',
    TRON: 'TRX',
    DASH: 'DASH',
    DOGE_COIN: 'DOGE',
    TETHER: 'USDT',
    BINANCE_COIN_BSC: 'BNB',
    CELO: 'CELO',
    MATIC:'MATIC',
    bitcoin: 'BTC',
    ethereum: 'ETH',
    dogecoin: 'DOGE',
    ripple: 'XRP',
    binancecoin: 'BNB',
    stellar: 'XLM',
    'bitcoin-cash': 'BCH',
    litecoin: 'LTC',
    tron: 'TRX',
}

that.$coin2Snake = {
    BTC: 'BITCOIN',
    BTH: 'BITCOIN_CASH',
    ETH: 'ETHEREUM',
    ETC: 'CLASSIC_ETHEREUM',
    BCH: "BITCOIN_CASH",
    LTC: 'LITE_COIN',
    BNB: 'BINANCE_COIN_BSC',
    EOS: 'EOS',
    XLM: 'STELLAR',
    XRP: 'RIPPLE',
    TRX: 'TRON',
    DASH: 'DASH',
    MATIC: 'MATIC',
    CELO: 'CELO',
    DOGE: 'DOGE_COIN',
    USDT: 'TETHER',
    bitcoin: 'BITCOIN',
    'bitcoin-cash': 'BITCOIN_CASH',
    ethereum: 'ETHEREUM',
    etc: 'CLASSIC_ETHEREUM',
    'ethereum-classic': 'CLASSIC_ETHEREUM',
    litecoin: 'LITE_COIN',
    binancecoin: 'BINANCE_COIN_BSC',
    eos: 'EOS',
    stellar: 'STELLAR',
    ripple: 'RIPPLE',
    tron: 'TRON',
    dash: 'DASH',
    dogecoin: 'DOGE_COIN',
    tether: 'TETHER',
    celo: 'CELO',
    'matic-network': 'MATIC',
}

that.$coin3Snake = {
    TOMAN: 'تومان',
    bitcoin: 'BTC',
    'bitcoin-cash': 'BCH',
    ethereum: 'ETH',
    litecoin: 'ETC',
    binancecoin: 'BNB',
    eos: 'EOS',
    stellar: 'XLM',
    ripple: 'XRP',
    tron: 'TRX',
    dash: 'DASH',
    dogecoin: 'DOGE',
    tether: 'USDT',
    celo:'CELO',
    'matic-network':'MATIC'
}

that.$sortCoins = ['bitcoin', 'ethereum', 'ethereum-classic', 'bitcoin-cash', 'litecoin', 'ripple', 'tether', 'dash', 'dogecoin']
that.$accountLevel = []

that.$errorHandeling = {
    BAD_REQUEST: 'The information sent is incorrect',
    NOT_FOUND: 'The requested information was not found',
    FINNOTECH_NOT_COMPLETED: 'The user has not completed the Finotech process',
    CONFLICT: 'You are not allowed',
    FORBIDDEN: 'You do not have the necessary access',
    MOBILE_NUMBER_NOT_FOUND: 'Mobile number is not registered',
    AMOUNT_NOT_ALLOWED: 'The value entered is not allowed',
    'Invalid Phone Number': 'The mobile number is incorrect.',
    'Old Password Is Incorrect': 'The previous password is incorrect.',
    'user not found': 'Username not found',
    'Not Enough Balance': 'Your inventory is not enough.',
    'NOT_ENOUGH_BALANCE_TOMAN': 'Your toman balance is not enough.',
    'SMS_OTP_UNAVAILABLE': 'SMS feature is not enabled.',
    'MARKET_IS_NOT_ACTIVE': 'This market is not active.',
    'incorrect password!!!': 'Wrong username or password',
    'Incorrect username or password.': 'Wrong username or password',
    'no user found with entered username': 'Wrong username or password',
    'Not Enough HDWallets': 'An error has occurred. Contact support',
    'Not Enough Wallets': 'An error has occurred. Contact support',
    'Incorrect OTP': 'The code entered is incorrect.',
    'Incorrect OTP Code.': 'The code entered is incorrect.',
    'Otp code is not valid.': 'The code entered is incorrect.',
    'Code Expired': 'Your code has expired.',
    'username exist.': 'The account is duplicate',
    'You Dont Have This Coin Wallet': 'You do not have the wallet of this currency',
    'You Don\'t Have Enough Credit': 'Inventory is not enough'
}
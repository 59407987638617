<template>
  <div class="login">
    <div class="header-auth">
      <p>Log In</p>
    </div>
    <the-input @updateValue="getEmailValue"  value="Email" type="text" :errorText="emailError"/>
    <the-input @updateValue="getpasswordValue"   value="Password" logo="logo" type="password" :errorText="passError"/>
    <router-link to="ForgetPassword" class="forgetpassword">Forgot Password?</router-link>
    <button @click="send" class="gold-btn" :disabled="data.password === '' || data.email === ''" :class="{'disale':data.password === '' || data.email === '' }">Log In</button>
    <p class="haveaccount">Don’t have an account? <router-link to="register">Register</router-link></p>
  </div>
</template>

<script>
import TheInput from "../../tools/the-input";
export default {
  name: "the-login",
  components: {TheInput},

  data(){
    return{
      data:{
        email:'',
        password:'',
        osType: '',
        deviceType: '',
        browser: '',
      },
      emailError: '',
      passError: ''
    }
  },

  watch:{
    email:function (){


    }
  },

  methods:{
    getEmailValue(value){
      this.data.email = value
    },

    getpasswordValue(value){

      this.data.password = value
    },

    async send() {
      await this.checkEmail()
      this.state.loading = true
      const res = await this.$axios.post('/users/login', this.data)
      if (res.message === 'login successful, otp is required') {

        this.$cookies.set('username', this.data.email)
        this.state.userInfo.email = this.data.email
        this.state.userInfo.mobilenumber = res.baseDTO.starTwoStepAuthentication || '';

        await this.$router.push({name:'OtpLogin'})

      }

      else if (res.token){
        this.$setToken(res.token,this.state.userInfo.remember)
        await this.$router.push('/Panel')
      }

    },

    getData() {
      this.data.osType = this.$detectOs()
      this.data.browser = this.$detectBrowser() || 'CHROME'
      this.data.deviceType = this.$detectDevice()
    },

    async checkEmail() {
      if (!this.$validEmail(this.data.email) || !this.data.email) {
        this.$error('The information entered is incorrect', 'Enter the correct email')
        return Promise.reject('repeat failed : ' + this.data.email)
      }
    },

  },

  mounted() {
    this.getData()
  }


}
</script>

<style lang="scss" scoped>
.login{
  display: flex;
  flex-direction: column;
  gap: 10px;

  .forgetpassword{
    color:#F1CF52;
    font-size: small;
    text-align: end;
  }

  .gold-btn{
    color: white!important;
  }

  .haveaccount{
    text-align: center;
    color: #484D56;
    a{
      color:#F1CF52;
    }
  }

  .disale{
    opacity: 0.7;
    cursor: not-allowed;
  }
}
</style>
<template>

  <table class="public-table">
    <tr>
      <th :class="item.class" v-for="(item , index) in headerData" :key="index">
        <div class="th-table">
          {{ item}}
        </div>
      </th>
    </tr>

    <tr v-for="(item , index) in data" :key="index">
      <td>{{item.relatedCoin}}</td>
      <td>{{item.amount}}</td>
      <td>{{item.hashCode}}</td>
      <td>{{item.createdAtDateTime}}</td>
      <td>{{tableContent.isDone?'Unsuccessful':'Successful'}}</td>
    </tr>

    <tr v-if="!data.length">
      <td colspan="8">
        <p class="no-data"> Custom is not registered. </p>
      </td>
    </tr>

  </table>

</template>

<script>

export default {
  name: "the-transactioncrypto",

  data() {
    return {
      Crypto: true,
      Fiat: false,

      //header data
      headerData: ['Type' , 'Amount' , 'Transaction ID' , 'Date' , 'Status'],



      //data table
      data:[]
    }
  },

  methods:{
    async getWithdraw(){
      const res = await this.$axios.get('wallets/withdrawal-requests', {
        params: {
          size: 10,
          page: this.page
        }
      });
      this.data = res.content
    }
  },

  mounted() {
    this.getWithdraw()
  }

}
</script>

<style lang="scss" scoped>
.no-data {
  color: #cc2d6a;
  font-weight: bold;
  text-align: center;
  width: 100%;
}
//general styles
.td-first {
  text-align: left !important;
}

.Failed{
  color: rgba(72, 77, 86, 0.5)!important;
}

.buttons{
  display: flex;
  flex-direction: row;
  margin: 38px 0;

  .btns{
    Width: 100px;
    Height: 34px;
    color: rgba(72, 77, 86, 1);
    border-radius: 4px;
    background-color: #FFFFFF;
    border:none;
    cursor: pointer;
  }
}



.active{
  @extend .btns;
  background-color: rgba(242, 242, 242, 1) !important;
}

.network {
  color: rgba(70, 19, 163, 1);
  cursor: pointer;
}

.Destination {
  overflow: hidden;
}

.table-div {
  display: flex;
  justify-content: left;
  align-items: center;
}

.th-table {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
}

.td-last {
  text-align: right !important;
}

.public-table {
  table-layout: auto !important;
}

.right {
  justify-content: right !important;
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
}

.success {
  color: rgba(93, 204, 141, 1);
}

.danger {
  color: rgba(242, 53, 126, 1);
}

//main styles
.main-market {
  width: 100%;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  padding: 0 16px;

  h1 {

  }


  .hover-svg {
    fill: #BDBDBD !important;
    width: 8px;
    height: 8px;
    cursor: pointer;
  }

  .hover-svg:hover {
    fill: var(--main-background-color) !important;
  }


  th {
    .arrows {
      display: flex;
      flex-direction: column;
      gap: 1px;
    }

    .item {
      display: flex;
      flex-direction: row;
      gap: 5px;
      align-items: center;
      justify-content: center;
    }
  }

}

@media (max-width: 690px) {
  .hide {
    display: none !important;
  }
}
</style>

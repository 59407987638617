<template>
  <div class="main-dashboard">

    <!--    dashboard header comp-->
    <div class="box-dashboard">
      <the-header-dashboard/>
    </div>
    <!--    start row-2-->
    <div class="row-2">
      <the-chart/>
      <the-referal/>
    </div>
    <!--    start row-3-->
    <div class="row-3">
      <the-login-history/>
      <the-order-table/>
    </div>
  </div>
</template>

<script>
import TheHeaderDashboard from "./component/row-1/the-header-dashboard";
import TheChart from "./component/row-2/the-chart";
import TheReferal from "./component/row-2/the-referal";
import TheLoginHistory from "./component/row-3/the-login-history";
import TheOrderTable from "./component/row-3/the-order-table";

export default {
  name: "the-dashboard",
  components: {TheOrderTable, TheLoginHistory, TheReferal, TheChart, TheHeaderDashboard}
}
</script>

<style lang="scss" scoped>


//main style
.main-dashboard {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 10px 0;

  .row-2 {
    display: flex;
    flex-direction: row;
    gap: 16px;
    flex-wrap: wrap;
  }

  .row-3 {
    display: flex;
    flex-direction: row;
    gap: 16px;
    flex-wrap: wrap;
  }
}
</style>
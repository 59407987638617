<template>
  <div class="assets-box balnce">
    <the-deposit-modal v-if="depositpanelmodal === true" @close="depositpanelmodal = false"/>
    <the-withdraw-modal v-if="withdrawpanelmodal === true" @close="withdrawpanelmodal = false"/>

    <div class="Estimated-balance">
      <div class="title">
        Estimated balance
      </div>
      <div class="value">
        <img src="../../../../assets/panel/coin.png" alt="">
        {{Tether.credit}}
      </div>
    </div>
    <div class="Frozen">
      <div class="title">Frozen</div>
      <div class="value">
        {{Tether.blockedCredit}}
      </div>
    </div>
    <div class="Available">
      <div class="title">Available</div>
      <div class="value">
        {{Tether.wholePriceOfCredit}}
      </div>
    </div>
    <div class="buttons">
      <button class="purple-btn" @click="depositpanelmodal = true">Deposit</button>
      <button class="solid-button" @click="withdrawpanelmodal = true">Withdraw</button>
    </div>
  </div>
</template>

<script>
import TheDepositModal from "./the-Deposit-modal";
import TheWithdrawModal from "./the-Withdraw-modal";
export default {
  name: "the-balance",
  components: {TheWithdrawModal, TheDepositModal},
  data(){
    return{
      Tether:[],
      depositpanelmodal:false,
      withdrawpanelmodal:false
    }
  },

  methods:{
    async getWallets() {
      this.state.loading = true
      const [res,] = await this.$http.get('/wallets/customer-addresses')
      if (res) {
        let entireRes = Object.entries(res)
        const sortCoins = this.$walletSortCoins
        entireRes.sort((a, b) => sortCoins.indexOf(a[0]) - sortCoins.indexOf(b[0]))
       let mainTether = entireRes.find(a => a[0] === 'TETHER');
        this.Tether = mainTether[1]
      }
      this.state.loading = false
    },

  },

  mounted() {
    this.getWallets()
  }
}
</script>

<style lang="scss" scoped>
//shared class
.item-balanc {
  display: flex;
  flex-direction: column;
  gap: 9px;
  flex-grow: 1;
  flex-basis: 100px;

  .title {
    color: #828282;
    font-size: clamp(12px, 1vw, 18px);
  }

  .value {
    color: #484D56;
    font-size: clamp(14px, 1vw, 24px);
    align-items: center;
    display: flex;
    gap: 10px;
  }
}

//general class
.balnce {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;

  .Estimated-balance {
    @extend .item-balanc;
    img{
      width: 25px;
      height: 25px;
    }
  }

  .Frozen {
    @extend .item-balanc;
  }

  .Available {
    @extend .item-balanc;
  }

  .buttons {
    display: flex;
    flex-direction: row;
    gap: 10px;
    flex-grow: 1;
    justify-content: end;
    align-items: center;

    button{
      height: 38px !important;
    }
  }
}
</style>
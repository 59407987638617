<template>
  <the-public-modal @close="$emit('close')">
    <slot>
      <div class="header">
        <span class="title">
          Cell Number Veification
        </span>
      </div>
      <span class="text">To start trading, please verify your cell number.</span>
      <the-input value="Email"/>
      <the-input value="Cell Number"/>
      <span  v-if="shotwostep === true" class="text">
        Enter the 6-digit code sent to your cell number.
      </span>
      <div class="cod-input">
        <CodeInput
            v-if="shotwostep === true"
            :loading="false"
            :fields="6"
            @input="$emit('update' , value)"
            @change="onChange"
            @complete="submit"
            ref="code-input"
        />
      </div>
      <button @click="send" class="gold-btn">Send Code</button>
      <button v-if="shotwostep === true" @click="submit" class="gold-btn">Submit</button>
    </slot>
  </the-public-modal>
</template>

<script>
import ThePublicModal from "../../../../tools/the-public-modal";
import TheInput from "../../../../tools/the-input";
import CodeInput from "vue-verification-code-input"
export default {
  name: "the-get-code-verify",
  components: {TheInput, ThePublicModal , CodeInput},

  data(){
    return{
      shotwostep : false
    }
  },

  methods:{
    send(){
      this.shotwostep = true;

    },

    submit(){

    }
  }
}
</script>

<style lang="scss" scoped>
.gold-btn {
  color: #FFFFFF;
  margin-top: 30px;
}

.header{
  display: flex;
  justify-content: center;

  .title{
    font-size: clamp(16px , 2vw , 20px);
    color: rgba(70, 19, 163, 1);
  }
}

.text{
  color: rgba(72, 77, 86, 1);
  font-size: clamp(14px , 2vw , 16px);
}
</style>
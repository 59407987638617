<template>
  <the-public-modal @close="$emit('close')">
    <slot>
      <div class="header">
        <span class="title">
          Cell Number Veification
        </span>
      </div>
      <span class="text">To start trading, please verify your cell number.</span>
      <the-input value="Email"/>
      <the-input value="Cell Number"/>
      <button @click="$emit('showGetcodeModal')" class="gold-btn">Send Code</button>
    </slot>
  </the-public-modal>
</template>

<script>
import TheInput from "../../../../tools/the-input";
import ThePublicModal from "../../../../tools/the-public-modal";

export default {
  name: "the-send-code-verify-phone",
  components: {ThePublicModal, TheInput}
}
</script>

<style lang="scss" scoped>
.gold-btn {
  color: #FFFFFF;
  margin-top: 30px;
}

.header{
  display: flex;
  justify-content: center;

  .title{
    font-size: clamp(16px , 2vw , 20px);
    color: rgba(70, 19, 163, 1);
  }
}

.text{
  color: rgba(72, 77, 86, 1);
  font-size: clamp(14px , 2vw , 16px);
}
</style>
<template>
  <div class="main-item">
    <div class="item" v-for="(item , index) in readmessages" :key="index">
      <span class="bold-text">{{ item.title }}</span>
      <span class="explain">{{ item.content }}</span>
      <span class="date">{{ item.createdAtDateTime }}</span>
    </div>

    <div class="item" v-for="(item , index) in unreadmessages" :key="index">
      <span class="bold-text">{{ item.title }}</span>
      <span class="explain">{{ item.content }}</span>
      <span class="date">{{ item.createdAtDateTime }}</span>
    </div>

  </div>
</template>

<script>
export default {
  name: "the-notif-box",
  props: ["data"],
  data() {
    return {
      readmessages:[],
      unreadmessages:[],

    }
  },

  methods:{
    async getUnreadMessages(page = this.page, perPage = this.perPage) {
      this.state.loading = true
      const res = await this.$axios.get('/users/notifications',
          {
            params: {
              read: false, page: page, size: perPage,
            }
          })
      this.unreadmessages = res.content
    },
    async getReadMessages(page = this.page, perPage = this.perPage) {
      this.state.loading = true
      const res = await this.$axios.get('/users/notifications',
          {
            params: {
              read: true, page: page, size: perPage,
            }
          })
      this.readmessages = res.content
    },
  },

  mounted() {
    this.getUnreadMessages();
    this.getReadMessages()
  }


}
</script>

<style lang="scss" scoped>
.main-item {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0 32px;

  .item {
    display: flex;
    flex-direction: column;
    gap: 4px;

    .bold-text {
      font-weight: 500;
      position: relative;
      font-size: clamp(12px, 2vw, 14px);
      color: #FFFFFF;

      &:before {
        content: "";
        width: clamp(6px, 2vw, 8px);
        height:  clamp(6px, 2vw, 8px);
        border-radius: 50%;
        position: absolute;
        background-color: #00A35F;
        left: -15px;
        top: 25%;
      }
    }

    .explain {
      font-size: clamp(10px, 2vw, 12px);
      color: rgba(224, 224, 224, 1);
      overflow: hidden;
      height: 15px;
    }

    .date {
      font-size: clamp(10px, 2vw, 12px);
      color: rgba(224, 224, 224, 1);
    }
  }
}
</style>
<template>
  <div class="box-dashboard">
    <div class="main-chart">
      <div class="header">
        <span class="title">Balance Details</span>
        <div class="arrow-div">
          <span @click="$router.push({name:'Assets'})" class="link-to-balanc">Account Balance </span>
        </div>
      </div>
      <div class="chart">
        <apex-chart type="donut" width="100%" height="100%" :options="chartOptions" :series="series"
                    :key="series.length + '' + chartOptions.labels.length + change"/>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: "the-chart",

  data() {
    return {
      series: [0],

      change: 0,

      chartOptions: {

        chart: {
          width: '100px',
          fontFamily: 'IBMPlexSans',
          fontSize:'16px',
          color: 'rgb(72, 77, 86)',
        },

        colors: [
          '#E57373', '#81C784', '#4DD0E1', '#7986CB', '#BA68C8', '#FF9ACC', '#FFB74D', '#F7EA72', '#4DB6AC', '#90A4AE'
        ],

        theme: {
          mode: 'light',
          palette: 'palette2'
        },

        stroke: {
          width: 1,
          colors: undefined,
          lineCap:'round'
        },

        labels: [],

        dataLabels: {
          style: {
            fontFamily: 'IBMPlexSans'
          }
        },

        legend: {
          show: true,
          customLegendItems: [],
        },

        plotOptions: {
          pie: {
            offsetX: 0,
            offsetY: 0,
            donut: {
              size: '75%',
              background: 'white',
              labels: {
                show: true,
                name: {
                  show: true,
                  fontSize: '12px',
                  fontFamily: 'IBMPlexSans',
                  fontWeight: 600,
                  color: 'var(--tooDark)',
                  offsetY: -17,
                  formatter: function (val) {
                    return val.name || val
                  }
                },
                value: {
                  show: true,
                  fontSize: '12px',
                  fontFamily: 'IBMPlexSans',
                  fontWeight: 400,
                  color: '#484D56',
                  offsetY: 10,
                  formatter: function (val) {
                    return parseFloat(val).toLocaleString()
                  }
                },
                total: {
                  show: true,
                  showAlways: false,
                  label: 'Account Balance',
                  fontSize: '16px',
                  fontFamily: 'IBMPlexSans',
                  fontWeight: 600,
                  color: '#484D56',
                  formatter: function (w) {
                    w = w.globals.seriesTotals.reduce((a, b) => a + b)
                    return w.toLocaleString() + '\n' + 'USD'
                  }
                }
              }
            },
          }
        },

        tooltip: {
          enabled: true,
          fillSeriesColor: true,
          custom: function ({
                              seriesIndex,
                              w
                            }) {
            return ` ${w.globals.seriesNames[seriesIndex].value.toLocaleString()} ${w.globals.seriesNames[seriesIndex].name} `
          }
        },

        responsive: [
          {
            breakpoint: 1000,
            options: {
              plotOptions: {
                bar: {
                  horizontal: false
                },

                pie: {
                  offsetX: 0,
                  offsetY: 0,
                  donut: {
                    size: '75%',
                    background: 'white',
                    labels: {
                      show: false,
                    }
                  },
                }
              },
              legend: {
                position:'bottom'
              },
            }
          }
        ]
      },

    }
  },

  methods: {
    async getWallets() {
      const res = await this.$axios.get('/wallets/customer-addresses')
      let entireRes = Object.entries(res)
      const sortCoins = ['TOMAN', 'TETHER', 'BITCOIN', 'ETHEREUM', 'BITCOIN_CASH', 'LITE_COIN', 'RIPPLE', 'CLASSIC_ETHEREUM', 'DASH', 'DOGE_COIN']
      entireRes.sort((a, b) => sortCoins.indexOf(a[0]) - sortCoins.indexOf(b[0]))
      this.wallets = entireRes
    },


    chart() {
      let index = 0
      this.wallets.forEach((e) => {
        if (e[0] !=='TOMAN') {
          this.chartOptions.labels[index] = {
            'name': this.$coinUnit[e[0]],
            'value': e[1].credit || 0
          }
          this.series[index] = e[1].totalBalance || e[1].wholePriceOfCredit || 0;

          this.chartOptions.legend.customLegendItems[index] = (e[1].confirmedCredit ? e[1].confirmedCredit + this.$coinUnit[e[0]] : 0 + this.$coinUnit[e[0]])
          index++
        }
      })
      ++this.change

    },
  },

  async mounted() {
    this.state.loading = true
    await this.getWallets()
    // await this.getData()
    this.chart()
    const res = await this.$axios.get('/users/invites')
    this.data = res.baseDTO
  },

}
</script>

<style lang="scss" scoped>
.chart{
  height: 350px !important;
}

.box-dashboard {
  display: flex;
  flex-grow: 2;
  flex-basis: 100px;
}

.main-chart {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  padding: 10px;

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;

    .title {
      color: rgba(72, 77, 86, 1);
      font-size: clamp(0.9rem, 2.5vw, 1.3rem);
    }

    .arrow-div {
      background-image: url("../../../../../assets/panel/right-arrow.png");
      background-position: right center;
      background-repeat: no-repeat;
      display: flex;
      align-items: center;
      padding: 0 20px;
      cursor: pointer;

      .link-to-balanc {
        color: rgba(130, 130, 130, 1);
        font-size: clamp(0.8rem, 2.5vw, 0.9rem);
      }
    }
  }
}
</style>

<style>
.apexcharts-legend {
  height: auto !important;
  position: absolute !important;
  left: auto !important;
  top: 24px !important;
  right: 5px !important;
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: wrap !important;
  overflow: auto !important;
  width: 55% !important;
  gap: 20px;
  justify-content: start !important;
  align-items: center !important;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.apexcharts-legend-series {
  display: flex !important;
  align-items: center !important;
  align-items: start;
  width: 150px;
}

.apexcharts-legend-marker {
  width: 25px !important;
  height: 25px !important;
  border-radius: 4px !important;
}

.apexcharts-legend-text{
  color: rgba(72, 77, 86, 1) !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  font-family: IBMPlexSans !important;
  font-family: IBMPlexSans !important;
}

@media(max-width: 1000px){
  .apexcharts-legend{
    top:45% !important;
    left: 0 !important;
  }
}
</style>
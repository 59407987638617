import { render, staticRenderFns } from "./the-chart.vue?vue&type=template&id=2dbf1782&scoped=true&"
import script from "./the-chart.vue?vue&type=script&lang=js&"
export * from "./the-chart.vue?vue&type=script&lang=js&"
import style0 from "./the-chart.vue?vue&type=style&index=0&id=2dbf1782&lang=scss&scoped=true&"
import style1 from "./the-chart.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2dbf1782",
  null
  
)

export default component.exports
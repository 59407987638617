<template>
  <div class="main-market">
    <h1>Exchange History</h1>
    <!--  public table-->
    <div class="table-div">
      <table class="public-table">
        <tr>
          <th :class="item.class" v-for="(item , index) in headerData" :key="index">
            <div class="th-table">
              {{ item.name }}
              <div class="arrows">
                <inline-svg v-if="item.sort" class="hover-svg"
                            :src="require('../../../../assets/public/arrow-top.svg')"/>
                <inline-svg v-if="item.sort" class="hover-svg"
                            :src="require('../../../../assets/public/arrow-bottom.svg')"/>
              </div>
            </div>
          </th>
        </tr>

        <tr v-for="(item , index) in data" :key="index">
          <td>{{ item.Date }}</td>
          <td :class="item.class">{{ item.Coin }}</td>
          <td :class="{'success' : item.Type === 'Limit/Buy' , 'danger' : item.Type === 'Limit/Sell'}">
            {{ item.Type }}
          </td>
          <td :class="item.class">{{ item.Amount }}</td>
          <td>{{ item.Price }}</td>
          <td :class="item.class">{{ item.Network }}</td>
          <td :class="item.class"><a class="network">{{ item.TxID }}</a></td>
          <td>{{ item.Total }}</td>
          <td :class="item.class">{{ item.Status }}</td>
        </tr>

      </table>
    </div>

  </div>
</template>

<script>

export default {
  name: "the-exchangehistory",

  data() {
    return {
      datesort: [],
      sort: [],
      //header data
      headerData: [
        {name: "Date", sort: true},
        {name: "Coin", sort: true, class:"hide"},
        {name: "Type", sort: true, class: "hide"},
        {name: "Amount", sort: false, class:"hide"},
        {name: "Price", sort: false},
        {name: "Network", sort: false},
        {name: "TxID", sort: false, class:"hide"},
        {name: "Total", sort: true, class:"hide"},
        {name: "Status", sort: false},
      ],

      //data table
      data: [
        {
          Date: "2021-01-09 08:30:59",
          Coin: "BTC/USDT",
          Type: "Limit/Buy",
          Amount: 0.07235,
          Price: 12928930.28,
          TxID: 'More...',
          Network: "ERC20",
          Total: 5726.37,
          Status: "Canceelled",
          class: 'hide',
        },
        {
          Date: "2021-01-09 08:30:59",
          Coin: "BTC/USDT",
          Type: "Limit/Buy",
          Amount: 0.07235,
          Price: 12928930.28,
          TxID: 'More...',
          Network: "ERC20",
          Total: 5726.37,
          Status: "Canceelled",
          class: 'hide',
        },
        {
          Date: "2021-01-09 08:30:59",
          Coin: "BTC/USDT",
          Type: "Limit/Buy",
          Amount: 0.07235,
          Price: 12928930.28,
          TxID: 'More...',
          Network: "ERC20",
          Total: 5726.37,
          Status: "Canceelled",
          class: 'hide',
        },
        {
          Date: "2021-01-09 08:30:59",
          Coin: "BTC/USDT",
          Type: "Limit/Buy",
          Amount: 0.07235,
          Price: 12928930.28,
          TxID: 'More...',
          Network: "ERC20",
          Total: 5726.37,
          Status: "Canceelled",
          class: 'hide',
        },
        {
          Date: "2021-01-09 08:30:59",
          Coin: "BTC/USDT",
          Type: "Limit/Buy",
          Amount: 0.07235,
          Price: 12928930.28,
          TxID: 'More...',
          Network: "ERC20",
          Total: 5726.37,
          Status: "Canceelled",
          class: 'hide',
        },
        {
          Date: "2021-01-09 08:30:59",
          Coin: "BTC/USDT",
          Type: "Limit/Buy",
          Amount: 0.07235,
          Price: 12928930.28,
          TxID: 'More...',
          Network: "ERC20",
          Total: 5726.37,
          Status: "Canceelled",
          class: 'hide',
        },
        {
          Date: "2021-01-09 08:30:59",
          Coin: "BTC/USDT",
          Type: "Limit/Buy",
          Amount: 0.07235,
          Price: 12928930.28,
          TxID: 'More...',
          Network: "ERC20",
          Total: 5726.37,
          Status: "Canceelled",
          class: 'hide',
        },
        {
          Date: "2021-01-09 08:30:59",
          Coin: "BTC/USDT",
          Type: "Limit/Buy",
          Amount: 0.07235,
          Price: 12928930.28,
          TxID: 'More...',
          Network: "ERC20",
          Total: 5726.37,
          Status: "Canceelled",
          class: 'hide',
        },
        {
          Date: "2021-01-09 08:30:59",
          Coin: "BTC/USDT",
          Type: "Limit/Buy",
          Amount: 0.07235,
          Price: 12928930.28,
          TxID: 'More...',
          Network: "ERC20",
          Total: 5726.37,
          Status: "Canceelled",
          class: 'hide',
        },
        {
          Date: "2021-01-09 08:30:59",
          Coin: "BTC/USDT",
          Type: "Limit/Buy",
          Amount: 0.07235,
          Price: 12928930.28,
          TxID: 'More...',
          Network: "ERC20",
          Total: 5726.37,
          Status: "Canceelled",
          class: 'hide',
        },

      ]
    }
  },
}
</script>

<style lang="scss" scoped>
//general styles
.td-first {
  text-align: left !important;
}
.network{
  color: rgba(70, 19, 163, 1);
  cursor: pointer;
}
.table-div {
  display: flex;
  justify-content: left;
  align-items: center;
}

.th-table {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
}

.td-last {
  text-align: right !important;
}

.public-table {
  table-layout: fixed !important;
}

.right {
  justify-content: right !important;
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
}

.success {
  color: rgba(93, 204, 141, 1);
}

.danger {
  color: rgba(242, 53, 126, 1);
}

//main styles
.main-market {
  width: 100%;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  padding: 0 16px;

  h1 {

  }


  .hover-svg {
    fill: #BDBDBD !important;
    width: 8px;
    height: 8px;
    cursor: pointer;
  }

  .hover-svg:hover {
    fill: var(--main-background-color) !important;
  }


  th {
    .arrows {
      display: flex;
      flex-direction: column;
      gap: 1px;
    }

    .item {
      display: flex;
      flex-direction: row;
      gap: 5px;
      align-items: center;
      justify-content: center;
    }
  }

}

@media (max-width: 690px) {
  .hide {
    display: none !important;
  }
}
</style>

<template>
  <div class="main-market">
    <the-delet-request-modal v-if="showDeletModal === true" @delet="delet" @notdelet="cancelDelet"
                             @close="showDeletModal = false"/>
    <h1>Open Orders</h1>
    <!--  public table-->
    <div class="table-div">
      <table class="public-table">
        <tr>

          <th>
            <div class="th-table">
             <span>Date</span>
              <div class="arrows">
                <inline-svg @click="AscendingDate"
                            :src="require('../../../../assets/public/arrow-top.svg')"/>

                <inline-svg @click="DescendingDate"
                            :src="require('../../../../assets/public/arrow-bottom.svg')"/>
              </div>
            </div>
          </th>

          <th class="hide">
            <div class="th-table">
              <span>Pair</span>
              <div class="arrows">
                <inline-svg @click="AscendingPair"
                            :src="require('../../../../assets/public/arrow-top.svg')"/>

                <inline-svg @click="DescendingPair"
                            :src="require('../../../../assets/public/arrow-bottom.svg')"/>
              </div>
            </div>
          </th>

          <th>
            <div class="th-table">
              <span>Type</span>
              <div class="arrows">
                <inline-svg @click="AscendingType"
                            :src="require('../../../../assets/public/arrow-top.svg')"/>

                <inline-svg @click="DescendingType"
                            :src="require('../../../../assets/public/arrow-bottom.svg')"/>
              </div>
            </div>
          </th>

          <th class="hide">
            Amount
          </th>

          <th class="hide">
            Price
          </th>

          <th class="hide">
            Filled
          </th>

          <th>
            <div class="th-table">
              <span>Total</span>
              <div class="arrows">
                <inline-svg @click="AscendingTotal"
                            :src="require('../../../../assets/public/arrow-top.svg')"/>

                <inline-svg @click="DescendingTotal"
                            :src="require('../../../../assets/public/arrow-bottom.svg')"/>
              </div>
            </div>
          </th>

          <th>
            Operation
          </th>


        </tr>

        <tr v-if="!tableContents.length">
          <td colspan="8">
            <p class="no-data"> Custom is not registered. </p>
          </td>
        </tr>

        <tr v-else v-for="(item , index) in tableContents" :key="index">
          <td>{{ item.createdAtDateTime }}</td>
          <td class="hide" :class="item.class">{{ item.destinationCoin }}</td>
          <td :class="{
            'success' : item.orderType === 'BUY, LIMITED_BUY,MARKET_BUY,STOP_LIMIT_BUY'  ,
            'danger' : item.orderType === 'LIMITED_SELL , MARKET_SELL, SELL,STOP_LIMIT_SELL'}"
          >
            {{ item.orderType }}
          </td>
          <td class="hide">{{ item.amount }}</td>
          <td class="hide">{{ item.unitPrice }}</td>
          <td class="hide">{{ item.executedAmount }}</td>
          <td>{{ item.wholePrice }}</td>
          <td>
            <a @click="cancelRequest(item.id)" class="cancel">Cancel</a>
          </td>
        </tr>

      </table>
    </div>

  </div>
</template>

<script>

import TheDeletRequestModal from "@/components/tools/the-deletRequest-modal";
export default {
  name: "the-openorders",
  components: {TheDeletRequestModal},
  data() {
    return {
      showDeletModal: false,
      isRemove: false,

      //data table
      tableContents: []
    }
  },

  methods: {
    //get open orders
    async getActiveOrders() {
      const res = await this.$axios.get('/orders', {
        params: {
          orderStatus: 'IS_OPEN',
          market: this.state.MarketType,
        }
      })
      this.tableContents = res.content || [];
      this.$emit('update:activeLength', this.tableContents.length)
      this.loading = false
    },

   // sorting table
    AscendingDate(){
      let data = this.tableContents
      data.sort((a, b) => b.createdAtDateTime - a.createdAtDateTime);
      this.tableContents = data;
     console.log(this.tableContents)
    },

    DescendingDate(){
      let data = this.tableContents
      data.sort((a, b) => a.createdAtDateTime - b.createdAtDateTime);
      this.tableContents = data;
      console.log(this.tableContents)
    },

    AscendingPair(){
      let data = this.tableContents
      data.sort((a, b) => b.Pair - a.Pair);
      this.tableContents = data;
      console.log(this.tableContents)
    },

    DescendingPair(){
      let data = this.tableContents
      data.sort((a, b) => a.Pair - b.Pair);
      this.tableContents = data;
      console.log(this.tableContents)
    },

    AscendingTotal(){
      let data = this.tableContents
      data.sort((a, b) => b.Total - a.Total);
      this.tableContents = data;
      console.log(this.tableContents)
    },

    DescendingTotal(){
      let data = this.tableContents
      data.sort((a, b) => a.Total - b.Total);
      this.tableContents = data;
      console.log(this.tableContents)
    },

    async cancelRequest(e) {
      this.state.loading = true;
      const delet = await this.$axios.delete(`orders/${e}`);
      if (delet) {
        await this.getOpenOrders();
        this.showDeletModal = false
        this.showDeletModal = true;
      }
    },


    delet() {
      this.isRemove = true;
      this.cancelRequest()
      this.isRemove = false;
    },

  },

  mounted() {
    this.getActiveOrders()
  }
}
</script>

<style lang="scss" scoped>
//general styles

.td-first {
  text-align: left !important;
}

.table-div {
  display: flex;
  justify-content: left;
  align-items: center;
}

.th-table {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.td-last {
  text-align: right !important;
}

.right {
  justify-content: right !important;
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
}

.success {
  color: rgba(93, 204, 141, 1);
}

.danger {
  color: rgba(242, 53, 126, 1);
}

//main styles
.main-market {
  width: 100%;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  padding: 0 16px;

  h1 {

  }


  .hover-svg {
    fill: #BDBDBD !important;
    width: 8px;
    height: 8px;
    cursor: pointer;
  }

  .hover-svg:hover {
    fill: var(--main-background-color) !important;
  }


  th {

    .arrows {
      display: flex;
      flex-direction: column;
      gap: 1px;

      svg{
        fill: #a7a7a7;
        &:hover{
          fill: #4613A3;
          cursor: pointer;
        }
      }
    }

    .item {
      display: flex;
      flex-direction: row;
      gap: 5px;
      align-items: center;
      justify-content: center;
    }
  }

}

@media (max-width: 690px) {
  .hide {
    display: none !important;
  }
}

.no-data {
  color: #cc2d6a;
  font-weight: bold;
  text-align: center;
  width: 100%;
}

@media (max-width: 992px) {
  .hide{
    display: none;
  }
}
</style>
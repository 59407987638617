<template>
  <div class="main-use">
    <!--  custom header component  -->
    <the-header value="How To Use"/>
    <div class="main">
      <h2 class="top-title">Account Verification</h2>
      <!--    custom dropdown component -->
      <the-dropdown v-for="(item , index) in use" :key="index" :data="item"/>

      <h2 class="bottom-title">Cryptocurrency Exchange</h2>
      <!--    custom dropdown component -->
      <the-dropdown v-for="(item , index) in use" :key="index" :data="item"/>

    </div>
  </div>
</template>

<!--script component-->
<script>
import TheHeader from "../tools/The-header";
import TheDropdown from "../tools/the-dropdown";

export default {
  name: "the-how-to-use",
  components: {TheDropdown, TheHeader},
  data() {
    return {
      //drop down component data
      use: [

      ]
    }
  },

  methods:{
    async getFaq() {
      this.use = await this.$axios.get('/user-manual');
    }
  },

  mounted() {
    this.getFaq()
  }
}
</script>

<!--style component-->
<style lang="scss" scoped>
.main-use {
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: #E5E5E5;

  .main {
    width: 90%;
    margin: 20px auto;

    .top-title {
      color: var(--main-background-color);
    }

    .bottom-title {
      color: var(--main-background-color);
    }
  }
}
</style>
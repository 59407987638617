<template>
  <the-public-modal @close="$emit('close')">
    <div class="title">
      <span>Fiat Deposit</span>
    </div>
    <div class="input-modal">
      <div class="main-select">
        <select @click="name = false" v-model="value" class="public-select">
          <option v-for="(item , index) in numbercart" :key="index">
            {{ item }}
          </option>
        </select>
        <span v-if="name === true" class="select-value">Card</span>
      </div>
      <the-input value="Amount"/>
    </div>
    <button class="gold-btn">Continue</button>
  </the-public-modal>
</template>

<script>
import ThePublicModal from "../../../tools/the-public-modal";
import TheInput from "../../../tools/the-input";

export default {
  name: "the-Deposit-modal",
  components: {TheInput, ThePublicModal},
  data() {
    return {
      value: "",
      numbercart: [58922020685412, 58922020685412, 58922020685412],
      name: true
    }
  }
}
</script>

<style lang="scss" scoped>

.title {
  font-weight: bold;
  color: rgba(72, 77, 86, 1);
}

.gold-btn {
  color: #FFFFFF !important;
}

.input-modal {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.main-select {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;

  .hide-name {
    display: none;
  }

  select {
    width: 100%;
    cursor: pointer;
    color: #bdb6b6;

    option {
      text-align: center;
      color: rgba(72, 77, 86, 1);
    }
  }

  .select-value {
    font-size: small;
    color: #bdb6b6;
    position: absolute;
    left: 10px;
    top: 30%;
  }

}

</style>
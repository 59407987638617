import Vue from 'vue'

// axios
import axios from 'axios'
import router from '../router/index'

const that = Vue.prototype

if (that.state.token) {
    axios.defaults.headers.common['Authorization'] = that.state.token
}

axios.defaults.baseURL = 'https://vistadev.xyz/api'


axios.interceptors.response.use(
    (res) => {
        that.state.loading = false

        return res.data;
    },
    (err) => {
        that.state.loading = false

        const res = err.response || err.toJSON()

        if (err.response)
            if (res.data.message)
                if (res.data.message.includes('JWT') || res.data.message.includes('Invalid Token')) {
                    that.$logout()
                    return Promise.reject(err);
                } else if (err.response.data.message.includes('ShebaCode')) {
            that.$error('Sheba number or duplicate card.', '')
            return Promise.reject(err);
        } else if (['GOOGLE_FAILURE', 'Can\'t Access Resource'].includes(err.response.data.message)) {
            return Promise.reject(err);
        } else if (err.response.data.message === "USER_IDENTITY_DATA") {

            const a = {
                MOBILE_IS_INCORRECT: 'The mobile number is duplicate.',
                NATIONAL_CODE_IS_INCORRECT: 'The national code is duplicate.',
                TELEPHONE_IS_INCORRECT: 'The phone number is duplicate.',
            }
            let uniqueErrors = '';
            for (let i = 0; i < err.response.data.errors.length; i++) {
                uniqueErrors = uniqueErrors + (a[err.response.data.errors[i]] || err.response.data.errors[i]) + '\n'
            }
            that.$error('The information is duplicate', uniqueErrors);
            return Promise.reject(err);
        } else if (err.response.data.status === 'BAD_REQUEST') {
            let a = err.response.data.errors[0]
            // a = JSON.parse(a.match(/\[(.*?)\]/)[1])
            const codeError = {
                'invalid otp or mobile': 'The code entered is incorrect',
                'otp used before': 'The code has expired',
            }
            const finoError = {
                'nid is not valid': 'The national code is incorrect',
                'mobile is not valid': 'The mobile number is incorrect',
                'nid and mobile is not match': 'Your mobile number does not match the national code',
                'No records found. Please control the input values': 'Date of birth does not match other information.',
            }
            if (finoError[a]) {
                that.$error('Authentication error', codeError[a] || finoError[a]);
                if (finoError[a]) {
                    router.push({
                        name: 'userAccount'
                    })
                }
                return Promise.reject(err);
            }
        }

        // if (err.response ? ['UNAUTHORIZED'].includes(res.data.status) : false) {
        //     return Promise.reject(err);
        // }

        const text = err.response ?
            that.$errorHandeling[err.response.data.message] ||
            that.$errorHandeling[err.response.data.status] ||
            res.data.errors ||
            res.data.error :
            res.message

        if (err.response && that.$errorHandeling[err.response.data.message] || that.$errorHandeling[err.response.data.status]) {
            that.$swal({
                icon: 'error',
                title: err.request.status,
                text: text,
                confirmButtonText: 'OK',
                customClass: {
                    confirmButton: 'btn-success',
                },
            })
        }

        return Promise.reject(err);
    }, );

let func = async (config) => {
    try {
        const res = await axios(config)
        return [res, null]
    } catch (e) {
        return [null, e]
    }
}
const props = {
    get: async (url, config) => {
        try {
            const res = await axios.get(url, config)
            return [res, null]
        } catch (e) {
            return [null, e]
        }
    },
    post: async (url, data, config) => {
        try {
            const res = await axios.post(url, data, config)
            return [res, null]
        } catch (e) {
            return [null, e]
        }
    },
    put: async (url, data, config) => {
        try {
            const res = await axios.put(url, data, config)
            return [res, null]
        } catch (e) {
            return [null, e]
        }
    },
}
for (let prop in props) {
    func[prop] = props[prop];
}
that.$http = func;

that.$axios = axios

export default axios
import VueRouter from "vue-router";
import routes from "./routes";
import Vue from "vue";
const that = Vue.prototype
Vue.use(VueRouter);

const router = new VueRouter({
    mode: "history",
    routes: routes,
});

const forLogin = [
    'History',
    'Dashboard',
    'MainAssets',
    'Security',
    'Notifications',
    'Profile',
    'OpenOrders',
    'Trade',
]
const unAuthorUser = [
    'History',
    'Dashboard',
    'MainAssets',
    'OpenOrders',
]
const otp = [
    'OtpLogin',
    'OtpRegister',
]
const auth = [
    'Login',
    'Register',
    'ForgetPassword',
]

router.beforeEach(async (to, from, next) => {

    document.title = to.name

    that.state.loading = true
    if (that.state.token) {

        if (!that.state.gotten) {
            await that.$getuserInfo()
            that.state.loading = true
            await that.$getFees()
        }

        that.state.loading = true

        if (from.name === 'Blog') {
            await window.open(to.fullPath, '_self')
        } else if (otp.includes(to.name)) {
            next({name: 'Home'})
        } else if (to.name === 'Trade') {
            try {
                that.state.loading = true
                await that.$axios(`/markets/BITCOIN_TETHER`)
                next()
            } catch (e) {
                if (e.response.data.message === "MARKET_IS_NOT_ACTIVE") {
                    if (otp.includes(from.name)) {
                        next({name: 'Home'})
                    } else {
                        await that.$error('The market is inactive')
                        next(from)
                    }
                }
            }
        } else {
            if (that.state.userInfo.authorized === true) {
                next()
            } else {
                if (otp.includes(from.name) && to.name !== 'Profile') {
                    next({name: 'Profile'})
                } else if (unAuthorUser.includes(to.name)) {
                    that.state.loading = false
                    const res = await that.$error('Inaccessibility', 'To access this section, your account must first be completed and approved', 'error', 'Account')
                    if (res.isConfirmed) {
                        next('/panel/Profile')
                    } else {
                        next(from)
                    }
                } else next()
            }
        }
    } else {
        if ((otp.includes(to.name) && !Vue.$cookies.isKey('username')) || forLogin.includes(to.name))
            next({name: 'Login'})
        else {
            next()
        }
    }
    that.state.loading = false
})

export default router
<template>
  <div class="main-item">
    <div v-if="sell" class="balanced">
      <span class="blnc-1">Balance :</span>
      <span v-if="wallets.wholePriceOfCredit > 0 " class="blnc-2">{{
          wallets.wholePriceOfCredit
        }}{{ $coin3Snake[$route.params.coin] }}</span>
      <span v-if="wallets.wholePriceOfCredit <= 0 " class="blnc-2">0 {{
          $coin3Snake[$route.params.coin]
        }}</span>
    </div>

    <div v-if="buy" class="balanced">
      <span class="blnc-1">Balance :</span>
      <span class="blnc-2">{{ usdt.wholePriceOfCredit }} USDT</span>
    </div>

    <div class="main-input">
      <div class="custom-input">
        <input :class="{'disable' : select === 'Market'}" :disabled="select === 'Market'" v-model="price" type="text">
        <span class="placholder">Price</span>
        <span class="type">USDT</span>
        <span v-if="select === 'Market'" class="markettype">Market</span>
      </div>
    </div>

    <div class="main-input">
      <div class="custom-input">
        <input v-if="buy" v-model="amount" type="text">
        <input @input="sellTotal" v-if="sell" v-model="AllowedAmount" type="text">
        <span class="placholder">Amount</span>
        <span class="type">{{ $coin3Snake[$route.params.coin] }}</span>
      </div>
    </div>

    <the-range v-if="buy" @change="buyinputrangevalue"/>
    <the-range v-if="sell" @change="sellinputrangevalue"/>

    <div class="main-input" v-if="select === 'Limit'">
      <div class="custom-input">
        <span v-if="showDate === false" class="placholder">Expiration Date(Optonal)</span>
        <img @click="showDate = !showDate" src="../../../../../assets/public/date.png" alt="">
        <datepicker format="MM/DD/yyyy" v-if="showDate === true" placeholder="Please select Date" :value="state.date"
                    v-model="date" name="uniquename"></datepicker>
      </div>
    </div>

    <div class="main-input">
      <div class="custom-input">
        <input v-if="buy" v-model="buytotal" type="text">
        <input v-if="sell" v-model="selltotal" type="text">
        <span class="placholder">Total</span>
        <span class="type">USDT</span>
      </div>
    </div>

    <p v-if="buy" class="error">
      {{buyError}}
    </p>

    <p v-if="sell" class="error">
      {{sellError}}
    </p>

    <button :disabled="isBuyError === true ||isSellError === true" :class="{'red-btn':sell , 'green-btn':buy , 'disable' :isBuyError === true || isSellError === true}">
      <span v-if="buy" @click="Buy">Buy {{ $coin3Snake[$route.params.coin] }}</span>
      <span v-if="sell" @click="sel">Sell {{ $coin3Snake[$route.params.coin] }}</span>
    </button>
    <button v-if="this.state.token === ''" class="gray-btn">Log In or Register Now</button>
  </div>
</template>

<script>
import TheRange from "../../../../tools/the-range";
import Datepicker from 'vuejs-datepicker';

export default {
  name: "the-change-component",
  props: ['buy', 'sell', 'select'],
  components: {
    TheRange,
    Datepicker
  },

  data() {
    return {
      state: {
        date: new Date(2021, 9, 16)
      },
      showDate: false,
      customerAddress: [],
      price: 0,
      amount: 0,
      buytotal: 0,
      selltotal:0,
      date: '',
      usdt: [],
      wallets: [],
      AllowedAmount:0,
      buyError:'',
      sellError:'',
      isBuyError:true,
      isSellError:true
    }
  },


  methods: {

    buyinputrangevalue(e) {
      this.amount = e;
      this.showtotal();
      //get price from server
      if (this.select === 'Market') {
        this.getSellPriceFromServer()
      }
    },

    sellinputrangevalue(e) {
      this.AllowedAmount = (this.wallets.wholePriceOfCredit * e);
      this.showtotal();
      //get price from server
      if (this.select === 'Market') {
        this.getSellPriceFromServer()
      }
    },

    sellTotal(){
      this.selltotal = this.AllowedAmount * this.price
    },

    async getWalletCredit() {
      const res = await this.$axios.get('/wallets/customer-addresses', {
        params: {
          coins: this.$coin2Snake[this.$route.params.coin]
        },
      })
      this.customerAddress = res[this.$coin2Snake[this.$route.params.coin]]
    },


    //buy crypto
    async Buy() {
      this.state.loading = true;
      let orderDetail;
      let marketType = this.$coin2Snake[this.$route.params.coin] + '_TETHER';

      if (this.select === 'Limit') {
        orderDetail = {
          marketType: marketType,
          amount: this.$S2N(this.amount),
          orderType: `LIMITED_BUY`,
          expiresAt: this.date || null,
          unitPrice: this.$S2N(this.price),
          wholePrice: this.$S2N(this.total),
        }
      } else if (this.select === 'Market') {

        orderDetail = {
          marketType: marketType,
          amount: this.$S2N(this.amount),
          orderType: `MARKET_BUY`,
        }
      }

      try {
        const res = await this.$axios.post(`/orders/buy`, orderDetail, {
          params: {
            force: true
          }
        });
        this.state.loading = true;
      } catch (error) {
        if (error.response.data.message === "the amount of order is bigger than market liquidity") {
          await this.$error('', 'The requested volume is more than the market volume.')
        }
      }
    },

    //sell crypto
    async sel() {
      this.state.loading = true;
      let orderDetail;
      let marketType = this.$coin2Snake[this.$route.params.coin] + '_TETHER';

      if (this.select === 'Limit') {
        orderDetail = {
          marketType: marketType,
          amount: this.$S2N(this.amount),
          orderType: `LIMITED_SELL`,
          expiresAt: this.date || null,
          unitPrice: this.$S2N(this.price),
          wholePrice: this.$S2N(this.total),
        }
      } else if (this.select === 'Market') {
        orderDetail = {
          marketType: marketType,
          amount: this.$S2N(this.amount),
          orderType: `MARKET_SELL`,
        }
      }

      try {
        const res = await this.$axios.post(`/orders/sell`, orderDetail, {
          params: {
            force: true
          }
        });
        this.state.loading = true;
      } catch (error) {
        if (error.response.data.message === "the amount of order is bigger than market liquidity") {
          await this.$error('', 'The requested volume is more than the market volume.')
        }
      }
    },


    //total value
    showtotal() {
      this.buytotal = (this.price * this.amount).toFixed(3);
      this.selltotal = (this.price * this.AllowedAmount).toFixed(3)
    },

    //get price from server
    async getSellPriceFromServer() {
      let amount = this.$S2N(this.amount);
      const [res,] = await this.$http.get('/orders/market-buy-sell-whole', {
        params: {
          amount: amount,
          marketType: this.$coin2Snake[this.$route.params.coin] + '_TETHER',
          orderType: 'MARKET_SELL'
        }
      })
      if (res) {
        this.price = this.$toLocal(res.baseDTO.wholePrice);
      }
    },

    //get selected coin data
    async getSelectedCoinData() {
      const coin = this.$route.params.coin
      this.state.loading = true
      let [res,] = await this.$http.get('/wallets/customer-addresses', {
        params: {
          coins: this.$coin2Snake[coin]
        }
      })

      if (res) {
        let entireRes = Object.entries(res)
        const sortCoins = this.$walletSortCoins
        entireRes.sort((a, b) => sortCoins.indexOf(a[0]) - sortCoins.indexOf(b[0]))
        this.wallets = entireRes[0][1]
      }
      this.state.loading = false
    },

  //  get USDT Amount
    async getUSDT() {
      const coin = this.$route.params.coin
      this.state.loading = true
      let [res,] = await this.$http.get('/wallets/customer-addresses', {
        params: {
          coins: 'TETHER'
        }
      })

      if (res) {
        let entireRes = Object.entries(res)
        const sortCoins = this.$walletSortCoins
        entireRes.sort((a, b) => sortCoins.indexOf(a[0]) - sortCoins.indexOf(b[0]))
        this.usdt = entireRes[0][1];
      }
      this.state.loading = false
    },

  },


  watch:{
    'buytotal':function (){
      if (this.buytotal > this.usdt.wholePriceOfCredit){
        this.buyError = 'The requested amount is too much';
        this.isBuyError = true
      }
      else  {
        this.buyError = '';
        this.isBuyError = false
      }

    },

    'selltotal':function (){
      if (this.selltotal > this.wallets.wholePriceOfCredit){
        this.sellError = 'The requested amount is too much';
        this.isSellError = true
      }
      else if (this.selltotal <= this.wallets.wholePriceOfCredit) {
        this.sellError = '';
        this.isSellError = false
      }

    },
  },

  mounted() {
    this.getWalletCredit()
    this.getSelectedCoinData()
    this.getUSDT()
  }
}
</script>

<style lang="scss" scoped>

.disable {
  cursor: not-allowed;
  opacity: 0.7;
}

.error{
  color: #cc2d6a;
  font-size: clamp(10px , 2vw , 14px);
}

.main-item {
  flex-grow: 1;
  flex-basis: 215px;
  flex-direction: column;
  display: flex;
  gap: 0;

  .balanced {
    display: flex;
    justify-content: space-between;
    font-size: 14px;

    .blnc-1 {
      color: rgba(189, 189, 189, 1);
    }

    .blnc-2 {
      color: rgba(189, 189, 189, 1);
    }
  }

  .red-btn {
    color: #FFFFFF;
  }

  .green-btn {
    color: #FFFFFF;
  }

  .gray-btn {
    color: var(--main-background-color);
    font-weight: 500;
  }

  .main-input {
    width: 95%;
    margin: 10px auto;
    height: 36px;
    display: flex;

    .custom-input {
      width: 100%;
      background-color: rgba(242, 242, 242, 1);
      position: relative;
      color: rgba(189, 189, 189, 1);
      border-radius: 3px;

      input {
        background-color: transparent;
        border: 0;
        position: absolute;
        left: 30%;
        height: 100%;
        text-align: left;
        outline-style: none;
        color: rgba(72, 77, 86, 1);
        width: 60%;
      }


      .placholder {
        position: absolute;
        left: 10px;
        top: 25%;
        font-size: clamp(10px, 2vw, 14px);
      }

      .type {
        position: absolute;
        right: 10px;
        top: 25%;
        font-size: 14px;
      }

      .markettype {
        position: absolute;
        right: 50px;
        top: 25%;
        font-size: 14px;
        color: rgba(72, 77, 86, 1);
      }

      img {
        position: absolute;
        right: 10px;
        font-size: 12px;
        top: 27%;
        cursor: pointer;
      }
    }
  }
}


</style>
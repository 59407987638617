<template>
  <div class="main-drop-notif">
    <div class="content">
      <div class="header">
        <div class="number-of-notif">
          <span class="number">{{messages.length}}</span>
          <span class="text">pending notifications</span>
        </div>
        <div class="link-to-all-notif">
          <span @click="$router.push({name:'Notifications'})" class="text">View All</span>
          <img src="../../../assets/public/next.png" alt="">
        </div>
      </div>
      <the-notif-box :data="items"/>
    </div>
  </div>
</template>

<script>
import TheNotifBox from "./the-notif-box";
export default {
  name: "the-drop-notifications",
  components: {TheNotifBox},

  data(){
    return{
      messages:[]
    }
  },

  methods:{
    async getMessages(){
      const res = await this.$axios.get('/users/notifications');
      this.messages = res.content
    }
  },

  mounted() {
    this.getMessages()
  }
}
</script>

<style lang="scss" scoped>
.main-drop-notif {
  display: flex;
  flex-direction: column;
  padding: 16px;
  background-color: rgba(70, 19, 163, 1);
  border-radius: 0 0 12px 12px;
  position: absolute;
  top:150%;
  overflow: auto;
  max-height:420px;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  z-index: 3;
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.24);
  width: 320px;

  .content{
    display: flex;
    flex-direction: column;

    .header{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      height: 60px;
      align-items: center;
      background-color: rgba(125, 88, 192, 1);
      border-radius: 6px;
      margin-bottom: 24px;
      padding: 16px 24px;
      cursor: pointer;

      .text{
        color: #F2F2F2;
        font-size: clamp(12px,2vw,14px);
      }

      .number-of-notif{
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;

        .number{
          font-size: clamp(14px,2vw,22px);
          color: #FFFFFF;
        }
      }

      .link-to-all-notif{
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
      }
    }
  }

}
</style>
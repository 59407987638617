<template>
  <div id="faciallverification" class="faciallverification">
    <span class="title-info">Facial Verification</span>
    <div class="faciallverification box-info">
      <p class="text">
        Upload a photo of you handholding the front of ID document and statement, please write down the date of
        submission and "Binance".
      </p>
      <div   class="upload">
        <div class="true-sample-pic">
          <img src="../../../../assets/panel/true-sample-pic.png" alt="">
          <img src="../../../../assets/panel/false-sample-pic.png" alt="">
        </div>

        <form
            @submit.prevent="upload"
            @dragover.prevent
            @dragenter="hovered=!hovered"
            @dragleave="hovered=!hovered"
            @drop.stop.prevent="hovered=false;drop($event);"
            :style="{borderColor : hovered? 'blue' : ''}"
            class="upload-image">

          <label>
            <img v-if = "!path"  src="../../../../assets/panel/upload-pic.png" alt="">
            <img v-if = "path" class="img" :src="path" alt="">
            <input
                type="file"
                name="picture"
                id="picture"
                style="display: none"
                @change="upload($event.target)"
                :disabled="readonly"
                v-if="!readonly"
                required>
          </label>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UploadDocuments",
  props: ['data'],
  data() {
    return {
      path: '',
      loadedpicture: '',
      hovered: '',
    }
  },
  watch: {
    'data.image'() {
      if (this.data.image)
        if (typeof this.data.image === 'string')
          this.path = this.data.image
    }
  },
  computed: {
    readonly() {
      return ['AUTHORIZED', "INFORMATION_CHANGED"].includes(this.state.userInfo.authenticationType)
    }
  },
  methods: {

    drop(e) {
      let dt = e.dataTransfer
      this.upload(dt)
    },


    upload(evt) {
      let file = evt.files[0]
      this.data.image = file
      console.log(this.data.image)
      let fileReader = new FileReader();
      fileReader.addEventListener("loadstart", (event) => {
        this.loadedpicture = ((event.loaded / event.total) * 100).toFixed(0);
      })
      fileReader.addEventListener("progress", (event) => {
        this.loadedpicture = ((event.loaded / event.total) * 100).toFixed(0);
      });
      fileReader.addEventListener('load', (event) => {
        this.loadedpicture = ((event.loaded / event.total) * 100).toFixed(0);
        this.path = event.target.result
      });
      fileReader.readAsDataURL(file)
    },

    hover() {
      this.hovered = true
    }
  }
}
</script>

<style lang="scss" scoped>
.disabl{
  cursor: not-allowed !important;
}

.faciallverification {
  display: flex;
  flex-direction: column;

  .text {
    color: rgba(130, 130, 130, 1);
    font-size: small;
  }

  .upload {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    flex-direction: row;
    flex-wrap: wrap;

    .true-sample-pic{
      display: flex;
      flex-direction: row;
      gap: 20px;
      flex-wrap: wrap;
    }

    .img{
      width: 213px;
      height: 186px;
      padding: 10px;
    }

    .upload-image {
      width: 220px;
      height: 190px;
      border: 2px dashed #4613A3;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      img {
        cursor: pointer;
      }
    }
  }
}
</style>
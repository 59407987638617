<template>
  <div class="otp">
    <div class="header-auth">
      <p>Security verification </p>
    </div>
    <p class="text">
      Please enter the 6-digit verification code that was sent to {{state.registerEmail}} The code is valid for  {{ countDown }}s.
    </p>
    <div class="cod-input">
      <CodeInput
          :loading="false"
          class="input"
          :fields="6"
          @input="$emit('update' , value)"
          @change="onChange"
          @complete="submit"
          ref="code-input"
      />
    </div>
    <a @click="send" class="resend">Resend Email</a>
  </div>
</template>

<script>
import {setPlaceholder, reset, paste} from "@/lib/reuseableFunction";
import CodeInput from "vue-verification-code-input"
export default {
  name: "the-otp",
  components: {CodeInput},

  data(){
    return{
      countDown:120,
      otp: {
        code: '',
        email: this.state.userInfo.email || this.$cookies.get('username')
      }
    }
  },

  methods:{
    onChange(e) {
      this.otp.code = e
      this.state.otpcode = e
    },

    countDownTimer() {
      if (this.countDown > 0) {
        this.timeOut = setTimeout(() => {
          this.countDown--
          this.countDownTimer()
        }, 1000)
      } else {
        this.$error('Your time is up', '')
      }
    },

    async submit() {
      if (!this.disabled) {
        this.loading = true
        const res = await this.$axios.post('/users/check-otp-code?mobile=true', this.otp).catch(()=>{})
        this.reset()
        this.loading = false
        if (res.token) {
          await this.$setToken(res.token)
          await this.$router.push({name:'Panel'})

        }
      }
    },

    async send() {
      this.state.loading = true
      this.reset()
      await this.$axios.post(`/users/resend-otp-code?email=${this.$cookies.get('username')}`)

      this.$error('New code to ' + this.state.registerEmail + ' You were sent.', '', 'success')
      this.$cookies.set('username', this.state.userInfo.email)

      if (this.countDown === 0) {
        this.countDown = 120
        this.countDownTimer()
      }

    },

    setPlaceholder,
    reset,
    paste
  },

  beforeDestroy() {
    clearTimeout(this.timeOut)
  },

  mounted() {

    this.countDownTimer()
  }
}
</script>

<style lang="scss" scoped>
.otp{
  display: flex;
  flex-direction: column;
  gap: 10px;

  .text{
    color: rgba(72, 77, 86, 1);
    font-size: 14px;
    margin: 10px 40px;
  }

  .resend{
    color: rgba(241, 207, 82, 1) ;
    opacity: 0.7;
    text-align: center;
    margin-top: 30px;
    cursor: pointer;
    text-decoration:underline;
  }
}
.cod-input{
  display: flex;
  justify-content: center;
  margin: 0 auto;
  width: 90%;
}

.react-code-input-container{
  display: flex !important;
  justify-content: space-between!important;
}

.react-code-input{
  width: 100%;
  display: flex !important;
  justify-content: space-between!important;
}

.react-code-input > input{
  border-radius: 5px !important;
  margin: 0 3px;
  border-right: 1px solid #a8adb7 !important;
  width: 40px !important;
  height: 40px !important;
}
</style>
<template>
  <div class="main-panel">

    <!--    side bar section -->
    <div :class="{'show-option' : showoptions === true}" class="sidbar">

      <div @click="$router.push({name : 'Dashboard'})" class="item"
           :class="{'div-active' : $route.matched[1].name === 'Dashboard'}">
        <div class="name-item">
          <inline-svg
              :src="require('../../assets/panel/dashboard.svg')"
              :class="{'icon-active' : $route.matched[1].name === 'Dashboard'}"
              class="icon"
          />
          <span class="span-name">Dashboard</span>
        </div>
      </div>

      <div @click="$router.push({name : 'Profile'})" class="item"
           :class="{'div-active' : $route.matched[1].name === 'Profile'}">
        <div class="name-item">
          <inline-svg
              :src="require('../../assets/panel/user.svg')"
              :class="{'icon-active' : $route.matched[1].name === 'Profile'}"
              class="icon"
          />
          <span class="span-name">Profile</span>
        </div>
        <div class="step-Auth">
          <h6 :class="{
           'verify': Auth === 'AUTHORIZED' ,
           'pending': Auth === 'PENDING' ,
            'pending': Auth === 'NOT_COMPLETE' ,
           'notVerify': Auth === 'UNAUTHORIZED'}">
            {{ Auth.replace(/_/g , "") }}
          </h6>
        </div>
      </div>

      <div @click="$router.push({name : 'Security'})" class="item"
           :class="{'div-active' : $route.matched[1].name === 'Security'}">
        <div class="name-item">
          <inline-svg
              :src="require('../../assets/panel/security.svg')"
              :class="{'icon-active' : $route.matched[1].name === 'Security'}"
              class="icon"
          />
          <span class="span-name">Security</span>
        </div>
      </div>

      <div @click="$router.push({name : 'Assets'})" class="item"
           :class="{'div-active' : $route.matched[1].name === 'Assets'}">
        <div class="name-item">
          <inline-svg
              :src="require('../../assets/panel/wallet.svg')"
              :class="{'icon-active' : $route.matched[1].name === 'Assets'}"
              class="icon"
          />
          <span class="span-name">Assets</span>
        </div>
      </div>

      <div @click="$router.push({name : 'History'})" class="item"
           :class="{'div-active' : $route.matched[1].name === 'History'}">
        <div class="name-item">
          <inline-svg
              :src="require('../../assets/panel/history.svg')"
              :class="{'icon-active' : $route.matched[1].name === 'History'}"
              class="icon"
          />
          <span class="span-name">History</span>

          <img @click="showoptions = true" v-if="showoptions === false" class="arrow-bottom"
               src="../../assets/public/arrow-bottom.svg" alt="">

          <img @click="showoptions = false" v-if="showoptions === true" class="arrow-top"
               src="../../assets/public/arrow-top.svg" alt="">

        </div>
      </div>

      <div v-if="showoptions === true">
        <ul class="ul-list">
          <li :class="{'li-active' : $route.name === 'OpenOrders'}"
              @click="$router.push({name:'OpenOrders'})">
            <span>Open Orders</span>
          </li>

          <li :class="{'li-active' : $route.name === 'TradeHistory'}"
              @click="$router.push({name:'TradeHistory'})">
            <span>Trade History</span>
          </li>

          <li :class="{'li-active' : $route.name === 'OrderHistory'}"
              @click="$router.push({name:'OrderHistory'})">
            <span>Order History</span>
          </li>

          <li :class="{'li-active' : $route.name === 'ExchangeHistory'}"
              @click="$router.push({name:'ExchangeHistory'})">
            <span>Exchange History</span>
          </li>

          <li :class="{'li-active' : $route.path.includes('transactioncrypto' , 'transactionfiat')}"
              @click="$router.push({name:'TransactionHistory'})">
            <span>Transaction History</span>
          </li>

        </ul>
      </div>

      <div @click="$router.push({name : 'Referral'})" class="item"
           :class="{'div-active' : $route.matched[1].name === 'Referral'}">
        <div class="name-item">
          <inline-svg
              :src="require('../../assets/panel/referal.svg')"
              :class="{'icon-active' : $route.matched[1].name === 'Referral'}"
              class="icon"
          />
          <span class="span-name">Referral</span>
        </div>
      </div>

      <div @click="exit" class="item">
        <div class="name-item" style="cursor: pointer">
          <inline-svg
              :src="require('../../assets/panel/exit.svg')"
              class="icon"
          />
          <span class="span-name">Exite</span>
        </div>
      </div>

  </div>

    <!--   main bar section  -->
    <div :class="{'hide-main' : showlayer === true , 'main-bar' :  showlayer === false}">
      <router-view/>
    </div>

  </div>

</template>

<script>

import Vue from "vue";

export default {
  name: "the-panel",

  data() {
    return {
      width: 0,
      Auth: {},
      numberverify: 2,
      showname: true,
      showlayer: false,
      showoptions: false,
      items: [
        {name: "Dashboard", path: "dashboard.svg", logo: true},
        {name: "Profile", path: "user.svg", logo: true},
        {name: "Security", path: "security.svg", logo: true},
        {name: "Assets", path: "wallet.svg", logo: true},
        {
          name: "History", path: "history.svg", logo: true, options: [
            {name: "OpenOrders", path: "history.svg", logo: false},
            {name: "TradeHistory", path: "history.svg", logo: false},
            {name: "OrderHistory", path: "history.svg", logo: false},
            {name: "ExchangeHistory", path: "history.svg", logo: false},
            {name: "TransactionHistory", path: "history.svg", logo: false},
          ]

        },
        {name: "Referral", path: "referal.svg", logo: true},
        {name: "Exite", path: "exit.svg", logo: true},
      ]
    }
  },

  methods: {
    changeRout(e) {
      this.$router.push({name: e});
    },

    Fnshowname() {
      this.showname = !this.showname;
      this.showlayer = !this.showlayer;
    },

    hideParentHistory() {
      for (let i; i <= this.items.length; i++) {
        if (this.items[i].logo === false) {
        }
      }
    },

    logout() {
      localStorage.clear('token')
      Vue.$cookies.remove('--abcd14589')
      this.state.token = ''
      this.state.gotten = false
      this.$axios.defaults.headers.common['Authorization'] = this.state.token
      try {
        this.$router.push('/')
      } catch (e) {
        location.reload()
      }
    },

    exit(){
      this.state.token = '';
      this.$router.push('/')
    },

    async GetStepAuth() {
      this.state.loading = true;
      const res = await this.$axios("/users/account", {params: {'image': false}});
      if (res) {
        this.Auth = res.baseDTO.authenticationType;
        this.state.userInfo.authorized = res.baseDTO.authenticationType
      }
    },
  },

  mounted() {
    this.width = screen.width;
    this.showname = this.width > 768;
    this.GetStepAuth()
  }
}
</script>

<style lang="scss" scoped>

//generall style--------------
.step-Auth {
  .notVerify {
    color: #cc2d6a;
    font-size: 12px;
    font-weight: bold;
    margin-right: 5px;
  }

  .pending {
    color: #efde0c;
    font-size: 12px;
    font-weight: bold;
    margin-right: 5px;
  }

  .verify {
    color: #00A35F;
    font-size: 12px;
    font-weight: bold;
    margin-right: 5px;
  }

}


.logout {
  display: flex;
  flex-direction: row;
  gap: 10px;
  cursor: pointer;

  svg {
    width: 19px;
    margin-left: 30px !important;
  }

  span {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    color: #484d56;
    font-size: clamp(.8rem, 2.5vw, 1rem);
  }
}

.verify {
  display: flex;
  flex-direction: row;
  padding-right: 10px;

  .group-green-ciricle {
    display: flex;
    position: relative;

    .number-verified {
      position: absolute !important;
      left: 43% !important;
      top: 8% !important;
      color: #FFFFFF !important;
      font-size: small !important;
    }
  }
}

.solid-parent {
  &:before {
    content: none !important;
  }

  .icon-active {
    fill: #484d56 !important;
  }
}

.active-parent {
  &:before {
    content: "" !important;
  }

  .icon-active {
    fill: #c2a748 !important;
  }
}

.not-verify {
  display: flex;
  flex-direction: row;
  padding-right: 10px;

  span {
    font-size: 15px !important;
  }
}

.red {
  color: rgba(204, 45, 106, 1) !important;
}

.green {
  color: rgba(74, 163, 113, 1) !important;
}

.cricle-green {
  position: relative;

  &:before {
    content: "";
    background-color: rgba(74, 163, 113, 1);
    border-radius: 50%;
    width: 20px;
    height: 20px;
    margin-left: 5px;
    display: flex;
    justify-content: center;
    color: #FFFFFF;

  }
}

.cricle-red {
  position: relative;

  &:before {
    content: "!";
    background-color: rgba(204, 45, 106, 1);
    border-radius: 50%;
    width: 18px;
    height: 18px;
    margin-left: 5px;
    display: flex;
    justify-content: center;
    color: #FFFFFF;

  }
}

.li-active {
  background-color: #f2f2f2 !important;

  &::before {
    content: "";
    width: 5px;
    height: inherit;
    position: absolute;
    background-color: rgba(194, 167, 72, 1);
    border-radius: 0 5px 5px 0;
  }
}

.icon-active {
  fill: rgba(194, 167, 72, 1) !important;
}

.text-active {
  color: rgba(194, 167, 72, 1) !important;
}

.div-active {
  background-color: rgba(242, 242, 242, 1);
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &::before {
    content: "";
    width: 5px;
    height: inherit;
    position: absolute;
    background-color: rgba(194, 167, 72, 1);
    border-radius: 0 5px 5px 0;
  }

  .icon-active {
    margin-left: 3px !important;
  }
}

.open-div {
  flex-direction: column !important;
  height: auto !important;
  background: #FFF !important;
}

.ul-list {
  padding-left: 0;
  width: 100%;

  li {
    text-decoration: none;
    list-style: none;
    color: rgb(72, 77, 86);
    font-size: clamp(0.8rem, 2.5vw, 1rem);
    height: 40px;
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
    cursor: pointer;

    span {
      position: absolute;
      left: 20%;
    }


  }
}


//main style-------------

.main-panel {
  display: flex;
  flex-direction: row;
  width: 100%;

  .sidbar {
    box-shadow: 4px 0 4px rgba(70, 19, 163, 0.05);
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: #fff;
    position: relative;
    min-width: 210px;
    height: 100vh;

    .sidebar-button {
      display: none;
    }

    .max-width {
      max-width: 50px;
    }

    .auto-width {
      width: min-content;
    }

    .show-sidbar:before {
      content: ">";
      width: 15px;
      height: 50px;
      background-color: #c2a748 !important;
      position: absolute;
      top: 20%;
      left: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      box-shadow: 9px 0 9px -7px;
      border-radius: 0 10px 10px 0;
      cursor: pointer;
    }

    .close-sidbar:before {
      content: "<";
      width: 20px;
      height: 50px;
      background-color: #FFFFFF;
      position: absolute;
      top: 20%;
      left: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      box-shadow: 9 0 9px -7px;
      border-radius: 0 10px 10px 0;
      cursor: pointer;
    }

    .name-item {
      display: flex;
      flex-direction: row;
      gap: 10px;
      font-size: clamp(0.9rem, 2.5vw, 1rem);
      width: 100%;
      height: 40px;
      align-items: center;

      .icon {
        fill: rgba(72, 77, 86, 1);
        margin-left: 20px !important;
      }

      .span-name {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
      }
    }

    .name-item-active {
      display: flex;
      flex-direction: row;
      gap: 10px;
      font-size: clamp(0.9rem, 2.5vw, 1rem);
      width: 100%;
      height: 40px;
      align-items: center;

      &::before {
        content: "";
        width: 5px;
        height: inherit;
        position: absolute;
        background-color: rgba(194, 167, 72, 1);
        border-radius: 0 5px 5px 0;
      }

      .icon {
        fill: rgba(72, 77, 86, 1);
        margin-left: 28px !important;
        width: 22px;
      }

      .span-name {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
      }

    }


    .item {
      display: flex;
      flex-direction: row;
      gap: 10px;
      height: 40px;
      align-items: center;
      cursor: pointer;
      position: relative;
      justify-content: space-between;

      .arrow-bottom {
        margin-left: auto;
        right: 16px;
        position: absolute;
        fill: #a8adb7 !important;
        top: 45%;
        width: 10px;
      }

      .arrow-top {
        margin-left: auto;
        right: 16px;
        position: absolute;
        fill: #a8adb7 !important;
        top: 43%;
        width: 10px;
      }

      .margin-left {
        margin-left: 20%;
      }


      span {
        color: rgba(72, 77, 86, 1);
        font-size: clamp(0.8rem, 2.5vw, 1rem);
      }
    }
  }

  .main-bar {
    flex-grow: 8;
    display: flex;
    width: 100%;
    flex-direction: column;
  }


  .hide-main {
    width: min-content !important;
  }
}

@media (max-width: 768px) {
  .main-panel {
    height: auto;
    position: relative;
  }

  .sidbar {
    min-width: min-content !important;
    background-color: #fff;
  }

  .show-option {
    min-width: 100px !important;
  }

  .sidebar-button {
    display: block !important;
  }

  .icon, .icon-active {
    margin-left: 10px !important;
  }

}

@media (max-width: 475px) {
  .span-name {
    display: none !important;
  }

  .not-verify {
    display: none;
  }

  .name-item {
    gap: 0 !important;
  }

  .arrow-top {
    top: 100% !important;
    bottom: 0 !important;
    left: 50% !important;
  }

  .arrow-bottom {
    top: 100% !important;
    bottom: 0 !important;
    left: 50% !important;
  }
}

@media (max-width: 600px) {
  .step-Auth{
    display: none;
  }
}
</style>
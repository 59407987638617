<template>
  <div class="bankinfo">
    <span class="title-info">Bank Info</span>
    <div class=" box-info">
      <!--      enter cart -->
      <div class="form">
        <label class="label-input">
          <input  v-model="cart.bankName" placeholder="Name on Card" class="global-input">
          <span class="spn-valu">Name on Card</span>
        </label>

        <label class="label-input">
          <input  v-model="cart.cardNumber" placeholder="card  Number" class="global-input">
          <span class="spn-valu">card  Number</span>
        </label>

        <label class="label-input">
          <input  v-model="cart.shebaCode" placeholder="IBAN" class="global-input">
          <span class="spn-valu">IBAN</span>
        </label>

<!--        <label class="label-input">-->
<!--          <input  v-model="cart.bankName" placeholder="Name on Card" class="global-input">-->
<!--          <span class="spn-valu">Provider</span>-->
<!--        </label>-->
      </div>


      <!--      add account -->
      <div class="form" v-for="(item , index) in data.bankAccounts" :key="index">
        <label class="label-input">
          <input v-if="data.bankAccounts[index]"  v-model="data.bankAccounts[index].bankName"
                 placeholder="Name on Card" class="global-input">
          <span class="spn-valu">Name on Card</span>
        </label>

        <label class="label-input">
          <input v-if="data.bankAccounts[index]"  v-model="data.bankAccounts[index].cardNumber"
                 placeholder="card  Number" class="global-input">
          <span class="spn-valu">card  Number</span>
        </label>

        <label class="label-input">
          <input v-if="data.bankAccounts[index]"  v-model="data.bankAccounts[index].shebaCode"
                 placeholder="IBAN"
                 class="global-input">

          <span class="spn-valu">IBAN</span>
        </label>

<!--        <label class="label-input">-->
<!--          <input v-if="data.bankAccounts[index]"  v-model="data.bankAccounts[index].bankName"-->
<!--                 placeholder="Provider" class="global-input">-->

<!--          <span class="spn-valu">Provider</span>-->
<!--        </label>-->
        <img @click="removeaccount" class="remove" src="../../../../assets/public/delet.png" alt="">
      </div>
    </div>
    <button @click.prevent="addaccount" class="gold-btn">Add Cart</button>
  </div>
</template>

<script>
export default {
  name: "the-bankinfo",
  props: ['data'],

  data() {
    return {
      cart: {shebaCode: "", cardNumber: "", bankName: ""}
    }
  },

  methods: {

    removeaccount(e) {
      this.data.bankAccounts.splice(e, 1)
    },

    addaccount() {
      this.data.bankAccounts.push(this.cart);
      this.cart = {shebaCode: "", cardNumber: "", bankName: ""}

      }
    }
}
</script>

<style lang="scss" scoped>

.form {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;

  label {
    width: 240px !important;
  }
}

.remove {
  width: 20px;
  height: 20px;
  cursor: pointer;
}


.gold-btn {
  width: 150px;
  color: #FFFFFF;
  margin-top: 20px;
}

.box-info {
  justify-content: flex-start;
  align-items: center;
  display: flex;
  gap: 10px;
}

.spn-valu:focus {
  color: rgba(130, 130, 130, 1) !important;
}


.global-input:focus + .spn-valu {
  color: rgba(130, 130, 130, 1);
  position: absolute;
  bottom: 75%;
  left: 8px;
  padding: 0 5px;
  width: fit-content;
  background-color: #FFFFFF;
  font-size: 14px;
}

.global-input:focus + .spn-valu {
  display: inline;
}

.global-input:focus::placeholder {
  color: transparent;
}

.address-input {
  position: relative;
  width: 530px !important;
  max-width: 530px !important;
}

.label-input {
  position: relative;
  width: 260px;
  max-width: 260px;

  input {
    text-align: left;
  }

  .global-input {
    background-color: transparent;
    border: 2px solid #BDBDBD;
    border-radius: 5px;
    padding: 10px;
    color: rgba(130, 130, 130, 1);
    font-size: small;
    font-family: IBMPlexSans, serif;
    width: 100%;
    position: relative;
    outline: none;


    &:focus {
      border: 2px solid #BDBDBD;
      border-radius: 5px;
      padding: 10px;
      background-color: transparent;
    }
  }

  .global-input:focus + .spn-valu {
    display: flex;
  }

  .spn-valu {
    color: rgba(130, 130, 130, 1);
    position: absolute;
    bottom: 80%;
    left: 8px;
    padding: 0 5px;
    width: fit-content;
    background-color: #FFFFFF;
    font-size: 14px;
    display: none;
  }
}


.visibility {
  position: absolute;
  left: 91%;
  top: 25%;
  cursor: pointer;
  z-index: 1;
}


@media (max-width: 992px) {
  .email-input {
    width: 100% !important;
  }

  .global-input {
    width: 100% !important;
  }

  .label-input {
    max-width: 200px !important;
  }
}

</style>
<template>
  <div class="login-history">
  <span class="title-info">
   Login History
  </span>

    <div class="history">
      <table class="public-table">
        <tr>
          <th>Time</th>
          <th class="hid-590">Device</th>
          <th>IP</th>
          <th>Status</th>
        </tr>

        <tr v-for="(item , index) in loginhistory" :key="index">
          <td>{{ item.loginRequestedDateTime }}</td>
          <td class="hid-590">{{ item.deviceType }}</td>
          <td>{{ item.ipAddress }}</td>
          <td :class="{'red':item.isSuccessful === false , 'green':item.isSuccessful === true}">{{ returnStatue(item.isSuccessful) }}</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "the-panel-login-history",
  components: {},
  data() {
    return {
      loginhistory: []
    }
  },

  methods: {

    returnStatue(i){
      if(i === true){
        return 'Success'
      }
      else if (i === false){
        return 'Failed'
      }
    },

    async getLoginHistory() {
      const res = await this.$axios.get('/users/login-history', {
        params: {
          size: 10
        }
      })
      this.loginhistory = res.content;
    },
  },

  mounted() {
    this.getLoginHistory()
  }
}

</script>

<style lang="scss" scoped>

.red {
  color: rgba(204, 45, 106, 1);
}

.green {
  color: rgba(74, 163, 113, 1);
}

.login-history {
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 40px;

  .public-table {
    table-layout: auto;
  }

  .public-table tr th:last-child {
    text-align: right;
  }

  .public-table td {
    font-size: clamp(0.6rem, 2.5vw, 0.8rem);
  }

  .public-table tr td:first-child {
    text-align: left;
    padding-left: 5px;
  }

  .public-table tr td:last-child {
    text-align: right;
    padding-right: 5px;
  }
}

@media (max-width: 590px) {
  .hid-590 {
    display: none;
  }
}

@media (max-width: 490px) {
  .hid-490 {
    display: none;
  }
}
</style>

<template>
  <the-modal>
    <router-view/>
  </the-modal>
</template>

<script>
import TheModal from "./the-modal";

export default {
  name: "the-authentication",
  components: {TheModal}
}
</script>

<style scoped>

</style>
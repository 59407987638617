<template>
  <div class="main-fn-de">
    <the-exchange-modal
        v-if="showmodal === true"
        @close="showmodal = false"
    />


    <the-otp-withdraw @giveVerifyCode="withdraw" @close="showOtpModal = false" v-if="showOtpModal === true"/>

    <!--    this is a top banner -->
    <div class="baner">
      <img src="../../../../assets/panel/Banner-wid.png" alt="">
    </div>

    <div class="coins">
      <div class="left">
        <div class="header">
          <h2 class="title">Coin Withdraw</h2>
        </div>

        <div class="main-rows">
          <!--    this is a Select Coin section -->
          <div class="row-1">

            <div class="row1-left">
              <span class="SelectCoin">Select Coin</span>
            </div>

            <div class="row1-right">
              <div @click="showmodal = true" class="selectcoin">
                <img v-if="arr.coin !== 'TOMAN'" class="logo"
                     :src="require('../../../../assets/coinsName/'+ arr.coin +'.png')" alt="">

                <img class="arrow" src="../../../../assets/public/arrow-bottom.svg" alt="">
                <span class="selectedcoin">{{ arr.coin }}</span>
                <span class="top-span">Coin</span>
              </div>

              <the-input @updateValue="inputgetAmount" value="Amount"/>

              <div class="balance">
                <span class="text-balance">Balance :</span>
                <span v-if="arr.wholePriceOfCredit !== null"
                      class="value-balance">{{ arr.wholePriceOfCredit }}{{ arr.logo }}</span>
                <span v-if="arr.wholePriceOfCredit === null" class="value-balance">0 {{ arr.logo }}</span>
              </div>


            </div>
          </div>

          <!--    this is a Withdraw to section -->
          <div class="row-1">
            <div class="row1-left">
              <span>Withdraw to</span>
            </div>
            <div class="row1-right">

              <div @click="select = !select" v-if="arr.coin === 'TETHER'" class="selectcoin">
                <img v-if="arr.coin !== 'TOMAN'" class="logo" :src="require('../../../../assets/coinsName/'+arr.coin +'.png')" alt="">
                <img class="arrow" src="../../../../assets/public/arrow-bottom.svg" alt="">
                <span class="selectedcoin">{{ selectedTeter }}</span>
                <div v-if="select === true" class="select">
                  <span @click="selectedTeter ='ERC20'">ERC20</span>
                  <span @click="selectedTeter ='TRC20'">TRC20</span>
                </div>
                <span class="top-span">Network</span>
              </div>

              <div v-else @click="showmodal = true" class="disableselectcoin">
                <span class="selectedNetworkcoin">{{ arr.Network }}  </span>
                <span class="top-span">Network</span>
              </div>


              <the-input @updateValue="inputgetWalletAddress" value="Wallet Address"/>

              <div class="balance">
                <span class="text-balance">Network  fee</span>
                <span class="value-balance1">Minimum withdrawal</span>
              </div>
              <div class="balance">
                <span  class="text-balance2">{{ arr.transferFee }}</span>
                <span  class="text-balance2">{{ arr.maxWithdrawAmount }}</span>
              </div>

              <div class="danger">
                <p class="text-danger">
                  Please confirm that your withdrawal address matches the type of your chosen mainnet, in case of
                  unnecessary asset loss;
                </p>
                <p class="text-danger">
                  After withdrawal audition, your asset will be sent out as soon as possible. The actual arrival time is
                  determined by the number of confirmations of the recipient.
                </p>
              </div>

              <button class="gold-btn" @click="submit">Withdraw</button>
            </div>
          </div>
        </div>
      </div>

      <div class="right">

        <div class="header">
          <h2 class="title">FAQ</h2>
        </div>

        <div class="faq-links">
          <a @click="$router.push({name:'FAQ'})" class="links" v-for="(item , index) in links" :key="index">
            <img src="../../../../assets/panel/iconlinks.png" alt="">
            {{ item }}
          </a>
        </div>
      </div>

    </div>


  </div>
</template>

<script>
import TheInput from "../../../tools/the-input";
import TheExchangeModal from "../../../tools/the-exchange-modal";
import TheOtpWithdraw from "./the-otp-withdraw";

export default {
  name: "the-fn-wi",
  components: {TheOtpWithdraw, TheExchangeModal, TheInput},
  data() {
    return {
      links: [
        'How to withdraw crypto, step by step guide',
        'How to withdraw crypto, step by step guide',
        'How to withdraw crypto, step by step guide',
        'How to withdraw crypto, step by step guide',
        'How to withdraw crypto, step by step guide',
      ],
      wallets: [],
      showmodal: false,
      showNetworkmodal: false,
      arr: [],
      datamodal: [],
      selectcoindepositenable: true,
      select: false,
      selectedTeter: 'ERC20',
      showOtpModal: false,

      transaction: {
        amount: 0,
        code: '',
        destinationWalletAddress: '',
        relatedCoin:'',
        tokenType:'',
      }

    }
  },

  methods: {
    inputgetAmount(value) {
      this.transaction.amount = value
    },

    inputgetWalletAddress(value) {
      this.transaction.destinationWalletAddress = value
    },

    getData() {
      let data = this.state.getselectedcoin;
      this.arr = data;
    },

    async getWallets() {
      this.state.loading = true
      const [res,] = await this.$http.get('/wallets/customer-addresses')
      if (res) {
        let entireRes = Object.entries(res)
        const sortCoins = this.$walletSortCoins
        entireRes.sort((a, b) => sortCoins.indexOf(a[0]) - sortCoins.indexOf(b[0]))
        this.wallets = entireRes;
      }
      this.state.loading = false
    },

    async submit() {
      this.showOtpModal = true;
      await this.$axios.post("/users/send-code")
    },

    async withdraw(value) {
      this.transaction.code = value;
      this.transaction.relatedCoin = this.arr.coin;
      if (this.arr.coin !== 'TETHER') {
        this.transaction.tokenType = null;
        this.transaction.relatedCoin = this.arr.coin
      } else if (this.arr.coin === 'TETHER') {
        this.transaction.tokenType = this.selectedTeter;
      }

      const [res,] = await this.$http.post('/wallets/withdrawal-request', this.transaction);
      console.log(res)
      if (res) {
        this.$error('Your request has been successfully submitted', '', 'success')
      }
    }
  },

  async mounted() {
    await this.getData();
    await this.getWallets()
  }

}
</script>

<style lang="scss" scoped>
.disable {
  border: 2px solid #eae3e3 !important;
  cursor: not-allowed !important;
  pointer-events: none;
}

.selectedNetworkcoin {
  position: absolute;
  left: 10px;
  top: 30%;
  color: #484D56;
  font-size: 14px;
}

.enable {
  border: 2px solid #BDBDBD !important;
  cursor: pointer !important;
  pointer-events: auto !important;
}

.main-fn-de {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 16px;

  .baner {
    flex-grow: 1;

    img {
      width: 100%;
    }
  }

  .title {
    color: rgba(72, 77, 86, 1);
  }

  .coins {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    flex-wrap: wrap;
    column-gap: 180px;

    .left {
      flex-grow: 2;
      display: flex;
      justify-content: start;
      flex-direction: column;
      flex-basis: 100px;

      .main-rows {
        display: flex;
        flex-direction: column;
        gap: 30px;

        .row-1 {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          justify-content: space-between;
          gap: 10px;

          .row1-left {
            flex-grow: 1;

            //.SelectCoin{
            //  margin-top:16px;
            //}

            span {
              color: rgba(72, 77, 86, 1);
              font-size: 14px;
            }
          }

          .row1-right {
            flex-grow: 2;
            display: flex;
            gap: 16px;
            flex-direction: column;
            flex-basis: 100px;

            .selectcoin {
              border: 2px solid #BDBDBD;
              box-sizing: border-box;
              border-radius: 4px;
              position: relative;
              width: 100%;
              height: 44px;
              align-items: center;
              cursor: pointer;


              .select {
                display: flex;
                flex-direction: column;
                gap: 20px;
                position: absolute;
                top: 102%;
                background-color: white;
                width: 100%;
                z-index: 10;
                border: 2px solid lightgray;
                padding: 20px 0;

                span {
                  padding: 10px 0 10px 10px;

                  &:hover {
                    background-color: #a8a8a8;
                  }
                }
              }


              .top-span {
                position: absolute;
                top: -10px;
                left: 3%;
                background-color: #FFFFFF;
                color: rgba(130, 130, 130, 1);
                font-size: 12px;
                padding: 0 2px;
              }

              .logo {
                position: absolute;
                left: 3%;
                top: 20%;
                width: 24px;
                height: 24px;
              }

              .arrow {
                position: absolute;
                right: 10px;
                top: 50%;
              }

              .selectedcoin {
                position: absolute;
                left: 50px;
                top: 30%;
                color: rgba(72, 77, 86, 1);
                font-size: 14px;
              }

              .selectedcoin2 {
                position: absolute;
                left: 3%;
                top: 30%;
                color: rgba(130, 130, 130, 1);
                font-size: 14px;
              }

              .nickname {
                color: rgba(189, 189, 189, 1);
                position: absolute;
                left: 15%;
                top: 30%;
                font-size: 14px;
              }

            }

            .disableselectcoin {
              border: 2px solid #dbd7d7;
              box-sizing: border-box;
              border-radius: 4px;
              position: relative;
              width: 100%;
              height: 44px;
              align-items: center;
              cursor: not-allowed;
              pointer-events: none;

              .top-span {
                position: absolute;
                top: -10px;
                left: 3%;
                background-color: #FFFFFF;
                color: rgba(130, 130, 130, 1);
                font-size: 12px;
                padding: 0 2px;
              }

              .logo {
                position: absolute;
                left: 3%;
                top: 20%;
                width: 24px;
                height: 24px;
              }

              .arrow {
                position: absolute;
                right: 10px;
                top: 50%;
              }

              .selectedcoin {
                position: absolute;
                left: 50px;
                top: 30%;
                color: rgba(72, 77, 86, 1);
                font-size: 14px;
              }

              .deselectedcoin {
                position: absolute;
                left: 50px;
                top: 30%;
                color: #C4C4C4FF;
                font-size: 14px;
              }

              .selectedcoin2 {
                position: absolute;
                left: 3%;
                top: 30%;
                color: rgba(130, 130, 130, 1);
                font-size: 14px;
              }


              .nickname {
                color: rgba(189, 189, 189, 1);
                position: absolute;
                left: 15%;
                top: 30%;
                font-size: 14px;
              }

            }

            .balance {
              display: flex;
              justify-content: space-between;

              .text-balance {
                font-size: 12px;
                color: rgba(189, 189, 189, 1);
              }

              .value-balance {
                font-size: 12px;
                color: rgba(189, 189, 189, 1);
                text-decoration: underline;
              }

              .value-balance1 {
                font-size: 12px;
                color: rgba(189, 189, 189, 1);
              }

              .text-balance2 {
                font-size: 14px;
                color: rgba(102, 102, 102, 1);
              }

              .value-balance2 {
                font-size: 14px;
                color: rgba(102, 102, 102, 1);
              }
            }

            .danger {
              display: flex;
              flex-direction: column;
              position: relative;
              align-items: end;
              width: 100%;

              .text-danger {
                color: rgba(204, 45, 106, 1);
                font-size: 12px;
                position: relative;
                width: 90%;

                &:before {
                  content: "!";
                  background-color: #cc2d6a;
                  border-radius: 50%;
                  width: 20px;
                  height: 20px;
                  margin-left: 5px;
                  display: flex;
                  justify-content: center;
                  color: #FFFFFF;
                  position: absolute;
                  left: -30px;
                }
              }
            }

            .gold-btn {
              color: #FFFFFF;
            }
          }
        }
      }
    }


    .right {
      flex-grow: 1;
      display: flex;
      flex-basis: 100px;
      flex-direction: column;
      align-items: baseline;
      gap: 30px;

      .header {
        display: flex;
        width: 100%;

        .title {
          text-align: left;
          width: 100%;
        }
      }

      .faq-links {
        display: flex;
        flex-direction: column;
        gap: 18px;

        img {
          width: 15px;
          height: 18px;
        }

        .links {
          color: rgba(130, 130, 130, 1);
          font-size: 14px;
          width: 100%;
          text-decoration: underline;
          display: flex;
          align-items: center;
          gap: 5px;
          cursor: pointer;
        }
      }
    }

  }
}

@media (max-width: 1200px) {
  .nickname {
    left: 25% !important;
  }
}
</style>
<template>
  <div class="dropdown">
    <div class="title">
      <p v-if="data.question">{{data.question}}</p>
      <p v-if="data.title">{{data.title}}</p>
      <!--      this buttons sections -->
      <img class="pulse" v-if="show === false" @click="show = true" src="../../assets/public/pluse.png" alt="">
      <img class="minez" v-if="show === true" @click="show = false" src="../../assets/public/minez.png" alt="">
    </div>
    <!--    this a caption section -->
    <div v-if="show" class="caption">
      <p v-if="data.answer">{{data.answer}}</p>
      <p v-if="data.html">{{data.html}}</p>
    </div>
  </div>
</template>

<script>
export default {

  name: "the-dropdown",
  props: ["data"],

  data() {
    return {
      show: false
    }
  }
}
</script>

<style lang="scss" scoped>
//general class
.pulse {
  width: 12px;
  height: 12px;
  cursor: pointer;
}

.minez {
  width: 10px;
  height: 3px;
  cursor: pointer;
}

//dropdown styles
.dropdown {
  border-top: 1px solid #adb2bb;
  height: auto;
  display: flex;
  flex-direction: column;
  width: 100%;

  &:first-child{
    border-top: 0 !important;
  }

  .title {
    display: flex;
    justify-content: space-between;
    color: #484D56;
    font-size: medium;
    align-items: center;
  }

  .caption {
    display: flex;
    color: #828282;
    font-size: small;
  }
}
</style>
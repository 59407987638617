<template>
  <div class="box-dashboard">
    <div class="header">
      <span class="title">Login History</span>
    </div>
    <div v-for="(item , index) in loginhistory" :key="index" class="login">
      <div class="row-1">
        <span>{{ item.deviceType }}</span>
        <span>{{ item.ipAddress }}</span>
      </div>
      <div class="row-2">
        <span>{{item.location}}</span>
        <span>{{ item.loginRequestedDateTime }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "the-login-history",
  data() {
    return {
      loginhistory: []
    }
  },

  methods:{
    async getLoginHistory() {
      const res = await this.$axios.get('/users/login-history', {
        params: {
          size: 10
        }
      })
      this.loginhistory = res.content;
    },
  },

  mounted() {
    this.getLoginHistory()
  }
}
</script>

<style lang="scss" scoped>
.box-dashboard {
  display: flex;
  flex-grow: 1;
  flex-basis: 100px;
  flex-direction: column;
  padding: 10px;
  gap: 10px;
  max-height: 293px;
  overflow: auto;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

  .header {
    width: 100%;
    margin-bottom: 10px;

    .title {
      color: rgba(102, 102, 102, 1);
      font-size: clamp(1rem, 2.5vw, 1.5rem);
    }
  }

  .login {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #F2F2F2;
    gap: 10px;

    .row-1 {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      color: rgba(72, 77, 86, 1);
      font-size: clamp(0.9rem, 2.5vw, 0.7rem);
      flex-wrap: wrap;
      gap: 10px;
    }

    .row-2 {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      color: rgba(130, 130, 130, 1);
      font-size: clamp(0.8rem, 2.5vw, 1rem);
      margin-bottom: 5px;
      flex-wrap: wrap;
      gap: 10px;
    }
  }
}
</style>
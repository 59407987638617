<template>
  <div class="main-assets">
    <div>
      <h2>Balance</h2>
      <the-balance/>
    </div>

    <div>
      <h2>Assets</h2>
      <the-child-assets/>
    </div>
  </div>
</template>

<script>
import TheBalance from "../components/the-balance";
import TheChildAssets from "../components/the-child-assets";

export default {
  name: "the-main-assets",
  components: {TheChildAssets, TheBalance},
  data(){
    return{

    }
  },

}
</script>

<style lang="scss" scoped>
.main-assets {
  display: flex;
  flex-direction: column;
  column-gap: 40px;
  padding: 16px;

  h2 {
    color: rgba(72, 77, 86, 1);
  }
}

.assets-box {
  background: #FFFFFF;
  box-shadow: 0 0 4px rgba(70, 19, 163, 0.15);
  border-radius: 12px;
  padding: 16px;
}
</style>
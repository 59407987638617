<template>
  <div class="flex-container">

    <!----------------------------- start row-1 --------------------------->
    <div class="row-1">

      <!----------------------------- start col-1 --------------------------->
      <div class="col-1">
        <the-progresive-bar :key="$route.params.coin + 'key1' "/>
      </div>

      <!----------------------------- start col-2 --------------------------->
      <div class="col-2">
        <!---------------------------the select coin comp---------------------->
        <the-selected-coin :key="$route.params.coin + 'key2' "/>
        <!---------------------------the trading vue comp---------------------->
        <div class="item  trading-div">
          <vue-trading-view
              :key="$route.params.coin"
              :options="{
          theme : 'light',
          height: '380',
          width : '100%',
          symbol :'BINANCE:' +this.$coin3Snake[$route.params.coin]+ 'USDT',
          enabled_features: ['header_fullscreen_button'],
        }"/>
        </div>
        <!---------------------------the selected coin comp---------------------->
        <the-change-coin :key="$route.params.coin + 'key3' "/>
      </div>
      <!----------------------------- start col-3 --------------------------->


      <div class="col-3">
        <!----------------------------- top table comp --------------------------->
        <top-table/>

        <!----------------------------- bottom table comp --------------------------->
        <bottom-table :key="$route.params.coin + 'key4' "/>

      </div>
      <!----------------------------- End of Row-1 --------------------------->
    </div>


    <!----------------------------- start row-2 --------------------------->
    <div class="row-2">
      <info-table/>
    </div>
    <!----------------------------- End of row-2 --------------------------->


    <!----------------------------- End of main --------------------------->

  </div>
</template>

<script>
import VueTradingView from 'vue-trading-view';
import TheProgresiveBar from "./component/row-1/col-1/the-progresive-bar";
import TheSelectedCoin from "./component/row-1/col-2/the-selected-coin";
import TheChangeCoin from "./component/row-1/col-2/the-change-coin";
import TopTable from "./component/row-1/col-3/top-table";
import BottomTable from "./component/row-1/col-3/bottom-table";
import InfoTable from "./component/row-2/info-table";

export default {
  name: "the-trade",
  components: {InfoTable, BottomTable, TopTable, TheChangeCoin, TheSelectedCoin, TheProgresiveBar, VueTradingView},
  data() {
    return {
      width: 0,
      type: ""
    }
  },

  methods: {
    marketType() {
      this.type = this.$route.params.coin + '_TETHER';
    },
  },

  mounted() {
    this.width = screen.width;
    this.marketType()
  },
}
</script>

<style lang="scss" scoped>

//general style

.flex-grow-1 {
  flex-grow: 1;
}

.flex-container {
  display: flex;
  flex-direction: column;
  background-color: #dedede;
  gap: 10px;
  padding-top: 5px;
  flex-wrap: wrap;

  .item {
    display: flex;
    flex-direction: column;
    border: 1px solid #FFFFFF;
    border-radius: 4px;
    width: 100%;
    background-color: #FFFFFF;
  }

  .row-1 {
    display: flex;
    flex-direction: row;
    gap: 10px;
    flex-wrap: wrap;

    .col-1 {
      display: flex;
      flex-direction: column;
      border: 1px solid #FFFFFF;
      border-radius: 4px;
      flex-grow: 1;
      background-color: #FFFFFF;
      flex-basis: 100px;
    }

    .col-2 {
      display: flex;
      flex-direction: column;
      gap: 10px;
      border-radius: 4px;
      flex-grow: 3;
      flex-basis: 100px;
    }

    .col-3 {
      display: flex;
      flex-direction: column;
      gap: 10px;
      border-radius: 4px;
      flex-grow: 1;
      flex-basis: 100px;
    }
  }

  .row-2 {
    display: flex;
    flex-direction: column;
    gap: 10px;
    border: 1px solid #FFFFFF;
    border-radius: 4px;
    background-color: #FFFFFF;
  }
}

.trading-div {
  height: 377px !important;
}

@media (max-width: 798px) {
  .col-3 {
    flex-basis: content !important;
  }

  .col-2{
    flex-basis: 500px !important;
  }
}


</style>

<template>
  <div class="otp">
    <div class="header-auth">
      <p>Security verification </p>
    </div>

    <p class="text">
      Enter the 5-digit code sent to your number. The code is valid for
      {{ countDown }}s.
    </p>

    <div class="cod-input">
      <CodeInput :loading="loading" class="input"
                 @change="onChange" @complete="submit"
                 :fields="num" ref="code-input" :fieldWidth="45" :fieldHeight="45"/>
    </div>

    <div class="footer">
      <a v-if="countDown == 0" @click.prevent="send" style="cursor: pointer" class="countdown">resend</a>
    </div>
    <button type="submit" class="gold-btn" :disabled="otp.code.length<5 || loading" style="margin-bottom:20px">Send
    </button>

  </div>
</template>

<script>
import CodeInput from "vue-verification-code-input";
import {reset, setPlaceholder, paste} from "@/lib/reuseableFunction";

export default {
  name: "FinoVerifying",
  components: {CodeInput},
  data() {
    return {
      email: '',
      password: '',
      password2: '',
      type1: 'password',
      type2: 'password',
      checked: '',
      countDown: 120,
      smscode: [],
      error: '',
      loading: false,
      num: 5,
      otp: {
        code: ''
      },
      timeOut: '',
    }
  },
  methods: {
    countDownTimer() {
      if (this.countDown > 0) {
        this.timeOut = setTimeout(() => {
          this.countDown--
          this.countDownTimer()
        }, 1000)
      } else {
        this.$error('زمان شما به اتمام رسید', '')
      }
    },
    async send() {
      this.reset()
      this.state.loading = true
      const res = await this.$axios.get(`/finotech/send-sms-to-customer?email=${this.state.userInfo.email}`)
      if (res.message === 'Sms sent to customer.') {
        if (this.countDown === 0) {
          this.countDown = 120
          this.countDownTimer()
        }
      }
    },
    onChange(e) {
      this.otp.code = e
    },
    async submit() {
      if (this.otp.code.length === 5 || this.loading) {
        this.loading = true
        let [res,] = await this.$http.get('/finotech/otp', {
          params: {
            otp: this.otp.code,
            email: this.state.userInfo.email
          }
        })
        this.loading = false
        if (res) {
          await this.$error('', 'فرایند احراز هویت انجام شد. منتظر تایید اطلاعات توسط پشتیبانی باشید.', 'success')
          this.$router.push({name: 'userAccount'})
        }
      }
    },
    setPlaceholder,
    paste,
    reset,
  },
  created() {
    this.countDownTimer()
  },
  beforeDestroy() {
    clearTimeout(this.timeOut)
  },
  mounted() {
    this.setPlaceholder()
  }
}
</script>


<style lang="scss" scoped>
.countdown {
  font-size: 14px;
  text-decoration: underline;
  color: rgba(72, 77, 86, 1);
}

.otp {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .text {
    color: rgba(72, 77, 86, 1);
    font-size: 14px;
    margin: 10px 40px;
  }

  .resend {
    color: rgba(241, 207, 82, 1);
    opacity: 0.7;
    text-align: center;
    margin-top: 30px;
  }

  .gold-btn {
    color: #FFFFFF;
    margin: 20px 0;
  }
}

.cod-input {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  width: 90%;
}

.react-code-input-container {
  display: flex !important;
  justify-content: space-between !important;
}

.react-code-input {
  width: 100%;
  display: flex !important;
  justify-content: space-between !important;
}

.react-code-input > input {
  border-radius: 5px !important;
  margin: 0 3px;
  border-right: 1px solid #a8adb7 !important;
  width: 40px !important;
  height: 40px !important;
}

.disable {
  opacity: 0.7;
  cursor: not-allowed;
}
</style>
<template>
  <div class="header-dashboard">
    <the-send-code-verify-phone @showGetcodeModal="showGetcodeModal" @close="mobileverify = false"
                                v-if="mobileverify === true"/>
    <the-get-code-verify v-if="getcode === true" @close="getcode = false ; isverify = true "/>
    <div class="liner">
      <div class="title">

        <div class="left-title">
          <h1>Welcome to Binance</h1>
          <p class="text-title">Just a few morer steps and you’re good to go!</p>

          <div class="verify-account">

            <div class="verify-box">
              <div class="done-prog"></div>
              <div class="verify-text">
                <p class="title">Register Account</p>
              </div>
              <div class="border"></div>
            </div>

            <div class="verify-box">
              <div v-if="mobileverifylog === false" class="prog-2"></div>
              <div v-if="mobileverifylog === true" class="done-prog"></div>
              <div class="verify-text">
                <div class="title-text">
                  <p class="title">Mobile Verification</p>
                </div>
                <div class="ve-text">
                  <p class="text">
                    Verify your phone number to proceed further
                  </p>
                  <button @click="mobileverify = true ; facicalverifylog = true" class="gold-btn">Verify</button>
                </div>
              </div>
              <div class="border"></div>
            </div>

            <div class="verify-box">
              <div v-if="facicalverifylog === false" class="prog-3"></div>
              <div v-if="facicalverifylog === true" class="done-prog"></div>
              <div class="verify-text">
                <div class="title-text">
                  <p class="title"> Facial Verification</p>
                </div>

                <div class="ve-text">
                  <p class="text">
                    Verify your ID & address and you’re all good to go!
                  </p>
                  <button @click="facicalVerify"
                          :class="{'disable-gold-btn' : isverify === false}" :disabled="isverify === false"
                          class="gold-btn">
                    <a class="link">Verify</a>
                  </button>
                </div>
              </div>
            </div>

          </div>

        </div>

        <div class="right-title">
          <div class="current-level">
            <p> Your Current Level</p>
            <div class="number">
              <span class="span-ciricle"></span>
              <span class="span-number">{{ NumericalLevel }}</span>
            </div>
          </div>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
import TheSendCodeVerifyPhone from "./the-send-code-verify-phone";
import TheGetCodeVerify from "./the-get-code-verify";

export default {
  name: "the-header-dashboard",
  components: {TheGetCodeVerify, TheSendCodeVerifyPhone},
  data() {
    return {
      isverify: false,
      mobileverify: false,
      getcode: false,
      facicalverify: false,
      mobileverifylog: false,
      facicalverifylog: false,
      NumericalLevel: 0,
      userAccount: [],
      email:this.state.userInfo.email
    }
  },

  methods: {
    showGetcodeModal() {
      this.mobileverify = false;
      this.getcode = true;
      this.mobileverifylog = true
    },

    facicalVerify() {
      if (this.facicalverifylog === false) {
        this.$router.push('/panel/dashboard')
      } else {
        this.$router.push('profile/#faciallverification')
      }
    },

    //mobileVerify
    async sendMobileNumber(){
      const res = await this.$axios.post('users/mobile-verification', this.email );
    },

    //get user account level
    // BRONZE
    // SILVER
    // GOLD
    // PLATINUM
    // SUPERVISOR
    async getLevelUser() {
      const res = await this.$axios('users/account');
      this.userAccount = res.baseDTO;
      if (this.userAccount.userAccountLevel === 'BRONZE') {
        this.NumericalLevel = 0
      } else if (this.userAccount.userAccountLevel === 'SILVER') {
        this.NumericalLevel = 1
      } else if (this.userAccount.userAccountLevel === 'GOLD') {
        this.NumericalLevel = 2
      } else if (this.userAccount.userAccountLevel === 'PLATINUM') {
        this.NumericalLevel = 3
      } else if (this.userAccount.userAccountLevel === 'SUPERVISOR') {
        this.NumericalLevel = 4
      }
    },
  },

  mounted() {
    this.getLevelUser()
  }
}
</script>

<style lang="scss" scoped>
.header-dashboard {
  background-image: url("../../../../../assets/panel/header-dashboard-bg.png");
  display: flex;
  flex-direction: column;
  background-size: cover;
  background-position: center;
  gap: 20px;
  border-radius: 12px;
  overflow: hidden;

  .link {
    text-decoration: none;
    color: rgba(72, 77, 86, 1);
  }

  .liner {
    display: flex;
    padding: 0 20px;
    flex-direction: column;
    gap: 50px;
    background: linear-gradient(180deg, #4613A3 -1.89%, rgba(70, 19, 163, 0.5) 46.5%, #4613A3 100%);

    .title {
      display: flex;
      flex-direction: row;

      .left-title {
        flex-grow: 2;
        display: flex;
        flex-direction: column;
        justify-content: start;

        h1, .text-title {
          color: rgba(242, 242, 242, 1);
        }

        .text-title {
          font-size: clamp(1rem, 2.5vw, 1.5rem);
        }

        .verify-account {
          display: flex;
          flex-direction: row;
          margin: 20px 0;
          width: 80%;

          .verify-box {
            display: flex;
            flex-direction: column;
            gap: 10px;
            flex-grow: 1;
            position: relative;
            flex-basis: 100px;

            .border {
              border-bottom: 2px #C2A748 dashed;
              position: absolute;
              width: 100%;
              top: 0;
            }

            .verify-text {
              display: flex;
              flex-direction: column;
              height: 100%;
              justify-content: space-between;

              .title {
                color: rgba(255, 255, 255, 1);
                font-size: clamp(0.9rem, 2.5vw, 1.2rem);
              }

              .text {
                color: rgba(189, 189, 189, 1);
                font-size: clamp(0.8rem, 2.5vw, 1rem);
              }

              .gold-btn {
                color: rgba(72, 77, 86, 1);
                width: 40%;
              }

              .disable-gold-btn {
                @extend .gold-btn;
                opacity: 0.7;
                cursor: not-allowed;
              }

            }

            .prog {
              display: flex;
              flex-direction: row;
              align-items: center;
              position: relative;
              z-index: 2;

              &:before {
                content: "";
                width: 20px;
                height: 20px;
                border-radius: 50%;
                position: absolute;
                background-color: #FFFFFF;
              }
            }

            .done-prog {
              @extend .prog;

              &:before {
                background-image: url("../../../../../assets/panel/Done.png");
                background-size: cover;
                background-position: center;
                background-color: transparent !important;
              }
            }

            .prog-2 {
              @extend .prog;

              &:before {
                content: "2";
                color: rgba(72, 77, 86, 1);
                text-align: center;
                font-weight: bold;
              }
            }

            .prog-3 {
              @extend .prog;

              &:before {
                content: "3";
                color: rgba(72, 77, 86, 1);
                text-align: center;
                font-weight: bold;
              }
            }
          }
        }
      }

      .right-title {
        flex-grow: 1;
        display: flex;
        flex-direction: row;
        justify-content: end;

        .current-level {
          display: flex;
          background-color: rgba(242, 242, 242, 0.7);
          padding: 0 20px;
          border-radius: 8px;
          color: rgba(242, 242, 242, 1);
          align-items: center;
          justify-content: center;
          height: fit-content;
          width: fit-content;
          margin-top: 20px;

          p {
            font-size: clamp(0.7rem, 2.5vw, 1rem);
          }

          .number {
            position: relative;

            .span-ciricle:after {
              content: "";
              background-color: rgba(242, 242, 242, 1);
              border-radius: 50%;
              width: 20px;
              height: 20px;
              position: absolute;
              margin-left: 5px;
            }

            .span-number {
              color: rgba(72, 77, 86, 1);
              position: relative;
              left: 50%;
              margin-left: 3px;
              font-weight: bold;
            }
          }
        }
      }
    }
  }

  //  media query
  @media (max-width: 678px) {
    .right-title {
      display: none !important;
    }

    .verify-account {
      .gold-btn {
        width: 80% !important;
      }

      .disable-gold-btn {
        width: 80% !important;
      }
    }

    .verify-account {
      width: 100% !important;
    }
  }

  @media (max-width: 992px) {
    .current-level {
      display: none !important;
    }
  }
}
</style>
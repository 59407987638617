<template>
  <div class="box-input">
    <div class="row-1">
      <span class="text-1">{{ title }}</span>
    </div>

    <div class="row-2">
      <span class="value">{{ data[1].credit }}</span>
      <div @click="openExchange" class="btn">
        <img class="coin" :src="require('../../assets/coinsName/'+data[1].relatedCoin + '.png')" alt="">
        <span>{{ this.$coinUnit[data[0]] }}</span>
        <img class="arrow" src="../../assets/Exchange/arrow.png" alt="">
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "the-exchange-input",
  props: ["title", "data"],

  methods: {
    openExchange() {
      this.$emit('showExchangeModal' , 'value');
    }
  }
}
</script>

<style lang="scss" scoped>
.box-input {
  width: 100%;
  background-color: rgba(242, 242, 242, 1);
  display: flex;
  flex-direction: column;
  gap: 24px;
  border-radius: 8px;
  padding: 12px;

  .row-1 {
    display: flex;
    justify-content: start;

    .text-1 {
      color: rgba(72, 77, 86, 1);
      font-size: 14px;
      font-weight: 400;
    }
  }

  .row-2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 100px;

    .value {
      color: rgba(72, 77, 86, 0.5);
      font-size: clamp(12px, 2vw, 18px);
      font-weight: 400;
    }

    .btn {
      width: auto;
      height: 36px;
      background-color: #FFFFFF;
      display: flex;
      flex-direction: row;
      gap: 8px;
      color: rgba(72, 77, 86, 1);
      font-size: 16px;
      align-items: center;
      padding: 8px;
      border-radius: 16px;
      justify-content: space-between;
      cursor: pointer;
      font-weight: 600;

      .coin {
        width: 24px;
        height: 24px
      }

      .arrow {
        width: 6px;
        height: 6px
      }
    }
  }

}

@media (max-width: 400px) {
  .row-2{
    column-gap: 0 !important;
  }
}
</style>
<template>
  <the-public-modal @close="$emit('close')">
    <div class="succes-modal">
      <img class="success" src="../../assets/public/Check.png" alt="">
      <p v-if="title1" class="title1">{{title1}}</p>
      <small v-if="title2" class="title2">{{title2}}</small>
      <div v-if="otp" class="otp">

        <div class="row1">
          <span>You Paid</span>
          <span>35.93 USDT</span>
        </div>
        <div class="row2">
          <span>You Recieved</span>
          <span>0.01022837 BTC</span>
        </div>

      </div>
      <button @click="$emit('close')" v-if="btnText" class="gold-btn">{{btnText}}</button>
    </div>
  </the-public-modal>
</template>

<script>
import ThePublicModal from "./the-public-modal";

export default {
  name: "the-succes-modal",
  components: {ThePublicModal},
  props:["title1" , "title2" , "btnText" , "otp"]
}
</script>

<style lang="scss" scoped>
.succes-modal{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .gold-btn{
    color: #FFFFFF;
    margin-top: 40px;
  }

  .otp{
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;


    .row1{
      display: flex;
      justify-content: space-between;
      color: rgba(72, 77, 86, 1);
      font-size: 14px;
    }

    .row2{
      display: flex;
      justify-content: space-between;
      color: rgba(72, 77, 86, 1);
      font-size: 14px;
    }
  }

  p{
    font-size: 22px;
  }
}

.success{
  margin-top: 40px;
}
</style>
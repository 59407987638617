<template>
  <div class="main-security">
    <the-reset-pass-modal v-if="resetpassmodal === true" @close="resetpassmodal = false" @success="succesmodal =true" />
    <!--    <the-otp-panel-modal v-if="otppanel === true" @close="otppanel = false" @success="succesmodal =true"/>-->
    <the-security-setting @resetpassmodal="resetpassmodal = true ; " @otppanel="otppanel = true" />
    <the-panel-login-history/>
    <the-succes-modal title1="Request Submitted" v-if="succesmodal === true" @close="succesmodal = false ;resetpassmodal= false ; otppanel = false "/>
  </div>
</template>

<script>
import TheSecuritySetting from "./component/the-security-setting";
import ThePanelLoginHistory from "./component/thep-panel-login-history";
import TheResetPassModal from "./component/the-reset-pass-modal";
import TheSuccesModal from "../../tools/the-succes-modal";

export default {
  name: "the-security",
  components: {
    TheSuccesModal,  TheResetPassModal, ThePanelLoginHistory, TheSecuritySetting},
  data(){
    return{
      resetpassmodal:false,
      otppanel : false,
      succesmodal:false
    }
  },

}
</script>

<style lang="scss" scoped>
.main-security {
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 40px;
}
</style>
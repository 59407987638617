<template>
  <div class="main-market">
    <h1>Transaction History</h1>

    <div class="buttons">
      <button :class="{'active' : Crypto === true}" @click="gotoCrypto" class="btns">withdrawal</button>
      <button :class="{'active' : Fiat === true}" @click="gotoFiat" class="btns">Deposit</button>
    </div>
    <!--  public table-->
    <div class="table-div">
      <router-view/>
    </div>

  </div>
</template>

<script>

export default {
  name: "the-transactionhistory",

  data() {
    return {
      Crypto: true,
      Fiat: false,

      //header data
      headerData: [
        {name: "Date", sort: true},
        {name: "Coin", sort: true, class: "hide"},
        {name: "Type", sort: true, class: "hide"},
        {name: "Amount", sort: false, class: "hide"},
        {name: "Destination", sort: false},
        {name: "Network", sort: false},
        {name: "TxID", sort: false, class: "hide"},
        {name: "Status", sort: false},
      ],

      //data table
      data: [
        {
          Date: "2021-01-09 08:30:59",
          Coin: "BTC/USDT",
          Type: "Limit/Buy",
          Amount: 0.07235,
          Destination: "1N4pWYTL9fx9ZGQs8WEJJ2ioHqP86PPJfC",
          TxID: 'More...',
          Network: "ERC20",
          Status: "Canceelled",
          class: 'hide',
        },
        {
          Date: "2021-01-09 08:30:59",
          Coin: "BTC/USDT",
          Type: "Limit/Buy",
          Amount: 0.07235,
          Destination: "1N4pWYTL9fx9ZGQs8WEJJ2ioHqP86PPJfC",
          TxID: 'More...',
          Network: "ERC20",
          Status: "Canceelled",
          class: 'hide',
        },
        {
          Date: "2021-01-09 08:30:59",
          Coin: "BTC/USDT",
          Type: "Limit/Buy",
          Amount: 0.07235,
          Destination: "1N4pWYTL9fx9ZGQs8WEJJ2ioHqP86PPJfC",
          TxID: 'More...',
          Network: "ERC20",
          Status: "Canceelled",
          class: 'hide',
        },
        {
          Date: "2021-01-09 08:30:59",
          Coin: "BTC/USDT",
          Type: "Limit/Buy",
          Amount: 0.07235,
          Destination: "1N4pWYTL9fx9ZGQs8WEJJ2ioHqP86PPJfC",
          TxID: 'More...',
          Network: "ERC20",
          Status: "Canceelled",
          class: 'hide',
        },
        {
          Date: "2021-01-09 08:30:59",
          Coin: "BTC/USDT",
          Type: "Limit/Buy",
          Amount: 0.07235,
          Destination: "1N4pWYTL9fx9ZGQs8WEJJ2ioHqP86PPJfC",
          TxID: 'More...',
          Network: "ERC20",
          Status: "Canceelled",
          class: 'hide',
        },
        {
          Date: "2021-01-09 08:30:59",
          Coin: "BTC/USDT",
          Type: "Limit/Buy",
          Amount: 0.07235,
          Destination: "1N4pWYTL9fx9ZGQs8WEJJ2ioHqP86PPJfC",
          TxID: 'More...',
          Network: "ERC20",
          Status: "Canceelled",
          class: 'hide',
        },
        {
          Date: "2021-01-09 08:30:59",
          Coin: "BTC/USDT",
          Type: "Limit/Buy",
          Amount: 0.07235,
          Destination: "1N4pWYTL9fx9ZGQs8WEJJ2ioHqP86PPJfC",
          TxID: 'More...',
          Network: "ERC20",
          Status: "Canceelled",
          class: 'hide',
        },
        {
          Date: "2021-01-09 08:30:59",
          Coin: "BTC/USDT",
          Type: "Limit/Buy",
          Amount: 0.07235,
          Destination: "1N4pWYTL9fx9ZGQs8WEJJ2ioHqP86PPJfC",
          TxID: 'More...',
          Network: "ERC20",
          Status: "Canceelled",
          class: 'hide',
        },

      ]
    }
  },

  methods:{
    gotoCrypto(){
      this.Crypto = true ;
      this.Fiat = false ;
      this.$router.push({name:"TransactionCrypto"})
    },

    gotoFiat(){
      this.Fiat = true ;
      this.Crypto = false;
      this.$router.push({name:"TransactionFiat"})
    }
  },

  mounted() {
    this.gotoCrypto()
  }
}
</script>

<style lang="scss" scoped>
//general styles
.td-first {
  text-align: left !important;
}

.buttons {
  display: flex;
  flex-direction: row;
  margin: 38px 0;

  .btns {
    Width: 100px;
    Height: 34px;
    color: rgba(72, 77, 86, 1);
    border-radius: 4px;
    background-color: #FFFFFF;
    border: none;
    cursor: pointer;
  }
}


.active {
  @extend .btns;
  background-color: rgba(242, 242, 242, 1) !important;
}

.network {
  color: rgba(70, 19, 163, 1);
  cursor: pointer;
}

.Destination {
  overflow: hidden;
}

.table-div {
  display: flex;
  justify-content: left;
  align-items: center;
}

.th-table {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
}

.td-last {
  text-align: right !important;
}

.public-table {
  table-layout: fixed !important;
}

.right {
  justify-content: right !important;
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
}

.success {
  color: rgba(93, 204, 141, 1);
}

.danger {
  color: rgba(242, 53, 126, 1);
}

//main styles
.main-market {
  width: 100%;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  padding: 0 16px;

  h1 {

  }


  .hover-svg {
    fill: #BDBDBD !important;
    width: 8px;
    height: 8px;
    cursor: pointer;
  }

  .hover-svg:hover {
    fill: var(--main-background-color) !important;
  }


  th {
    .arrows {
      display: flex;
      flex-direction: column;
      gap: 1px;
    }

    .item {
      display: flex;
      flex-direction: row;
      gap: 5px;
      align-items: center;
      justify-content: center;
    }
  }

}

@media (max-width: 690px) {
  .hide {
    display: none !important;
  }
}
</style>

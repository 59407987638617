<template>
  <div>
    <the-loading v-if="state.loading === true"/>
    <the-navbar/>
    <router-view/>
    <the-footer v-if="$route.name !== 'Trade' " />
  </div>
</template>
<!---->
<script>
import TheNavbar from "@/components/shared/navbar/The-navbar";
import TheFooter from "@/components/shared/footer/The-footer";
import TheLoading from "./components/tools/the-loading";

export default {
  name: "App",
  components: {TheLoading, TheFooter, TheNavbar},

  data() {
    return {
      width: 0
    }
  },

  "mounted"() {
    this.width = screen.width
  },
}
</script>

<style scoped>

</style>
import Vue from 'vue'

Vue.use(require('vue-cookies'))

const that = Vue.prototype

const state = Vue.observable({

    otpcode: "",
    registerEmail: "",
    loginEmail: "",
    loginuser: false,
    MarketType: 'BITCOIN_CASH_TETHER',
    verify: false,
    mobileverify: false,
    stateDataResive: {},
    stateDataSpend: {},
    userAddcard: false,

    getselectedcoin: {
        transferFee:0,
        coin:'',
        walletAdreess:'',
        Network:'',
        logo:'',
        maxWithdrawAmount:'',
        wholePriceOfCredit:0
    },

    loading: false,
    gotten: false,
    loop: true,
    dark: true,
    progress: 0,

    token: localStorage.token || Vue.$cookies.get('--abcd14589') || false,
    time: 5000,
    orderChanged: 0,
    requestChanged: 0,
    messages: 0,

    userInfo: {
        firstName: '',
        lastName: '',
        email: '',
        remember: false,
        towStepAuthenticationType: '',
        authenticationType: '',
        authorized: '',
        pending: false,
        supervisor: false,
        userAccountLevel: '',
        totalTradesVolume: 0,
        fee: 0.4,
    },

    shopInfo: {
        minSell: 0,
        maxBuy: 0,
        lastPrice: 0,
    },

    buyAmount: 0,
    buyPrice: 0,
    sellAmount: 0,
    sellPrice: 0
})

that.state = state
export default state
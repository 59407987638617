<template>
  <div class="main-privecy">
    <!--  the custom header component -->
    <the-header value="Privacy Policy"/>
    <div class="main">
      <div class="top-div">
        <h2 class="title">1. Introduction</h2>
        <!--      data caption  -->
        <p>{{ datacaption }}</p>
      </div>
      <div class="bottom-div">
        <h2 class="title">2. What type of information we collect and our legal basis for its collection</h2>
        <!--      data caption  -->
        <p>{{ datacaption }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import TheHeader from "../tools/The-header";

export default {
  name: "the-privacypolicy",
  components: {TheHeader},
  data() {
    return {
      //data text
      datacaption: "Cmama Ltd. and/or parent company New Bit Ventures Ltd. (both companies, jointly and severally, shall be referred to as \"us\", \"we\" or \"Company\") respect the privacy of our users (each, \"you\" or \"User\") and are committed to protecting the privacy of Users who access, visit, use or engage with our website or any other online services we provide (collectively: the \"Services\"). The Company has prepared this Privacy Policy to outline its practices with respect to collecting, using and disclosing your information when you use the Services.\n" +
          "We encourage you to read the Privacy Policy carefully and use it to make informed decisions. By using the Services, you agree to the terms of this Privacy Policy and your continued use of the Services constitutes your ongoing agreement to the Privacy Policy.\n" +
          "The Privacy Policy is a part of the Terms of Service and is incorporated herein by reference.\n" +
          "In this Privacy Policy you will read about, among other things:\n" +
          "What type of information we collect and our legal basis for its collection\n" +
          "How we use the information\n" +
          "With whom we share the information and for what purpose\n" +
          "International transfer\n" +
          "Your privacy rights\n" +
          "For how long we retain the information\n" +
          "How we protect your information\n" +
          "Marketing\n" +
          "Minors\n" +
          "How to contact us\n" +
          "“You” and “Your” refers to you, the person accessing the Website and which by doing so, accepts this Privacy Policy. Any use of the above terminology or other words in the singular, plural, capitalization are taken as interchangeable and therefore as referring to the same.\n",
    }
  }
}
</script>

<style lang="scss" scoped>
//main styles
.main-privecy {
  display: flex;
  flex-direction: column;
  background-color: #E5E5E5;

  .main {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 20px auto;
    gap: 30px;

    .title {
      color: var(--main-background-color);
    }
  }
}
</style>
<template>
  <the-public-modal  @close="$emit('close')">
    <div class="resetpass">
      <div class="header-auth">
        <p>Reset Your Password </p>
      </div>
      <div class="main-input">
        <the-input @updateValue="getCurrrentPassword" value="Currrent Password" type="text" logo="logo"
                   :errorText="passError"/>
        <the-input @updateValue="getNewPassword" value="New Password" logo="logo" type="text" :errorText="pass1Error"/>
        <the-input @updateValue="getConfirmpassword" value="Confirm password" logo="logo" type="text"
                   :errorText="pass2Error"/>
      </div>
      <button class="gold-btn" @click="submit">Save Chnages</button>
    </div>
  </the-public-modal>
</template>

<script>
import TheInput from "../../../tools/the-input";
import ThePublicModal from "../../../tools/the-public-modal";

export default {
  name: "the-reset-pass-modal",
  components: {ThePublicModal, TheInput},
  data() {
    return {
      show: false,
      oldpassword: '',
      newpassword1: '',
      newpassword2: '',
      passError: '',
      pass1Error: '',
      pass2Error: '',
    }
  },

  methods: {
    getmodaldata(e) {
      this.$emit("open", e)
    },

    getCurrrentPassword(e) {
      this.oldpassword = e
    },

    getNewPassword(e) {
      this.newpassword1 = e
    },

    getConfirmpassword(e) {
      this.newpassword2 = e
    },

    //send data
    async submit() {

      let passSent = {
        oldPassword: this.oldpassword,
        password: this.newpassword1
      }
      await this.check2Pass()
      if (this.newpassword1 && this.newpassword2) {
        if (this.checkPass())
          this.state.loading = true
        try {
          const res = await this.$axios.post(`/users/change-password`, passSent)
          if (res.message === "password changed successfully") {
            this.$error('Your password has been changed', '', 'success')
            this.oldpassword = ''
            this.newpassword1 = ''
            this.newpassword2 = ''
          }
        } catch (error) {
          if (error.response.data.message === 'Old Password Is Incorrect') {
            this.passError = 'The previous password is incorrect.'
          }
        }
      }

    },


    //checkPassword1
    checkPass() {
      if (!this.oldpassword) {
        this.passError = 'Mandatory current password';
      } else {
        this.passError = ''
      }
      if (this.newpassword1) {
        if (this.newpassword1.length < 8) {
          this.pass1Error = 'At least 8 characters';
        } else if (!this.validPass(this.newpassword1)) {
          this.pass1Error = 'A combination of uppercase and lowercase letters and numbers';
        } else if (!this.newpassword2) {
          this.pass1Error = ''
          this.pass2Error = 'Repeat the mandatory password';
        } else if (this.newpassword2 !== this.newpassword1) {
          this.pass1Error = ''
          this.pass2Error = 'Repeat password does not match';
        } else {
          this.pass2Error = ''
        }
      }
      if (!this.passError.length && !this.pass1Error.length && !this.pass2Error.length) {
        return true;
      }
    },

    //checkPassword2
    async check2Pass() {
      if (!this.$validPass(this.oldpassword) || this.oldpassword.length < 8) {
        this.$error('The information entered is incorrect', 'The password must be at least 8 characters long and contain uppercase, lowercase, and numbers')
        return Promise.reject('wrong pass : ' + this.oldpassword)
      }
      if (this.newpassword1 !== this.newpassword2) {
        this.$error('The information entered is incorrect', 'Repeat the wrong password')
        return Promise.reject('repeat failed : ' + this.newpassword1 + '!==' + this.newpassword2)
      }
    },

    //valid Password
    validPass(password) {
      const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/;
      return re.test(password);
    }
  },

}
</script>

<style lang="scss" scoped>
.resetpass {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 16px;
  justify-content: space-between;

  .header-auth {
    justify-content: start !important;
    align-items: start !important;
    color: rgba(72, 77, 86, 1);
    font-size: large;
  }

  .main-input {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .gold-btn {
    color: white;
    margin: 20px 0;
  }
}
</style>
<template>
  <div class="add-cart">
    <div class="header">
      <span>Pay with New Card</span>
    </div>

    <div class="inputs">
      <span class="text">Card Information</span>
      <the-input value="Name on Card"/>
      <the-input value="Card Number" />
      <div class="date-input">
        <the-input value="Expiry Date"/>
        <the-input value="Expiry Date"/>
      </div>
      <button class="gold-btn">Add Card</button>
    </div>
  </div>
</template>

<script>
import TheInput from "./the-input";
export default {
  name: "the-add-card",
  components: {TheInput}
}
</script>

<style lang="scss" scoped>
.add-cart {
  background-color: #FFFFFF;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  padding: 16px;
  width: auto;

  .gold-btn{
    color: #FFFFFF;
  }

  .header {
    display: flex;
    justify-content: start;
    color: rgba(72, 77, 86, 1);
    font-size: clamp(16px, 2vw, 20px);
    font-weight: bold;
  }

  .inputs{
    display: flex;
    flex-direction: column;
    gap: 24px;
    .date-input{
      display: flex;
      column-gap: 15px;
      flex-direction: row;
      flex-wrap: nowrap;
    }

    .text{
      color: rgba(64, 64, 64, 1);
      font-size: clamp(12px, 2vw, 14px);
      margin-top: 24px;
    }
  }
}

@media (max-width: 480px) {
  .add-cart{
    width: 300px;
  }
}
</style>
import Vue from 'vue'
import App from './App.vue'
//import state
import './lib/state'
import './lib/axios'
import './lib/globalFunction'
import './lib/globalConstants'
import './lib/georgianToJalali'
import './lib/reuseableFunction'
import VueSweetalert2 from 'vue-sweetalert2';
Vue.use(VueSweetalert2);
// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';

import VueCookies from 'vue-cookies';
Vue.use(VueCookies)

import router from "./router"

import VuePersianDatetimePicker from 'vue-persian-datetime-picker'
Vue.component('date-picker', VuePersianDatetimePicker)

//import project style
import './styles/main.css'

//import trend chart
import TrendChart from "vue-trend-chart";
Vue.use(TrendChart);

// inlinesvg
import InlineSvg from 'vue-inline-svg';
Vue.component('inline-svg', InlineSvg);

//import apexchart
import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)
Vue.component('apex-chart', VueApexCharts)

Vue.config.productionTip = false

new Vue({
    router,
    render: h => h(App),
}).$mount('#app')
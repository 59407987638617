<template>
  <div class="box-exchange">
    <!--      the modals show-->
    <the-exchange-modal v-if="showreceivemodal === true"
                        @close="showreceivemodal = false"
                        @selectcoin="Receivedata"
    />
    <the-exchange-modal v-if="showspendmodal === true"
                        @close="showspendmodal = false"
                        @selectcoin="Spenddata"
    />

    <!--             the header box-exchange-->
    <div class="header">
      <div class="links">
        <a @click="select = 'sell'" :class="{'active-link' : select === 'sell'}" class="link">Sell</a>
        <a @click="select = 'buy'" :class="{'active-link' : select === 'buy'}" href="#" class="link">Buy</a>
      </div>
      <div class="header-line"></div>
    </div>

    <!--             the inputs box-exchange-->
    <div class="inputs">
      <the-exchange-input title="Receive" :data="dataresive" @showExchangeModal="showreceivemodal = true"/>
      <the-exchange-input title="Spend" :data="dataspend" @showExchangeModal="showspendmodal = true"/>
    </div>

    <!--             the content box-exchange-->
    <div class="content">
      <div class="price">Price</div>
      <div class="chang">
        <span>1 BTC =</span>
        <span>{{dataspend.wholePriceOfCredit}} USD</span>
      </div>
    </div>

    <!--             the buttons box-exchange-->
    <div class="buttons">
      <button @click="Buy" v-if="select === 'buy'" class="buy-btn-color green-btn">Buy BTC</button>
      <button @click="Sell" v-if="select === 'sell'" class="sell-btn-color green-btn">Sell BTC</button>
    </div>
  </div>
</template>

<script>
import TheExchangeInput from "./the-exchange-input";
import TheExchangeModal from "../tools/the-exchange-modal";

export default {
  name: "the-box-exchange",
  components: {TheExchangeModal, TheExchangeInput},
  data() {
    return {
      select: 'buy',
      showspendmodal: false,
      showreceivemodal: false,
      dataresive: [
        'BITCOIN',
        {
          address: "mfs2cHuioZunxUCYph3yWYAy9xXU4jNsUE",
          balance: null,
          blockedCredit: 0,
          confirmedCredit: 0,
          credit: 0,
          exchangeOwner: false,
          ownersUsername: "nima2142.p30@gmail.com",
          path: null,
          relatedCoin: "BITCOIN",
          walletName: null,
          wholePriceOfBalance: null,
          wholePriceOfCredit: 0

        }
      ],
      dataspend: [
        'BITCOIN',
        {
          address: "mfs2cHuioZunxUCYph3yWYAy9xXU4jNsUE",
          balance: null,
          blockedCredit: 0,
          confirmedCredit: 0,
          credit: 0,
          exchangeOwner: false,
          ownersUsername: "nima2142.p30@gmail.com",
          path: null,
          relatedCoin: "BITCOIN",
          walletName: null,
          wholePriceOfBalance: null,
          wholePriceOfCredit: 0

        }
      ],
      showDate: false,
      customerAddress: [],
      price: 0,
      amount: 0,
      total: 0,
      date: '',
    }
  },

  methods: {
    Receivedata(e) {
      this.dataresive = e
    },

    Spenddata(e) {
      this.dataspend = e
    },


    //buy crypto

    async Buy() {
      this.state.loading = true;
      let orderDetail;
      let marketType = this.$coin2Snake[this.dataresive[0]] + '_TETHER';

      if (this.select === 'Limit') {
        orderDetail = {
          marketType: marketType,
          amount: this.$S2N(this.amount),
          orderType: `LIMITED_BUY`,
          expiresAt: this.date || null,
          unitPrice: this.$S2N(this.price),
          wholePrice: this.$S2N(this.total),
        }
      } else if (this.select === 'Market') {

        orderDetail = {
          marketType: marketType,
          amount: this.$S2N(this.amount),
          orderType: `MARKET_BUY`,
        }
      }

      try {
        const res = await this.$axios.post(`/orders/buy`, orderDetail, {
          params: {
            force: true
          }
        });
        this.state.loading = true;

      } catch (error) {
        if (error.response.data.message === "the amount of order is bigger than market liquidity") {
          await this.$error('', 'The requested volume is more than the market volume.')
        }
      }
    },

    //sell crypto
    async Sell() {
      this.state.loading = true;
      let orderDetail;
      let marketType = this.$coin2Snake[this.dataspend[0]] + '_TETHER';

      if (this.select === 'Limit') {
        orderDetail = {
          marketType: marketType,
          amount: this.$S2N(this.amount),
          orderType: `LIMITED_SELL`,
          expiresAt: this.date || null,
          unitPrice: this.$S2N(this.price),
          wholePrice: this.$S2N(this.total),
        }
      } else if (this.select === 'Market') {
        orderDetail = {
          marketType: marketType,
          amount: this.$S2N(this.amount),
          orderType: `MARKET_SELL`,
        }
      }

      try {
        const res = await this.$axios.post(`/orders/sell`, orderDetail, {
          params: {
            force: true
          }
        });
        this.state.loading = true;

      } catch (error) {
        if (error.response.data.message === "the amount of order is bigger than market liquidity") {
          await this.$error('', 'The requested volume is more than the market volume.')
        }
      }
    },


  }
}
</script>

<style lang="scss" scoped>
.box-exchange {
  background-color: #FFFFFF;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  padding: 16px;
  width: 432px;
  height: 432px;

  //the header box-exchange
  .header {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 32px;

    .active {
      color: #4613A3;
    }

    .header-line {
      border-bottom: 1px solid #a8adb7;
      width: 60%;
    }

    .links {
      display: flex;
      flex-direction: row;

      .link {
        color: rgba(189, 189, 189, 1);
        font-size: 14px;
        text-decoration: none;
        position: relative;
        width: 109px;
        text-align: center;
        cursor: pointer;
        margin-bottom: 8px;
      }

      .active-link {
        @extend .link;
        color: #4613A3 !important;

        &:after {
          content: "";
          width: 100%;
          height: 3px;
          background-color: #4613A3;
          position: absolute;
          left: 0;
          bottom: -10px;
        }
      }
    }

  }

  //the inputs box-exchange
  .inputs {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .box-input {
      width: 100%;
      background-color: rgba(242, 242, 242, 1);
      display: flex;
      flex-direction: column;
      gap: 24px;
      border-radius: 8px;
      padding: 12px;

      .row-1 {
        display: flex;
        justify-content: start;

        .text-1 {
          color: rgba(72, 77, 86, 1);
          font-size: 14px;
          font-weight: 400;
        }
      }

      .row-2 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        column-gap: 100px;

        .value {
          color: rgba(72, 77, 86, 0.5);
          font-size: clamp(12px, 2vw, 18px);
          font-weight: 400;
        }

        .btn {
          width: auto;
          height: 36px;
          background-color: #FFFFFF;
          display: flex;
          flex-direction: row;
          gap: 8px;
          color: rgba(72, 77, 86, 1);
          font-size: 16px;
          align-items: center;
          padding: 8px;
          border-radius: 16px;
          justify-content: space-between;
          cursor: pointer;
          font-weight: 600;

          .coin {
            width: 24px;
            height: 24px
          }

          .arrow {
            width: 6px;
            height: 6px
          }
        }
      }

    }
  }

  //the content box-exchange
  .content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: rgba(72, 77, 86, 1);
    font-size: 14px;
    margin-top: 24px;
  }

  //the buttons box-exchange
  .buttons {
    display: flex;
    margin-top: 32px;

    button {
      color: #FFFFFF;
    }

    .sell-btn-color {
      background-color: #cc2d6a !important;
    }

    .buy-btn-color {
      background-color: rgba(74, 163, 113, 1) !important;
    }
  }
}
</style>
import { render, staticRenderFns } from "./the-public-modal.vue?vue&type=template&id=437b33bc&scoped=true&"
import script from "./the-public-modal.vue?vue&type=script&lang=js&"
export * from "./the-public-modal.vue?vue&type=script&lang=js&"
import style0 from "./the-public-modal.vue?vue&type=style&index=0&id=437b33bc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "437b33bc",
  null
  
)

export default component.exports
<template>
  <div class="main-market">
    <!--  header custom component-->
    <the-header value="Markets"/>

    <div class="table-div">
      <table class="public-table">
        <tr>
          <th :class="item.class" v-for="(item , index) in headerData" :key="index">
            <div class="th-table">
              {{ item.name }}
              <div class="arrows">
                <inline-svg @click="sortdata(item.value)" v-if="item.sort" class="hover-svg"
                            :src="require('../../assets/public/arrow-top.svg')"/>
                <inline-svg @click="resortdata(item.value)" v-if="item.sort" class="hover-svg"
                            :src="require('../../assets/public/arrow-bottom.svg')"/>
              </div>
            </div>
          </th>
        </tr>

        <tr v-for="(item , index) in dataTable" :key="index">
          <td>{{ item.relatedCoin + '/USDT' }}</td>
          <td :class="item.class">{{ item.lastPrice }}</td>
          <td :class="{'success' : item.last24HourChange > 0 , 'danger' : item.last24HourChange <= 0}">
            {{ item.last24HourChange }}
          </td>
          <td :class="item.class">{{ item.last24HourHighestPrice }}</td>
          <td>{{ item.minPrice }}</td>
          <td :class="item.class">{{ item.last24HourVolume }}</td>
        </tr>

      </table>
    </div>

  </div>
</template>

<script>
import TheHeader from "../tools/The-header";

export default {
  name: "the-market",
  components: {TheHeader},
  data() {
    return {

      datesort: [],
      sort: [],
      //header data
      headerData: [
        {name: "Pair", sort:true , value:'relatedCoin'},
        {name: "Last Price", sort: true, class: "hide", value:'lastPrice'},
        {name: "24h Change", sort: true, class: "hide",value:'last24HourChange'},
        {name: "24h High", sort: true, class: "hide",value:'last24HourHighestPrice'},
        {name: "24h Low", sort: true,value:'minPrice'},
        {name: "24h Volume", sort: true,value:'last24HourVolume'},
      ],

      //data table
      dataTable: [],

      //MARKET TYPE
      TetherCoin: 'market_type=BITCOIN_TETHER' +
          '&market_type=BITCOIN_CASH_TETHER' +
          '&market_type=ETHEREUM_TETHER' +
          '&market_type=LITE_COIN_TETHER' +
          '&market_type=BINANCE_COIN_BSC_TETHER' +
          '&market_type=MATIC_TETHER' +

          '&market_type=STELLAR_TETHER' +
          '&market_type=TRON_TETHER' +
          '&market_type=RIPPLE_TETHER' +
          '&market_type=DOGE_COIN_TETHER' +
          '&market_type=CELO_TETHER',

    }
  },

  methods: {
    async getMarket() {
      const res = await this.$axios.get('/trades/market-info?' + this.TetherCoin, {
        params: {
          weeklyDataIncluded: false
        }
      });
      this.dataTable = res.content;
    },

    sortdata(value){
      let item = this.dataTable;
      var so = this;
      this.data = value;
      item.sort((a,b)=>  {return b[so.data] - a[so.data]});
    },

    resortdata(value){
      let item = this.dataTable;
      var so = this;
      this.data = value;
      item.sort((a,b)=> {return a[so.data] - b[so.data]});
    }
  },

  mounted() {
    this.getMarket();
  }
}
</script>

<style lang="scss" scoped>
//general styles
.td-first {
  text-align: left !important;
}

.table-div {
  display: flex;
  justify-content: left;
  align-items: center;
  padding: 60px;
}

.th-table {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
}

.td-last {
  text-align: right !important;
}

.public-table {
  table-layout: fixed !important;
}

.right {
  justify-content: right !important;
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
}

.success {
  color: rgba(93, 204, 141, 1);
}

.danger {
  color: rgba(242, 53, 126, 1);
}

//main styles
.main-market {
  width: 100%;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;


  h1 {

  }


  .hover-svg {
    fill: #BDBDBD !important;
    width: 8px;
    height: 8px;
    cursor: pointer;
  }

  .hover-svg:hover {
    fill: var(--main-background-color) !important;
  }


  th {
    .arrows {
      display: flex;
      flex-direction: column;
      gap: 1px;
    }

    .item {
      display: flex;
      flex-direction: row;
      gap: 5px;
      align-items: center;
      justify-content: center;
    }
  }

}

@media (max-width: 690px) {
  .hide {
    display: none !important;
  }
}

</style>


<template>
  <div>
    <div class="readmessagesitems">

      <div @click="showContent = !showContent" class="title-message">
        <span class="title">{{ items.title }}</span>
        <div class="date">
          <span class="date">{{ items.createdAtDateTime }}</span>
          <img class="pluse" v-if="showContent === false" src="../../../assets/public/pluse.png" alt="">
          <img v-if="showContent === true" src="../../../assets/public/minez.png" alt="">
        </div>
      </div>

      <div v-if="showContent === true" class="content-message">
        <p>{{items.content}}</p>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "the-drop-notif",
  props: ["items"],
  data() {
    return {
      explain: false,
      showContent: false
    }
  },

  methods: {
    explainopen() {
      this.explain = true
    },

    explainclose() {
      this.explain = false
    }
  }
}
</script>

<style lang="scss" scoped>

.readmessagesitems {
  display: flex;
  flex-direction: column;
  min-height: 50px;
  border-bottom: 1px solid rgba(72, 77, 86, 0.4);

  .title-message {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    cursor: pointer;
    font-weight: bold;
    align-items: baseline;

    .title{
      position: relative;
      &:before {
        content: "";
        width: clamp(8px, 2vw, 12px);
        height: clamp(8px, 2vw, 12px);
        border-radius: 50%;
        position: absolute;
        background-color: #4AA371;
        left: -15px;
        top: 25%;
      }
    }

    .date {
      display: flex;
      flex-direction: row;
      gap: 10px;
      align-items: center;
      font-weight: 400;
      font-size: small;

      .pluse {
        width: 14px;
        height: 14px;
      }
    }
  }

  .content-message {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    p{
      font-size: small;
      color: #484D56;
    }
  }
}

//.items {
//  width: 100%;
//  display: flex;
//  min-height: 50px;
//  justify-content: space-between;
//  align-items: baseline;
//  border-bottom: 1px solid rgba(72, 77, 86, 0.4);
//  flex-direction: column;
//
//  .explain {
//    color: rgba(72, 77, 86, 1);
//    font-size: 14px;
//    margin:16px 0;
//  }
//
//
//  .top-row{
//    width: 100%;
//    display: flex;
//    justify-content: space-between;
//    align-items: baseline;
//    flex-direction: row;
//
//    .item-div {
//      display: flex;
//      flex-direction: row;
//      align-items: center;
//      gap: 12px;
//      margin-left: 10px;
//
//      .pluse {
//        cursor: pointer;
//      }
//
//      .date {
//        color: rgba(72, 77, 86, 0.5);
//        font-size: clamp(12px, 2vw, 14px);
//      }
//
//      .bold-text-disable {
//        font-size: clamp(14px, 2vw, 18px);
//        font-weight: 500;
//        color: rgba(72, 77, 86, 0.5);
//        position: relative;
//
//        &:before {
//          content: "";
//          width: clamp(8px, 2vw, 12px);
//          height: clamp(8px, 2vw, 12px);
//          border-radius: 50%;
//          position: absolute;
//          background-color: #a8a8a8;
//          left: -15px;
//          top: 25%;
//        }
//      }
//
//      .bold-text {
//        font-weight: 500;
//        position: relative;
//        font-size: clamp(14px, 2vw, 18px);
//
//        &:before {
//          content: "";
//          width: clamp(8px, 2vw, 12px);
//          height: clamp(8px, 2vw, 12px);
//          border-radius: 50%;
//          position: absolute;
//          background-color: #00A35F;
//          left: -15px;
//          top: 25%;
//        }
//      }
//    }
//
//  }
//}

</style>
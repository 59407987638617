<template>
  <div class="main-footer">
    <div class="rows">
      <!--      this a logo footer section-->
      <div class="logo-footer">
        <img  @click="$router.push({name:'Home'})" class="logo-ft" src="../../../assets/footer/footer-logo.png" alt="">
        <p>The easiest place to buy and sell cryptocurrency.</p>
        <!--        this a sochial media icon section-->
        <div class="sochial-icon">
          <a :href="facebook"><img src="../../../assets/footer/facebook-icon.png" alt=""></a>
          <a :href="inestageram"><img src="../../../assets/footer/inesta.png" alt=""></a>
          <a :href="twitter"><img src="../../../assets/footer/twitter-icon.png" alt=""></a>
          <a :href="telegram"><img src="../../../assets/footer/telegram-icon.png" alt=""></a>
          <a :href="email"><img src="../../../assets/footer/Email.png" alt=""></a>

        </div>
      </div>
      <div class="menu">
        <div class="row">
          <!--          this a footer nav  -->
          <span class="title">About Us</span>
          <ul>
            <li v-for="(i , index) in row1" :key="index"><a @click="$router.push({name:i.Name})">{{ i.name }}</a></li>
          </ul>
        </div>

        <div class="row">
          <span class="title">Services</span>
          <ul>
            <li @click="$router.push('trade/BTC')">Trade</li>
            <li v-for="(i , index) in row2" :key="index"><a @click="$router.push({name:i.Name})">{{ i.name }}</a></li>
          </ul>
        </div>

        <div class="row">
          <span class="title">Markets</span>
          <div class="duble-row">
            <ul>
              <li @click="$router.push('trade/'+i.type)"  v-for="(i , index) in row3" :key="index">{{ i.name }}</li>
            </ul>
            <ul>
              <li @click="$router.push('trade/'+i.type)" v-for="(i , index) in row4" :key="index">{{ i.name }}</li>
            </ul>
            <ul>
              <li @click="$router.push('trade/BTC')">Stellar</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="border"></div>
    <!--    this a copyright section -->
    <div class="copyright">
      <p>Copyright © 2021 VistaApp . All rights reserved.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "The-footer",
  data() {
    return {
      //Address sochial media
      telegram: '#',
      whatsapp: '#',
      twitter: '#',
      facebook: '#',
      inestageram: '#',
      youtube: '#',
      email:"#",

      //footer menu data
      row1: [
        // {'About Us', 'FAQ', 'Privacy Policy', 'Blog', 'How To Use'},
        {name:'About Us' , Name:"AboutUs"},
        {name:'FAQ' , Name:"FAQ"},
        {name:'Privacy Policy' , Name:"Privacy"},
        {name:'Blog' , Name:"Blog"},
        {name:'How To Use' , Name:"HowToUse"},
      ],
      row2: [
        {name:'Buy Crypto' , Name:"BoxExchange"},
        {name:'Convert & OTC' , Name:"OTC"},
        {name:'Fee Schedule' , Name:"Fees"},
        {name:'Markets' , Name:"Market"},
      ],

      row3:[
        {name:'Bitcoin' , type:'BTC'},
        {name:'Ethereum' , type:'ETH'},
        {name:'Ripple' , type:'XRP'},
        {name:'Dogecoin' , type:'DOGE'},
        {name:'Binance Coin' , type:'BNB'},
      ],

      row4:[
        {name:'Bitcoin Cash' , type:'BCH'},
        {name:'Litecoin' , type:'LTC'},
        {name:'Ethereum Classic' , type:'ETC'},
        {name:'Dash' , type:'DASH'},
        {name:'Tron' , type:'TRX'},
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
//styles
.main-footer {
  display: flex;
  flex-direction: column;
  background-color: var(--main-background-color);

  .rows {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 20px 50px;
    flex-wrap: wrap;
    gap: 50px;

    .logo-footer {
      display: flex;
      flex-direction: column;
      flex-grow: 1;

      .logo-ft {
        width: 30%;
        cursor: pointer;
      }

      .sochial-icon {
        display: flex;
        justify-content: space-between;
        width: 50%;
        gap: 30px;

        img {
          width: 20px;
          opacity: 0.7;
          &:hover{
            opacity: 1;
          }
        }
      }

      p {
        color: #F2F2F2;
        font-weight: 400;
        opacity: 0.7;
      }

      img {
        width: 50%;
      }
    }

    .menu {
      display: flex;
      flex-direction: row;
      flex-grow: 2;
      justify-content: space-around;
      flex-wrap: wrap;

      .duble-row {
        display: flex;
        flex-direction: row;
        gap: 30px;
      }

      .row {
        span {
          font-size: large;
          color: white;
        }

        ul {
          padding: 0 !important;

          li {
            cursor: pointer;
            color: white;
            opacity: 0.7;
            list-style: none;
            margin-bottom: 10px;
          }
        }
      }
    }
  }

  .border {
    width: 90%;
    margin: 0 auto;
    border-top: 1px solid rgba(242, 242, 242, 0.3);
  }

  .copyright {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    opacity: 0.7;

    p {
      text-align: center;
    }
  }
}

//media query
@media (max-width: 450px) {
  .logo-ft {
    width: 40% !important;
  }

  .sochial-icon {
    width: 100% !important;
  }
}

@media (max-width: 950px) {
  .logo-footer {
    align-items: center;
    text-align: center;
  }
}
</style>
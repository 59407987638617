<template>
  <router-view/>
</template>

<script>

export default {
  name: "the-assets",

  data(){
    return{

    }
  },
}
</script>

<style lang="scss" scoped>

</style>
<template>
  <div class="box-input">
    <div class="row-1">
      <input  @input="$emit('change')" class="input-text" type="text" :placeholder="placeholder">
      <div @click="openExchange" class="btn">
        <img class="coin" :src="require('../../assets/coins/'+data.logo)" alt="">
        <span>{{ data.name }}</span>
        <img class="arrow" src="../../assets/Exchange/arrow.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "the-otc-input",
  props: ["data" , "placeholder"],
  methods: {
    openExchange() {
      this.$emit('showotcModal');
    }
  }
}
</script>

<style lang="scss" scoped>
.box-input {
  width: 100%;
  background-color: rgba(242, 242, 242, 1);
  display: flex;
  flex-direction: column;
  gap: 24px;
  border-radius: 8px;
  padding: 12px;
  margin-bottom: 16px;

  .row-1{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    column-gap: 50px;

    .input-text{
      border: none;
      background-color: transparent;
      outline: none;
      color: rgba(72, 77, 86, 0.5);
      font-size: clamp(14px , 2vw , 18px);
    }

    .value{
      color: rgba(72, 77, 86, 0.5);
      font-size: clamp(14px , 2vw , 18px);
    }

    .btn {
      width: auto;
      height: 36px;
      background-color: #FFFFFF;
      display: flex;
      flex-direction: row;
      gap: 8px;
      color: rgba(72, 77, 86, 1);
      font-size: 16px;
      align-items: center;
      padding: 8px;
      border-radius: 16px;
      justify-content: space-between;
      cursor: pointer;
      font-weight: 600;

      .coin {
        width: 24px;
        height: 24px
      }

      .arrow {
        width: 6px;
        height: 6px
      }
    }
  }
}
</style>
<template>
  <div class="assets assets-box">
    <div class="Estimated-balance">
      <div class="title">
        Estimated Total Assets
      </div>
      <div class="value">
        {{ EstimatedTotalAssets + " " + "USD" }}
      </div>
    </div>

    <!--  table  -->
    <table class="public-table">
      <tr>
        <th>Coin</th>
        <th>Amount</th>
        <th class="hid-670">Available</th>
        <th class="hid-580">Frozen</th>
        <th class="hid-580">Market Value</th>
        <th>Operation</th>
      </tr>

      <tr v-for="(item , index) in wallets" :key="index">
        <td v-if="item[0] !== 'TOMAN'">

          <div class="names">
            <span class="Shortname">
           {{ $coinUnit[item[0]] }}
            </span>
            <span class="name hid-670">
              {{item[0] }}
            </span>
          </div>
        </td>

        <td v-if="item[0] !== 'TOMAN'">
          <span v-if="item[1].wholePriceOfCredit === 'null'">0</span>
          <span v-else>{{item[1].wholePriceOfCredit}}</span>
        </td>

        <td v-if="item[0] !== 'TOMAN'" class="hid-670">
          <span v-if="item[1].credit === 'null'">0</span>
          <span v-else>{{item[1].credit}}</span>
        </td>

        <td v-if="item[0] !== 'TOMAN'" class="blak hid-580">
          <span v-if="item[1].blockedCredit === 'null'">0</span>
          <span v-else>{{item[1].blockedCredit}}</span>
        </td>

        <td v-if="item[0] !== 'TOMAN'" class="blak hid-580">
          <span v-if="item[1].wholePriceOfCredit === 'null'">0</span>
          <span v-else>{{item[1].wholePriceOfCredit}}</span>
        </td>

        <td v-if="item[0] !== 'TOMAN'">
          <div class="links purple">
            <a @click="Deposit(index , item)" class="link">Deposit</a>
            <a @click=" Withdraw(index , item)" class="link">Withdraw</a>
            <a @click="Tarde(index , item)" class="link">Tarde</a>
          </div>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  name: "the-child-assets",
  data() {
    return {
      EstimatedTotalAssets: 2805.26,
      wallets: [],
      coins: [],
      Allcoins:[],
      arr:this.state.getselectedcoin,
    }
  },

  methods: {
    Deposit(index , item) {
      this.$router.push({name:'FnDeposite'});
      this.state.getselectedcoin.transferFee = this.Allcoins.find(a => a.coin === this.wallets[index][0]).transferFee;
      this.state.getselectedcoin.coin = this.Allcoins.find(a => a.coin === this.wallets[index][0]).coin;
      this.state.getselectedcoin.walletAdreess = this.wallets[index][1].address;
      this.state.getselectedcoin.maxWithdrawAmount = this.Allcoins.find(a => a.coin === this.wallets[index][0]).maxWithdrawAmount;
      this.state.getselectedcoin.logo = this.$coinUnit[this.wallets[index][0]];
      this.state.getselectedcoin.Network = (this.$coinUnit[this.wallets[index][0]])+ ' ' +'('+(this.wallets[index][0])+')';
      this.state.getselectedcoin.wholePriceOfCredit = this.wallets[index][1].wholePriceOfCredit;
      if(item[0] === 'TETHER'){
        this.state.getselectedcoin.transferFee = 0;
        this.state.getselectedcoin.coin = this.Allcoins.find(a => a.coin === this.wallets[index][0]).coin;
        this.state.getselectedcoin.walletAdreess = null;
        this.state.getselectedcoin.maxWithdrawAmount = 0;
        this.state.getselectedcoin.logo = this.$coinUnit[this.wallets[index][0]];
        this.state.getselectedcoin.Network = null;
        this.state.getselectedcoin.wholePriceOfCredit = this.wallets[index][1].wholePriceOfCredit;
      }
    },

    Withdraw(index , item) {
      this.$router.push({name:'FnWithdraw'});
      this.state.getselectedcoin.transferFee = this.Allcoins.find(a => a.coin === this.wallets[index][0]).transferFee;
      this.state.getselectedcoin.coin = this.Allcoins.find(a => a.coin === this.wallets[index][0]).coin;
      this.state.getselectedcoin.walletAdreess = this.wallets[index][1].address;
      this.state.getselectedcoin.maxWithdrawAmount = this.Allcoins.find(a => a.coin === this.wallets[index][0]).maxWithdrawAmount;
      this.state.getselectedcoin.logo = this.$coinUnit[this.wallets[index][0]];
      this.state.getselectedcoin.Network = (this.$coinUnit[this.wallets[index][0]])+ ' ' +'('+(this.wallets[index][0])+')';
      this.state.getselectedcoin.wholePriceOfCredit = this.wallets[index][1].wholePriceOfCredit;
      if(item[0] === 'TETHER'){
        this.state.getselectedcoin.transferFee = 0;
        this.state.getselectedcoin.coin = this.Allcoins.find(a => a.coin === this.wallets[index][0]).coin;
        this.state.getselectedcoin.walletAdreess = null;
        this.state.getselectedcoin.maxWithdrawAmount = 0;
        this.state.getselectedcoin.logo = this.$coinUnit[this.wallets[index][0]];
        this.state.getselectedcoin.Network = null;
        this.state.getselectedcoin.wholePriceOfCredit = this.wallets[index][1].wholePriceOfCredit;
      }
    },

    Tarde(index , item) {
      this.state.getselectedcoin = this.wallets[index];
      this.$router.push('/trade/' + $coinUnit[item[0]])
    },

    async getData() {
      this.state.loading = true
      const res = await this.$axios.get('/coins')
      this.coins = res.content
    },

    async getWallets() {
      this.state.loading = true
      const [res,] = await this.$http.get('/wallets/customer-addresses')
      if (res) {
        let entireRes = Object.entries(res)
        const sortCoins = this.$walletSortCoins
        entireRes.sort((a, b) => sortCoins.indexOf(a[0]) - sortCoins.indexOf(b[0]))
        this.wallets = entireRes;
      }
      this.state.loading = false

    },

    async getCoins(){
      const [res,] = await this.$http.get('/coins');
      this.Allcoins = res.content
    }
  },

  mounted() {
    this.getWallets();
    this.getCoins()
  }
}
</script>

<style lang="scss" scoped>

.links {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;

  .link {
    cursor: pointer;
  }

}

.assets {
  display: flex;
  flex-direction: column;

  table {

  }

  .Estimated-balance {
    display: flex;
    flex-direction: column;
    gap: 9px;
    flex-grow: 1;

    .title {
      color: #828282;
      font-size: clamp(14px, 1vw, 18px);
    }

    .value {
      color: #484D56;
      font-size: clamp(18px, 1vw, 24px);
      align-items: center;
      display: flex;
      gap: 10px;
    }
  }

  .names {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 10px;

    .name {
      color: rgba(189, 189, 189, 1);
    }

    .Shortname {
      color: rgba(70, 19, 163, 1);
    }
  }

  .blak {
    color: rgba(72, 77, 86, 1);
  }

  .purple {
    color: rgba(70, 19, 163, 1);
  }

}

@media (max-width: 670px) {
  .hid-670 {
    display: none;
  }
}

@media (max-width: 580px) {
  .hid-580 {
    display: none;
  }

  .links {
    flex-direction: column !important;
    gap: 10px;
  }

  td {
    border-bottom: 1px solid #a8adb7;
  }
}
</style>
<template>
  <div class="item">
    <div v-for="(item , index) in datamarket" :key="index"  class="main-btc">
      <div class="logo-bit">
        <img :src="require('../../../../../assets/coins/' + $coin3Snake[item.relatedCoin] + '.png')" alt="">
        <P> {{ item.relatedCoin + '/USDT' }}</P>
      </div>
      <div class="select-btc">
        <p class="green">40,425.21</p>
      </div>
      <div class="btc-tab">
        <div class="item-btc">
          <span class="title-span">24h Change</span>
          <span class="val-span" :class="{'green' : item.last24HourChange > 0}">{{ item.last24HourChange.toLocaleString() }}</span>
        </div>

        <div class="item-btc">
          <span class="title-span">24h High</span>
          <span class="val-span" :class="{'select': item.select === true}">{{ item.last24HourHighestPrice.toFixed(2).toLocaleString() }}</span>
        </div>

        <div class="item-btc">
          <span class="title-span">24h Low</span>
          <span class="val-span" :class="{'select': item.select === true}">{{ item.minPrice.toFixed(2).toLocaleString() }}</span>
        </div>

        <div class="item-btc">
          <span class="title-span">24h Volume(USDT)</span>
          <span class="val-span" :class="{'select': item.select === true}">{{ item.last24HourVolume.toFixed(2).toLocaleString() }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "the-selected-coin",
  data() {
    return {
      databtc: [
        {title: "24h Change", text: "88.62 +0.22%", select: true},
        {title: "24h High", text: "41,330.00", select: false},
        {title: "24h Low", text: "41,330.00", select: false},
        {title: "24h Volume(USDT)", text: "41,330.00", select: false},
      ],
      type: "",
      datamarket:[]
    }
  },

  methods: {
    marketType() {
      this.type = this.$coin2Snake[this.$route.params.coin] + '_TETHER';
    },

    async getheadersMarket() {
      const res = await this.$axios.get('/trades/market-info', {
        params: {
          weeklyDataIncluded: false,
          market_type: this.type
        }
      })
      this.datamarket = res.content;
      console.log(this.datamarket)
    }
  },

  mounted() {
    this.marketType()
    this.getheadersMarket();
  }
}
</script>

<style lang="scss" scoped>
.main-btc {
  display: flex;
  flex-direction: row;
  width: 95%;
  gap: 30px;
  margin: 0 auto;
  align-items: center;
  flex-wrap: wrap;

  .logo-bit {
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;

    img {
      width: 24px;
      height: 24px;
    }

    p {
      font-weight: bold;
      color: rgba(72, 77, 86, 1);
    }
  }

  .select-btc {
    p {
      font-weight: bold;
    }
  }

  .select {
    color: #00A35F !important;
  }

  .btc-tab {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    column-gap: 30px;
    flex-wrap: wrap;
    row-gap: 20px;

    .item-btc {
      display: flex;
      flex-direction: column;
      gap: 5px;
      flex-grow: 1;
      justify-content: center;

      .title-span {
        color: rgba(189, 189, 189, 1);
        font-size: 10px;
      }

      .val-span {
        color: rgba(64, 64, 64, 1);
        font-size: clamp(8px, 2.5vw, 11px);
      }
    }
  }
}


</style>
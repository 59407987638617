<template>
  <div class="main-home">
    <!--header section-->
    <div class="header">
      <div class="liner">
        <div class="topdivision">
          <h1>Buy & sell Crypto in minutes</h1>
          <p>Join the world’d largest crypto exchange</p>
          <div class="btn-header">
            <button @click="$router.push({name:'Register'})" class="gold-btn">Register</button>
          </div>
        </div>
        <div class="bottomdivision">
          <div v-for="(item , index) in coinsdata" :key="index" class="item">
            <div class="fee">
              <span>{{ item.relatedCoin + '/USDT' }}</span>
              <span :class="{'red-span' :item.last24HourChange < 0 , 'green-span':item.last24HourChange >= 0 }">
                {{ item.last24HourChange + '%' }}
              </span>
            </div>
            <div class="usd">
              <span>{{ item.lastPrice }}</span>
            </div>
            <div class="price">
              <span>{{ '$' + item.maxPrice }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--box section-->
    <div class="main-box">
      <div v-for="(item ,index) in boxdata" :key="index" class="box">
        <div class="btn-box">
          <button class="solid-btn">News</button>
        </div>
        <div class="text-box">
          <p>{{ item.text }}</p>
          <span>{{ item.days }}d ago</span>
        </div>
      </div>
    </div>

    <!--Market trend-->
    <div  class="main-market-trend">
      <div class="title"><p>Market trend</p></div>
      <div class="table">
        <table>
          <tr>
            <th class="th-name">Name</th>
            <th class="th-price">Last Price</th>
            <th class="th-change">24h Change</th>
            <th class="th-markets">Markets</th>
          </tr>
          <tr @click="$router.push(/trade/+item.relatedCoin)" v-for="(item , index) in tabledata" :key="index">
            <td>
              <div class="item-1">
                <img :src="require('../../assets/coins/'+ $coinUnit[item.relatedCoin] +'.png')" alt="Markets">
                <div class="txt-item-1">
                  <span class="spn-name">{{ item.relatedCoin }}</span>
                  <span class="spn-abbr">{{$coin2Snake[item.relatedCoin]}}</span>
                </div>
              </div>
            </td>
            <td><span>{{ '$' + item.lastPrice.toLocaleString() }}</span></td>
            <td><span :class="{'red':item.last24HourChange < 0 , 'green':item.last24HourChange > 0}">{{
                item.last24HourChange + '%'
              }}</span></td>
            <td class="td-markets">
              <div class="chart">
                <svg
                    style="width: 0; height: 0; position: absolute"
                    aria-hidden="true"
                    focusable="false"
                >
                  <defs>
                    <linearGradient id="btcFill" x1="1" x2="1" y1="0" y2="1">
                      <stop offset="0%" stop-color="rgba(70, 19, 163, 1)"/>
                      <stop offset="100%" stop-color="rgba(70, 19, 163, 0)"/>
                    </linearGradient>
                  </defs>
                </svg>
                <TrendChart
                    :datasets="[
    {
      data: chartData(tabledata),
      smooth: true,
      fill: true,
      className: 'curve-btc'
    }
  ]"
                    :grid="{
     verticalLines: true,
     horizontalLines: true
  }"
                    :min="0">
                </TrendChart>
              </div>
            </td>
          </tr>
        </table>
      </div>
      <div class="more-link-market">
        <router-link to="market">View more markets</router-link>
        <img src="../../assets/home/right-arrow-tab.png" alt="">
      </div>
    </div>

    <!--trade anyway  -->
    <div class="main-app-div">
      <div class="title">
        <p>Trade. Anywhere.</p>
        <small>Compatible with multiple devices, start trading with safety and convenience.</small>
      </div>
      <div class="images-app">
        <div class="left">
          <img src="../../assets/home/mobile-1.png" alt="mobile">
          <img class="mobile-img-2" src="../../assets/home/mobile-2.png" alt="mobile">
        </div>
        <div class="right">
          <div class="qr-code">
            <img src="../../assets/home/qr-code.png" alt="">
            <div class="text-qr">
              <p>Scan to Download</p>
              <small>iOS & Android</small>
            </div>
          </div>
          <div class="store">
            <div class="box-store">
              <img src="../../assets/home/ios-logo.png" alt="ios">
              <span>App Store</span>
            </div>

            <div class="box-store">
              <img src="../../assets/home/android-logo.png" alt="ios">
              <span>Android APK</span>
            </div>

            <div class="box-store">
              <img src="../../assets/home/google-play-logo.png" alt="ios">
              <span>Google PLay</span>
            </div>

          </div>
        </div>
      </div>
    </div>

    <!--options App-->
    <div class="main-options">
      <div class="title">
        <p>Get in touch. Stay in touch.</p>
      </div>

      <div class="boxes">
        <div class="item-options">
          <img src="../../assets/home/Fast.png" alt="">
          <p class="title-text-option">Instant delivery</p>
          <p class="small-text-option">Place your order and get your coins instantly. No deposits required</p>
        </div>

        <div class="item-options">
          <img src="../../assets/home/conversation.png" alt="">
          <p class="title-text-option">24/7 Support</p>
          <p class="small-text-option">Place your order and get your coins instantly. No deposits required</p>
        </div>

        <div class="item-options">
          <img src="../../assets/home/Wallet.png" alt="">
          <p class="title-text-option">Secure Wallets</p>
          <p class="small-text-option">Place your order and get your coins instantly. No deposits required</p>
        </div>

        <div class="item-options">
          <img src="../../assets/home/Safe.png" alt="">
          <p class="title-text-option">Privacy</p>
          <p class="small-text-option">Place your order and get your coins instantly. No deposits required</p>
        </div>
      </div>
    </div>

    <!--get start-->
    <div class="main-start">
      <div class="title-start">Start tradimg now</div>
      <div class="btn-start">
        <button @click="$router.push({name:'Register'})" class="purple-btn">Register Now</button>
        <button @click="$router.push({name:'Trade'})" class="gray-btn">Tarde Now</button>
      </div>
    </div>

  </div>
</template>

<script>
export default {

  name: "The-home",

  data() {
    return {
      width: 0,

      TetherCoin: 'market_type=BITCOIN_TETHER' +
          '&market_type=BITCOIN_CASH_TETHER' +
          '&market_type=ETHEREUM_TETHER' +
          '&market_type=LITE_COIN_TETHER' +
          '&market_type=BINANCE_COIN_BSC_TETHER' +
          '&market_type=MATIC_TETHER' +

          '&market_type=STELLAR_TETHER' +
          '&market_type=TRON_TETHER' +
          '&market_type=RIPPLE_TETHER' +
          '&market_type=DOGE_COIN_TETHER' +
          '&market_type=CELO_TETHER',

      coinsdata: [],

      boxdata: [
        {text: "Ethereum devs announce upcoming hard fork", days: 1},
        {text: "Ethereum devs announce upcoming hard fork", days: 2},
        {text: "Ethereum devs announce upcoming hard fork", days: 3},
        {text: "Ethereum devs announce upcoming hard fork", days: 4},
      ],

      tabledata: []

    }
  },

  computed: {
    chartData() {
      return e => e.lastWeekData ? (e.lastWeekData.length > 2 ? e.lastWeekData : [0, 0]) : [0, 1, 0, 1,]
    }
  },

  methods: {
    async setData() {
      const res = await this.$axios.get('/trades/market-info?' + this.TetherCoin, {
        params: {
          weeklyDataIncluded: true,
          // marketType: this.active ? this.TomanCoin : this.TetherCoin
        }
      })
      this.coinsdata = res.content.slice(0 , 5);
      this.tabledata = res.content.slice(0 , 6);
    },

  },

  mounted() {
    this.setData();

  }
}
</script>

<style lang="scss" scoped>

//general class
.red {
  color: rgba(204, 45, 106, 1);
}

.green {
  color: rgba(74, 163, 113, 1);
}

.title {
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 100%;
  padding-left: 30px;

  p {
    font-size: 24px;
    font-weight: bold;
    color: var(--main-background-color);
  }

  small {
    color: rgba(72, 77, 86, 1);
  }
}

//main class
.main-home {
  display: flex;
  flex-direction: column;
  background-color: #E5E5E5;

  //header styles
  .header {
    background-image: url("../../assets/home/Header-bg.png");
    display: flex;
    flex-direction: column;
    gap: 20px;

    .liner {
      display: flex;
      flex-direction: column;
      gap: 50px;
      background: linear-gradient(180deg, #4613A3 -1.89%, rgba(70, 19, 163, 0.5) 46.5%, #4613A3 100%);
    }

    .topdivision {
      display: flex;
      flex-direction: column;
      width: 90%;
      margin: 30px auto 0 auto;

      .btn-header {
        display: flex;
        max-width: 150px;

        button {
          color: rgba(70, 19, 163, 1) !important;
        }
      }

      h1 {
        color: white;
        opacity: 0.8;
      }

      p {
        color: white;
        opacity: 0.8;
      }
    }

    .bottomdivision {
      display: flex;
      flex-direction: row;
      width: 90%;
      margin: 0 auto 30px auto;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 20px;


      .item {
        display: flex;
        flex-direction: column;
        gap: 10px;
        color: white;

        .fee {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          gap: 10px;
        }

        .red-span {
          color: var(--Unsuccessful-color);
        }

        .green-span {
          color: var(--successful-color);
        }

        .usd {
          display: flex;
          justify-content: start;
          font-size: 20px;
          font-weight: bold;
        }

        .price {
          display: flex;
          justify-content: start;
          opacity: 0.7;
        }
      }
    }
  }

  //box styles
  .main-box {
    display: flex;
    justify-content: space-between;
    margin: 20px auto;
    width: 90%;
    gap: 20px;
    flex-wrap: wrap;
    cursor: pointer;

    .box {
      display: flex;
      flex-direction: column;
      padding: 20px 20px 50px 20px;
      position: relative;
      z-index: 2;
      flex-grow: 1;
      flex-basis: 100px;


      &:hover {
        content: "";
        width: 100%;
        height: 100%;
        background-color: var(--main-background-color);
        opacity: 0.8;
        border-radius: 12px;
      }

      &::before {
        background-image: url("../../assets/home/bg-box.png");
        background-position: center;
        background-size: cover;
        border-radius: 12px;
        filter: blur(2px);
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
      }

      .btn-box {
        display: flex;
        justify-content: start;

        .solid-btn {
          background-color: #FFFFFF;
          color: rgba(64, 64, 64, 1);
          font-size: small;
          border-radius: 4px;
          border: none;
        }
      }

      .text-box {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;

        p {
          color: white;
          font-size: large;
          font-weight: bold;
        }

        span {
          color: white;
          opacity: 0.7;
        }
      ;
      }
    }
  }

  //Market trend
  .main-market-trend {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 90%;
    margin: 20px auto;
    cursor: pointer;

    .more-link-market {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 10px;

      a {
        color: rgba(130, 130, 130, 1);
        text-decoration: none;
      }
    }

    .table {
      table-layout: fixed;
      width: 100%;
      margin: 20px auto;
      max-height: 566px;
      overflow: auto;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */

      &::-webkit-scrollbar {
        display: none;
      }


      .th-name {
        text-align: left
      }

      .th-price {
        text-align: left
      }

      .th-change {
        text-align: left
      }

      .td-markets {
        display: flex;
        justify-content: right;

        .chart{
          width: 100px;
          height: 28px;
          display: flex;
          justify-content: right;
        }


      }

      .th-markets {
        text-align: right;
      }

      table {
        width: 100%;
        border-collapse: separate;
        border-spacing: 30px;

        th {
          font-size: small;
          color: rgba(189, 189, 189, 1);
          font-weight: 400;
        }

        td {
          margin: 10px 0;
          color: rgba(72, 77, 86, 1);
        }

        .item-1 {
          display: flex;
          flex-direction: row;
          gap: 10px;

          img {
            width: 20px;
            height: 20px;
          }

          .txt-item-1 {
            display: flex;
            flex-direction: row;
            gap: 5px;

            .spn-name {
              font-size: small;
              color: rgba(72, 77, 86, 1);
            }

            .spn-abbr {
              color: rgba(130, 130, 130, 1);
              font-size: small;
            }
          }
        }
      }
    }
  }

  //trade anyway
  .main-app-div {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 90%;
    margin: 20px auto;

    .title-app {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    .images-app {
      display: flex;
      flex-direction: row;
      gap: 20px;
      justify-content: space-between;
      flex-wrap: wrap;

      .left {
        flex-grow: 1;

        .mobile-img-2 {
          top: 20px;
        }
      }

      .right {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 30px;

        .qr-code {
          display: flex;
          flex-direction: row;
          gap: 15px;

          .text-qr {
            display: flex;
            flex-direction: column;
            font-size: 12px;
            color: rgba(72, 77, 86, 1);
          }
        }

        .store {
          display: flex;
          flex-direction: row;
          gap: 50px;

          .box-store {
            img {
              width: 20px;
              height: 20px;
            }

            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 5px;
          }
        }
      }
    }
  }

  //options App
  .main-options {
    display: flex;
    flex-direction: row;
    width: 90%;
    margin: 20px auto;
    gap: 20px;
    flex-wrap: wrap;

    .boxes {
      display: flex;
      flex-direction: row;
      gap: 20px;

      .item-options {
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
        justify-content: center;
        flex-grow: 1;

        img {
          width: 80px;
          height: 80px;
        }

        .title-text-option {
          font-size: large;
          color: var(--main-background-color);
        }

        .small-text-option {
          color: rgba(72, 77, 86, 1);
          font-size: small;
        }
      }
    }
  }

  //get start
  .main-start {
    background-color: rgba(224, 224, 224, 1);
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .title-start {
      justify-content: center;
      align-items: center;
      font-size: large;
      font-weight: bold;
      display: flex;
    }

    .btn-start {
      display: flex;
      flex-direction: row;
      width: 300px;
      gap: 20px;
      margin: 20px auto;
    }
  }
}

//media query
@media (max-width: 450px) {
  table {
    font-size: small;
    border-collapse: separate;
    border-spacing: 10px !important;
  }

  .title {
    margin-left: 0 !important;
    font-size: medium;
  }

  .left img {
    width: 50%;
  }

  .images-app {
    gap: 50px !important;
  }

  .qr-code {
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 992px) {

  .boxes {
    flex-wrap: wrap;
  }
}

@media (max-width: 560px) {
  .th-markets {
    display: none;
  }

  .td-markets {
    display: none;
  }

  .spn-abbr {
    display: none;
  }

  .item-1 img {
    display: none;
  }

  .main-market-trend {
    cursor: pointer;

    table {
      th {
        text-align: center !important;
      }

      td {
        text-align: center;
      }

      .th-name {
        text-align: left !important;
      }
    }
  }
}
</style>

<style>
.curve-btc .stroke {
  stroke: #4613A3 !important;
  stroke-width: 2 !important;
}

.curve-btc .fill {
  fill:url(#btcFill);
  fill-opacity: 0.5 !important;
}
</style>
<template>
  <div class="main-fn-de">
    <the-exchange-modal v-if="showmodal === true"
                        @close="showmodal = false"
    />
    <!--    this is a top banner -->
    <div class="baner">
      <img src="../../../../assets/panel/Banner-Depos.png" alt="">
    </div>

    <div class="coins">
      <div class="left">
        <div class="header">
          <h2 class="title">Coin Deposit</h2>
        </div>

        <div class="main-rows">
          <!--    this is a Deposit  to section -->
          <div class="row-1">
            <div class="row1-left">
              <span>Select Coin</span>
              <span>Deposit to</span>
            </div>
            <div class="row1-right">

              <div  @click="showmodal = true" class="selectcoin">
                <img v-if="arr[0] !== 'TOMAN'" class="logo"
                     :src="require('../../../../assets/coinsName/'+ arr.coin +'.png')" alt="">
                <img class="arrow" src="../../../../assets/public/arrow-bottom.svg" alt="">
                <span class="selectedcoin">{{ arr.coin }}</span>
                <span class="top-span">Coin</span>
              </div>

              <div @click="select = !select" v-if="arr.coin === 'TETHER'"  class="selectcoin">
                <img v-if="arr.coin !== 'TOMAN'" class="logo" :src="require('../../../../assets/coinsName/'+ arr.coin +'.png')" alt="">
                <img class="arrow" src="../../../../assets/public/arrow-bottom.svg" alt="">
                <span class="selectedcoin">{{selectedTeter}}</span>
                <div v-if="select === true" class="select">
                  <span @click="selectedTeter ='ERC20'">ERC20</span>
                  <span @click="selectedTeter ='TRC20'">TRC20</span>
                </div>
                <span class="top-span">Network</span>
              </div>



              <div v-else @click="showmodal = true" class="deselectcoin">
                <span class="selectedNetworkcoin">{{ arr.Network }}  </span>
                <span class="top-span">Network</span>
              </div>

              <div class="balance">
                <span class="text-balance">Address</span>
              </div>
              <div class="balance">
                <span class="text-balance2">{{address}}</span>
                <div class="logo">
                  <img @click="copyCode" class="copy-logo" src="../../../../assets/panel/content_copy.png" alt="">
                </div>
              </div>

              <div class="qrcode">
                <img width="200px" style="margin-top: 20px" :src="'data:image/png;base64,'+Qrcode" alt="">
              </div>

              <div class="danger">

                <transition name="fade">
                  <span v-if="alertcode" class="copy-alert">Your code was successfully copied</span>
                </transition>

                <p class="text-danger">
                  Please confirm that your withdrawal address matches the type of your chosen mainnet, in case of
                  unnecessary asset loss;
                </p>
                <p class="text-danger">
                  After withdrawal audition, your asset will be sent out as soon as possible. The actual arrival time is
                  determined by the number of confirmations of the recipient.
                </p>
              </div>
              <button class="gold-btn" @click="Depositcheck">Deposit check</button>
            </div>
          </div>
        </div>
      </div>

      <div class="right">

        <div class="header">
          <h2 class="title">FAQ</h2>
        </div>

        <div class="faq-links">
          <a @click="$router.push({name:'FAQ'})" class="links" v-for="(item , index) in links" :key="index">
            <img src="../../../../assets/panel/iconlinks.png" alt="">
            {{ item }}
          </a>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
import TheExchangeModal from "../../../tools/the-exchange-modal";

export default {
  name: "the-fn-de",
  components: {TheExchangeModal},
  data() {
    return {
      showmodal: false,
      links: [
        'How to withdraw crypto, step by step guide',
        'How to withdraw crypto, step by step guide',
        'How to withdraw crypto, step by step guide',
        'How to withdraw crypto, step by step guide',
        'How to withdraw crypto, step by step guide',
      ],

      token: false,

      alertcode: false,

      arr: [],

      datamodal: [],

      address: '',

      Qrcode: '',

      select:false,

      selectedTeter:'ERC20',

    }
  },

  methods: {
    copyCode() {
      navigator.clipboard.writeText(this.links[0].refCode);
      this.alertcode = true;
      setTimeout(() => {
        this.alertcode = false;
      }, 1500)

      alert('cpyeid code!')
    },


    getData() {
      let data = this.state.getselectedcoin;
      this.arr = Object(data);
    },

    //get Qr code
    async getQR() {
      this.state.loading = true;
      let params = {
        relatedCoin: this.arr.coin
      }
      const res = await this.$axios('/wallets/customer/wallet-address', {params});
      if (res) {
        this.address = res.baseDTO.address;
        this.Qrcode = res.baseDTO.qrCode;
      }
    },

    //Deposit check
    async Depositcheck(){
      this.state.loading = true
      const token = this.selectedTeter
      let query = {
        relatedCoin: this.arr[0]
      }
      if (this.arr[0] ==='TETHER') {
        query.tokenType = token
      }
      const res = await this.$axios.get('/wallets/check-confirmed-transaction', {
        params: query
      })
      let text
      if (res.baseDTO.amount) {
        text = `the amount of ${this.$toLocal(res.baseDTO.amount) || 0} ${this.$coinLabel[this.relatedCoin[0]]} Deposited and approved ${res.baseDTO.transactionIsDone ? 'to be' : 'not to be'}`
      } else {
        text = 'Deposit amount not detected'
      }
      this.$error('', text, res.baseDTO.transactionIsDone ? 'success' : 'info')

      this.state.loading = false
    }

  },

  watch:{
    'arr.coin'(){
      this.getQR()
    }
  },

  mounted() {
    this.getData();
    this.getQR()
  }
}
</script>

<style lang="scss" scoped>
.select{
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: absolute;
  top: 102%;
  background-color: white;
  width: 100%;
  z-index: 10;
  border: 2px solid lightgray;
  padding: 20px 0;

  span{
    padding: 10px 0 10px 10px;

    &:hover{
      background-color: #a8a8a8;
    }
  }
}
.copy-alert {
  width: auto;
  height: auto;
  background-color: #00A35F;
  color: #FFFFFF;
  border-radius: 10px;
  padding: 10px;
  opacity: 0.9;
  font-size: clamp(12px, 1vw, 14px);
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
}

.disable {
  border: 2px solid #eae3e3 !important;
  cursor: not-allowed !important;
  pointer-events: none;
}

.enable {
  border: 2px solid #BDBDBD !important;
  cursor: pointer !important;
  pointer-events: auto !important;
}

.main-fn-de {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 16px;

  .baner {
    flex-grow: 1;

    img {
      width: 100%;
    }
  }

  .title {
    color: rgba(72, 77, 86, 1);
  }

  .coins {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    flex-wrap: wrap;
    column-gap: 180px;

    .left {
      flex-grow: 2;
      display: flex;
      justify-content: start;
      flex-direction: column;
      flex-basis: 100px;

      .main-rows {
        display: flex;
        flex-direction: column;
        gap: 30px;

        .row-1 {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          justify-content: space-between;
          gap: 10px;

          .row1-left {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: baseline;
            height: 95px;


            span {
              color: rgba(72, 77, 86, 1);
              font-size: 14px;
            }
          }

          .row1-right {
            flex-grow: 2;
            display: flex;
            gap: 16px;
            flex-direction: column;
            flex-basis: 100px;

            .qrcode{
              display: flex;
              width: 100%;
              justify-content: center;
              align-items: center;
            }

            .selectcoin {
              border: 2px solid #BDBDBD;
              box-sizing: border-box;
              border-radius: 4px;
              position: relative;
              width: 100%;
              height: 44px;
              align-items: center;
              cursor: pointer;

              .top-span {
                position: absolute;
                top: -10px;
                left: 3%;
                background-color: #FFFFFF;
                color: rgba(130, 130, 130, 1);
                font-size: 12px;
                padding: 0 2px;
              }

              .logo {
                position: absolute;
                left: 3%;
                top: 20%;
                width: 24px;
                height: 24px;
              }

              .arrow {
                position: absolute;
                right: 10px;
                top: 50%;
              }

              .selectedcoin {
                position: absolute;
                left: 50px;
                top: 30%;
                color: rgba(72, 77, 86, 1);
                font-size: 14px;
              }



              .selectedNetworkcoin {
                position: absolute;
                left: 10px;
                top: 30%;
                color: #484D56;
                font-size: 14px;
              }

              .selectedcoin2 {
                position: absolute;
                left: 3%;
                top: 30%;
                color: rgba(130, 130, 130, 1);
                font-size: 14px;
              }

              .nickname {
                color: rgba(189, 189, 189, 1);
                position: absolute;
                left: 15%;
                top: 30%;
                font-size: 14px;
              }

            }

            .deselectcoin{
              border: 2px solid #d7d5d5;
              box-sizing: border-box;
              border-radius: 4px;
              position: relative;
              width: 100%;
              height: 44px;
              align-items: center;
              cursor: pointer;

              .top-span {
                position: absolute;
                top: -10px;
                left: 3%;
                background-color: #FFFFFF;
                color: #BDBDBD;
                font-size: 12px;
                padding: 0 2px;
              }

              .logo {
                position: absolute;
                left: 3%;
                top: 20%;
                width: 24px;
                height: 24px;
              }

              .arrow {
                position: absolute;
                right: 10px;
                top: 50%;
              }

              .selectedcoin {
                position: absolute;
                left: 50px;
                top: 30%;
                color: rgba(72, 77, 86, 1);
                font-size: 14px;
              }



              .selectedNetworkcoin {
                position: absolute;
                left: 10px;
                top: 30%;
                color: #484D56;
                font-size: 14px;
              }

              .selectedcoin2 {
                position: absolute;
                left: 3%;
                top: 30%;
                color: rgba(130, 130, 130, 1);
                font-size: 14px;
              }

              .nickname {
                color: rgba(189, 189, 189, 1);
                position: absolute;
                left: 15%;
                top: 30%;
                font-size: 14px;
              }

            }


            .balance {
              display: flex;
              justify-content: space-between;
              column-gap: 50px;
              flex-direction: row;
              flex-wrap: wrap;
              row-gap: 20px;

              .logo {
                display: flex;
                flex-direction: row;
                gap: 5px;

                img {
                  width: 20px;
                  height: 20px;
                  cursor: pointer;
                }
              }

              .text-balance {
                font-size: 12px;
                color: rgba(189, 189, 189, 1);
              }

              .value-balance {
                font-size: 12px;
                color: rgba(189, 189, 189, 1);
                text-decoration: underline;
              }

              .value-balance1 {
                font-size: 12px;
                color: rgba(189, 189, 189, 1);
              }

              .text-balance2 {
                font-size: 12px;
                color: rgba(102, 102, 102, 1);
              }

              .value-balance2 {
                font-size: 12px;
                color: rgba(102, 102, 102, 1);
              }
            }

            .danger {
              display: flex;
              flex-direction: column;
              position: relative;
              align-items: end;
              width: 100%;

              .text-danger {
                color: rgba(204, 45, 106, 1);
                font-size: 12px;
                position: relative;
                width: 90%;

                &:before {
                  content: "!";
                  background-color: #cc2d6a;
                  border-radius: 50%;
                  width: 20px;
                  height: 20px;
                  margin-left: 5px;
                  display: flex;
                  justify-content: center;
                  color: #FFFFFF;
                  position: absolute;
                  left: -30px;
                }
              }
            }

            .gold-btn {
              color: #FFFFFF;
            }
          }
        }
      }
    }


    .right {
      flex-grow: 1;
      display: flex;
      flex-basis: 100px;
      flex-direction: column;
      align-items: baseline;
      gap: 30px;

      .header {
        display: flex;
        width: 100%;

        .title {
          text-align: left;
          width: 100%;
        }
      }

      .faq-links {
        display: flex;
        flex-direction: column;
        gap: 18px;

        img {
          width: 15px;
          height: 18px;
        }

        .links {
          color: rgba(130, 130, 130, 1);
          font-size: 14px;
          width: 100%;
          text-decoration: underline;
          display: flex;
          align-items: center;
          gap: 5px;
          cursor: pointer;
        }
      }
    }

  }
}

@media (max-width: 1200px) {
  .nickname {
    left: 25% !important;
  }
}
</style>
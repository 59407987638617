<template>
  <div class="item">
    <the-alert-modal v-if="shoeAlertModal === true" @close="shoeAlertModal = false"/>
    <the-delet-request-modal v-if="showDeletModal === true" @delet="delet" @notdelet="cancelDelet"
                             @close="showDeletModal = false"/>
    <the-header-table allhistory="allhistory" :items="historyTabletitle" selected="Open Orders"
                      @selectitem="bottomTable"/>
    <table>
      <tr>
        <th>Date</th>
        <th class="dont-show hide-300">Type</th>
        <th>Side</th>
        <th>Price</th>
        <th class="dont-show">Amount</th>
        <!--        chang th   -->
        <th class="dont-show">
          <span v-if="showTable === 'Open Orders' || showTable === 'Trade History'">Filled</span>
          <span v-if="showTable === 'Order History'">Executed</span>
        </th>

        <th class="dont-show">Total</th>

        <th v-if="showTable === 'Open Orders'">Operation</th>
        <th v-if="showTable === 'Order History'">Status</th>
      </tr>

      <tr v-for="(item , index) in tableContents" :key="index">
        <td>{{ item.createdAtDateTime }}</td>

        <td class="dont-show" v-if="item.orderType === 'LIMITED_BUY'">limit</td>
        <td class="dont-show" v-if="item.orderType === 'LIMITED_SELL'">limit</td>
        <td class="dont-show" v-if="item.orderType === 'MARKET_BUY'">Market</td>
        <td class="dont-show" v-if="item.orderType === 'MARKET_SELL'">Market</td>

        <td class="hide-300" v-if="item.orderType === 'LIMITED_BUY'"
            :class="{'red': item.orderType === 'LIMITED_SELL' , 'green' : item.orderType === 'LIMITED_BUY'}">Buy
        </td>
        <td class="hide-300" v-if="item.orderType === 'LIMITED_SELL'"
            :class="{'red': item.orderType === 'LIMITED_SELL' , 'green' : item.orderType === 'LIMITED_BUY'}">Sell
        </td>
        <td class="hide-300" v-if="item.orderType === 'MARKET_BUY'"
            :class="{'red': item.orderType === 'MARKET_SELL' , 'green' : item.orderType === 'MARKET_BUY'}">Buy
        </td>
        <td class="hide-300" v-if="item.orderType === 'MARKET_SELL'"
            :class="{'red': item.orderType === 'MARKET_SELL' , 'green' : item.orderType === 'MARKET_BUY'}">Sell
        </td>


        <td class="dont-show">{{ item.amount.toLocaleString() }}</td>

        <td>{{ item.unitPrice.toLocaleString() }}</td>

        <td class="dont-show" v-if="showTable === 'Open Orders'">{{ item.overallValue.toLocaleString() }}</td>

        <td class="dont-show" v-if="showTable === 'Trade History'">{{ item.executedPercent }}</td>

        <td class="dont-show" v-if="showTable === 'Order History'">{{ item.executedPercent }}</td>

        <td class="dont-show">{{ item.wholePrice.toLocaleString() }}</td>

        <td class="cancel-td" v-if="showTable === 'Order History' || showTable === 'Open Orders' ">
          <span @click="cancelRequest(item.id)" class="cancelButton"
                v-if="item.orderStatus === 'IS_OPEN' && showTable === 'Open Orders'">Cancel</span>
          <span v-if=" item.orderStatus === 'CANCELLED_BY_USER' && showTable === 'Order History'">Cancelled</span>
          <span v-if=" item.orderStatus === 'IS_OPEN' && showTable === 'Order History'">Filled</span>
          <span v-if=" item.orderStatus === 'FINISHED' && showTable === 'Order History'">Success</span>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import TheHeaderTable from "../../../tools/the-headerTable";
import TheDeletRequestModal from "../../../tools/the-deletRequest-modal";
import TheAlertModal from "../../../tools/the-alert-modal";

export default {
  name: "info-table",

  components: {
    TheAlertModal,
    TheDeletRequestModal,
    TheHeaderTable
  },

  data() {
    return {
      history: true,

      showTable: "Open Orders",

      type: '',

      isRemove: false,

      shoeAlertModal: false,

      showDeletModal: false,

      historyTabletitle: ["Open Orders", "Trade History", "Order History", "Funds"],

      tableContents: [],

    }
  },

  methods: {
    cancelDelet() {
      this.isRemove = false
    },

    delet() {
      this.isRemove = true;
      this.cancelRequest()
      this.isRemove = false;
    },

    bottomTable(e) {
      this.showTable = e;
      if (e === 'Open Orders') {
        this.getOpenOrders()
      }
      if (e === 'Trade History') {
        this.getLastTrades()
      }
      if (e === 'Order History') {
        this.getLastOrders()
      }

      if (e === 'Funds') {
        this.showTable = 'Open Orders'
        this.shoeAlertModal = true
      }

    },

    //get market type
    marketType() {
      this.type = this.$coin2Snake[this.$route.params.coin] + '_TETHER';
    },

    //get open orders
    async getOpenOrders() {
      const res = await this.$axios.get('/orders', {
        params: {
          orderStatus: 'IS_OPEN',
          market: this.type
        }
      })
      this.tableContents = res.content
      this.state.loading = false
    },

    //get trade history
    async getLastTrades() {
      const res = await this.$axios.get('/trades/last-trades', {
        params: {
          marketType: this.type,
          size: 20
        }
      })
      this.tableContents = res.content
      this.state.loading = false
    },

    //get order history
    async getLastOrders() {
      let res = await this.$axios.get('/orders', {
        params: {
          market: this.type,
          page: 1,
          size: 20
        }
      })

      this.tableContents = res.content;
      this.state.loading = false
    },

    //cancel Request
    async cancelRequest(e) {
      this.state.loading = true;
      const delet = await this.$axios.delete(`orders/${e}`);
      if (delet) {
        await this.getOpenOrders();
        this.showDeletModal = false
        this.showDeletModal = true;
      }
    }
  },

  created()
  {
    this.getOpenOrders()
    this.marketType()
  }
  ,

}
</script>

<style lang="scss" scoped>

.header .selected::before {
  top: 38px !important;
}


.header {
  width: 100% !important;
  position: sticky;
  top: 0;
  background-color: white;
}


.item {
  height: 250px;
  overflow: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

table {
  width: 100%;
  margin: 10px 0;
  table-layout: fixed;
  overflow: auto;
  max-height: 250px;

  tr:first-child{
    position: sticky;
    top: 40px;
    background-color: white;
  }

  th {
    text-align: center;
    color: rgba(189, 189, 189, 1);
    font-size: clamp(12px, 1vw, 14px);

    &:first-child{
      text-align: left;
    }

    &:last-child{
      text-align: right;
    }
  }

  td {
    font-size: clamp(12px, 1vw, 14px);
    color: rgba(72, 77, 86, 1);
    padding: 5px !important;
    text-align: center;

    &:first-child{
      text-align: left;
    }

    &:last-child{
      text-align: right;
    }
  }

  .cancel-td {
    color: rgba(70, 19, 163, 1);
    opacity: 0.4;
    text-decoration: underline;
    cursor: pointer;
  }
}

@media (max-width: 450px) {
  .hide-300{
    display: none !important;
  }
}
</style>
<template>
  <div class="main-header">
    <div class="layer">
      <p class="value">{{value}}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "The-header",
  props:["value"]
}
</script>

<style lang="scss" scoped>
.main-header {
  display: flex;
  background-image: url("../../assets/public/header.png");
  height: 250px;

  .layer{
    background-image: linear-gradient(180deg, #4613A3 0%, rgba(70, 19, 163, 0.4) 133.64%);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .value{
      font-size: 30px;
      font-weight: bold;
      color: white;
    }
  }
}
</style>
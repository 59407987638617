import { render, staticRenderFns } from "./the-exchange-modal.vue?vue&type=template&id=2046b708&scoped=true&"
import script from "./the-exchange-modal.vue?vue&type=script&lang=js&"
export * from "./the-exchange-modal.vue?vue&type=script&lang=js&"
import style0 from "./the-exchange-modal.vue?vue&type=style&index=0&id=2046b708&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2046b708",
  null
  
)

export default component.exports
<template>
  <div class="main-navbar">
    <!--    this a logo section -->
    <div @click="$router.push({name:'Home'})" class="logo">
      <img src="../../../assets/navbar/logo.png" alt="">
    </div>

    <!--      Humberger-menu section -->
    <div class="Humberger-menu">
      <!--      this a login mobile section -->
      <div v-if="!state.token" class="login-mobile">
        <a @click="$router.push({name:'Login'})" class="link" href="">Login</a>
        <div class="button-group-mobile">
          <button @click="$router.push({name:'Register'})" class="gold-btn">Register</button>
        </div>
      </div>
      <!--        login user section -->
      <div v-if="state.token" class="account-mobile">
        <img @click="$router.push({name : 'Panel'})" src="../../../assets/navbar/user.png" alt="">
        <img @click="showNotif = !showNotif" src="../../../assets/navbar/Alarm.png" alt="">
        <transition name="notif">
          <the-drop-notifications v-if="showNotif"/>
        </transition>
      </div>

      <img @click="show = true" src="../../../assets/navbar/Hamburger.png" alt="">
    </div>

    <!--    this a navbar mobile section -->
    <div v-if="show" class="list">
      <img @click="show = false" src="../../../assets/public/close.png" class="close-img" alt="">
      <ul>
        <li v-for="(item ,index) in navItems" :key="index">
          <a class="link" @click="$router.push({name : item.name}) ">{{ item.name }}</a>
        </li>
        <li>
          <a class="link" @click="$router.push({name : 'Exchange'})">Buy Crypto</a>
        </li>
      </ul>
    </div>


    <!--    this a logo menu section -->
    <div class="menu">
      <img src="../../../assets/navbar/menu-icon.png" alt="">
    </div>
    <!--    this a navbar  section-->
    <div class="items">
      <ul>
        <li class="first-item">
          <span @click="$router.push({name : 'Exchange'})">Buy crypto</span>
          <div>
            <img class="badg" src="../../../assets/navbar/solid-svg.png" alt="">
            <span class="USD-SPAN">USD</span>
          </div>
          <img class="arrow-bt" src="../../../assets/navbar/bottom-arrow.png" alt="">
        </li>

        <li v-for="(item , index) in navItems" :key="index" >
          <a class="link" @click="$router.push(item.route)">{{ item.name }}</a>
          <img v-if="item.arrow" class="arrow-nav-bottom"
               src="../../../assets/navbar/bottom-arrow.png" alt="">
        </li>

        <li class="first-item">
          <span>NFT</span>
          <div>
            <img class="badg" src="../../../assets/navbar/golden-svg.png" alt="">
            <span class="USD-SPAN">USD</span>
          </div>
        </li>

      </ul>
    </div>

    <!--    not register user section-->
    <div v-if="!state.token" class="login">
      <a class="link" @click="$router.push({name:'Login'})">Login</a>
      <div class="button-group">
        <button class="gold-btn" @click="$router.push({name:'Register'})">Register</button>
      </div>
    </div>

    <!--    register user section-->
    <div v-if="state.token" class="account">
      <div @click="$router.push({name:'Assets'})" class="walet"><span>Wallet</span>
        <img src="../../../assets/navbar/bottom-arrow.png" alt=""></div>
      <div><span @click="$router.push({name : 'OrderHistory'})">Orders</span></div>
      <img @click="$router.push({name : 'Panel'})" src="../../../assets/navbar/user.png" alt="">
      <img @click="showNotif = !showNotif" src="../../../assets/navbar/Alarm.png" alt="">

      <transition name="notif">
        <the-drop-notifications v-if="showNotif"/>
      </transition>

    </div>

    <!--    this a download section -->
    <div class="line"><img src="../../../assets/navbar/Line.png" alt=""></div>
    <div class="download">
      <span class="link">Download</span>
    </div>
  </div>
</template>

<!--script section component-->
<script>
import TheDropNotifications from "../../innerpage/Notfications/the-drop-notifications";
export default {
  name: "The-navbar",
  components: {TheDropNotifications},
  data() {
    return {
      show: false,

      //navitem data
      navItems: [
        {
          name: "market",
          route: "/market",
          arrow: false
        },
        {
          name: "trade",
          route: "/trade",
          arrow: true
        },
        {
          name: 'derivatives',
          route: "/Derivatives",
          arrow: true
        },
        {
          name: 'finance',
          route: "/finance",
          arrow: true
        },

      ],

      Token: {name: 'Hadi'},

      showNotif:false
    }
  },

  created() {
    //close header div function
    window.addEventListener("click", (event) => {
      if (!event.target.closest('.main-navbar')) {
        this.show = false
      }
    })
  },

}
</script>

<!--style section component-->
<style lang="scss" scoped>
//--------------------Animation style
.slide-enter-active {
  animation: slide-in 1000ms ease-out forwards;

}

.slide-leave-active {
  animation: slide-out 1000ms ease-out forwards;
}

@keyframes slide-out {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100vw);
  }
}

@keyframes slide-in {
  from {
    transform: translateX(100vw);
  }
  to {
    transform: translateX(0);
  }
}



.notif-enter-active {
  animation: notif-in 1000ms ease-out forwards;

}

.notif-leave-active {
  animation: notif-out 1000ms ease-out forwards;
}

@keyframes notif-out {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-100vh);
  }
}

@keyframes notif-in {
  from {
    transform: translateY(-100vh);
  }
  to {
    transform: translateY(0);
  }
}

//general class
.link {
  color: white;
  text-decoration: none;
  cursor: pointer;
}

//styles
.main-navbar {
  display: flex;
  flex-direction: row;
  background-color: var(--main-background-color);
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;

  //mobile menu
  .Humberger-menu {
    display: flex;
    justify-content: end;
    flex-grow: 1;
    gap: 20px;

    img {
      width: 40px;
      cursor: pointer;
    }
  }

  .login-mobile {
    flex-direction: row;
    display: flex;
    gap: 10px;
    align-items: center;
    font-size: clamp(12px, 2vw, 16px);

    .link {
      color: white;
      text-decoration: none;
      cursor: pointer;
    }


    .button-group-mobile {
      width: 60%;
    }
  }

  .account-mobile {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    gap: 30px;
    margin-right: 10px;

    span {
      color: white;
      cursor: pointer;
    }

    img {
      cursor: pointer;
      width: 20px;
      height: 20px;
    }
  }

  .logo {
    flex-grow: 1;
    cursor: pointer;
  }

  .menu {
    flex-grow: 0;
    cursor: pointer;
  }

  .items {
    display: flex;
    flex-grow: 3;

    ul {
      display: flex;
      gap: 32px;

      .first-item {
        position: relative !important;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        gap: 10px;

        div {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;

          .badg {

          }

          .USD-SPAN {
            position: absolute;
            left: 6px;
            font-size: 12px;
            bottom: 3px;
          }
        }

        .arrow-bt {
          position: absolute;
          top: 50%;
          left: 105%;
          cursor: pointer;
        }
      }

      li {
        color: white;
        font-weight: 400;
        text-decoration: none;
        list-style: none;
        position: relative;
        cursor: pointer;
        font-size:  clamp(12px, 2vw, 16px);
        width: max-content;

        .arrow-nav-bottom {
          position: absolute;
          top: 50%;
          padding-left: 3px;
          cursor: pointer;
        }

        .golden-badg {
          fill: var(--golden-color) !important;
          position: absolute;
          top: 30%;
        }

        .solid-badg {
          fill: rgba(242, 242, 242, 1) !important;
          position: absolute;
          top: 30%;
        }
      }
    }
  }

  .login {
    flex-grow: 0;
    flex-direction: row;
    display: flex;
    gap: 10px;
    align-items:center;
    justify-content: space-between;


    .button-group {

      button {
        color: white;
      }
    }
  }

  .account {
    display: flex;
    flex-grow: 2;
    flex-direction: row;
    justify-content: space-between;
    position: relative;

    span {
      color: white;
      cursor: pointer;
    }

    img {
      cursor: pointer;
    }

    .walet {
      position: relative;

      img {
        position: absolute;
        top: 50%;
        margin-left: 5px;
      }
    }
  }

  .line {
    flex-grow: 1;
    display: flex;
    justify-content: center;
  }

  .download {
    flex-grow: 1;
  }
}

.list {
  display: flex;
  flex-direction: column;
  padding: 10px;
  position: fixed;
  background-color: var(--main-background-color);
  top: 0;
  z-index: 20;
  height: 100vh;
  width: 50vw;
  right: 0;

  .close-img {
    width: 15px;
    height: 15px;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }

  .close-window {
    width: 15%;
    cursor: pointer;
  }

  ul {
    gap: 25px;
    padding: 0;

    li {
      list-style: none;
      font-size: medium;
      cursor: pointer;
      padding: 20px;
      display: flex;
      justify-content: center;
      align-items: center;

      a {
        color: white;
        text-decoration: none;
      }
    }
  }
}

//media query

@media (min-width: 1200px) {
  .Humberger-menu {
    display: none !important;
  }
}

@media (min-width: 992px) and (max-width: 1050px) {
  .arrow-bt {
    left: 100% !important;
  }

  .arrow-nav-bottom {
    padding-left: 3px !important;
  }

  .Humberger-menu {
    display: none !important;
  }
}

@media (min-width: 900px) and (max-width: 1200px) {
  .line {
    display: none !important;
  }

  .download {
    display: none !important;
  }

  .account {
    display: flex !important;
    justify-content: center !important;
    gap: 20px !important;
  }

  .button-group {
    width: 60% !important;
  }

  .logo {
    flex-grow: 0 !important;
  }

  .login {
    justify-content: center !important;
  }

  .items {
    flex-basis: 100px !important;
  }

  .menu {
    display: none;
  }

  .Humberger-menu {
    display: none !important;
  }
}

@media (max-width: 900px) {
  .items {
    display: none !important;
  }

  .line {
    display: none !important;
  }

  .download {
    display: none !important;
  }

  .account {
    display: none !important;
  }

  .button-group {
    display: none !important;
  }

  .login {
    display: none !important;
  }

  .menu {
    display: none !important;
  }

  .main-drop-notif{
    top: 5% !important;
    right: 0 !important;
  }
}




@media (max-width: 360px){
  .Humberger-menu{
    gap: 0 !important;
  }

  .main-drop-notif{
    top: 5% !important;
    right: 0 !important;
    left: 0;
    width: auto;
  }
}
</style>
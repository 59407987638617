<template>
  <div class="main-faq">
    <the-header value="Frequently Asked Questions"/>
    <div class="main">
      <!--drop down section-->
      <the-dropdown v-for="(item , index) in faq" :key="index" :data="item"/>
    </div>
  </div>
</template>

<!--script components-->
<script>
import TheHeader from "../tools/The-header";
import TheDropdown from "../tools/the-dropdown";

export default {
  name: "the-faq",
  components: {TheDropdown, TheHeader},
  data() {
    return {
      //this drop down data
      faq: [],
      showmessage: [],
      messageheight: [],
      messages: [],
    }
  },

  methods:{
    async getFaq() {
      this.faq = await this.$axios.get('/faq');
    }
  },

  mounted() {
    this.getFaq()
  }
}
</script>

<!--style components-->
<style lang="scss" scoped>
.main-faq {
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: #E5E5E5;

  .main {
    width: 90%;
    margin: 20px auto;

  }
}
</style>
<template>
  <div class="Credit-debit-Card">

    <div class="header">
      <img @click="$router.push({name:'Exchange'})" class="back-arrow" src="../../assets/public/close.png" alt="">
      <span class="title">
          Pay with Credit/debit Card
        </span>
    </div>

    <div class="box-input">
      <div class="row">
        <div class="info">
          <img :src="require('../../assets/coins/'+dataresive.logo)" alt="">
          <span class="coin-name">{{ dataresive.name }}</span>
        </div>
        <div class="value">
          <span>{{ dataresive.value }}</span>
        </div>
      </div>
      <div class="row">
        <img src="../../assets/public/right-arrow.png" alt="">
      </div>
      <div class="row">
        <div class="info">
          <img :src="require('../../assets/coins/'+dataspend.logo)" alt="">
          <span class="coin-name">{{ dataspend.name }}</span>
        </div>
        <div class="value">
          <span>{{ dataspend.value }}</span>
        </div>
      </div>
    </div>

    <div class="card-info">
      <span class="title">Visa / Mastercards</span>
      <div class="bottom-row">
        <div class="carduser">
          <div v-if="state.userAddcard === false" class="addcard">
            <img @click="selectcard = 'MasterCard'"
                 :class="{'card-select-MasterCard' : selectcard === 'MasterCard'}"
                 src="../../assets/cards/MasterCard.png"
                 alt="">

            <img @click="selectcard = 'VisaCard'"
                 :class="{
              'card-select-VisaCard' : selectcard === 'VisaCard' }"
                 src="../../assets/cards/VisaCard.png"
                 alt="">
          </div>
          <div v-if="state.userAddcard === true" class="selectcard">
            <img src="../../assets/cards/MasterCard.png" alt="">
            <span class="number-card">****1825</span>
          </div>
        </div>
        <div class="btn">
          <button @click="$router.push({name:'AddCard'})" v-if="state.userAddcard === false" class="gold-btn btn-custom">Add New card</button>
          <span v-if="state.userAddcard === true" class="change-card">Change Card</span>
        </div>
      </div>
    </div>
    <button class="gold-btn">Buy</button>
  </div>
</template>

<script>
export default {
  name: "the-credit-debit-card",
  data() {
    return {
      dataspend: this.state.stateDataSpend,
      dataresive: this.state.stateDataResive,

      cardobjet: [
        {logo: "MasterCard.png", name: "MasterCard"},
        {logo: "VisaCard.png", name: "VisaCard"},
      ],

      selectcard: ""
    }
  }
}
</script>

<style lang="scss" scoped>
.Credit-debit-Card {
  background-color: #FFFFFF;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  padding: 16px;
  width: auto;
  gap: 32px;
  align-items: center;

  .title {
    color: rgba(72, 77, 86, 1);
    font-size: clamp(14px, 2vw, 20px);
    font-weight: 600;
    width: max-content;
  }


  .gold-btn {
    color: #FFFFFF;
  }

  .btn-custom{
    height: 36px;
    display: flex;
    align-items: center;
    width: fit-content;
  }

  .header {
    display: flex;
    flex-direction: row;
    column-gap: 70px;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
    flex-wrap: nowrap;


    .back-arrow {
      cursor: pointer;
      position: absolute;
      left: 0;
    }
  }

  .coin-name {
    color: rgba(72, 77, 86, 1);
    font-size: clamp(12px, 2vw, 14px);
  }

  .box-input {
    width: 100%;
    background-color: rgba(242, 242, 242, 1);
    display: flex;
    flex-direction: row;
    gap: 24px;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    column-gap: 60px;
    padding: 24px 60px;

    .row {
      display: flex;
      flex-direction: column;
      gap: 10px;
      justify-content: center;
      align-items: center;

      .info {
        display: flex;
        flex-direction: row;
        gap: 10px;
        justify-content: center;
        align-items: center;
      }

      .value {
        color: rgba(72, 77, 86, 1);
        font-size: clamp(15px, 2vw, 17px);
        font-weight: bold;
      }
    }
  }

  .card-info {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;

    .bottom-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .selectcard {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;

        .number-card {
          color: rgba(72, 77, 86, 1);
          font-weight: bold;
        }
      }

      .addcard {
        display: flex;
        flex-direction: row;
        gap: 10px;
      }

      .card-select-MasterCard {
        border: 1px solid #4613A3;
        border-radius: 5px;
      }

      .card-select-VisaCard {
        border: 1px solid #4613A3;
        border-radius: 5px;
      }
    }

    .change-card {
      color: #C2A748;
      position: relative;
      right: 20px;

      &:after {
        content: "";
        width: 10px;
        height: 10px;
        background-position: right center;
        position: absolute;
        background-repeat: no-repeat;
        top: 25%;
        right: -18px;
      }

    }
  }
}

@media (max-width: 460px){
  .box-input{
    column-gap: 25px !important;
    padding: 10px !important;
  }

  .btn-custom{
    width: 85px;
  }
}
</style>
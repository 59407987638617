<template>
  <div class="reset">
    <div class="header-auth">
      <p>Reset Your Password</p>
    </div>
    <p class="text">Enter your account’s email.</p>
    <the-input @updateValue="emailvalue" value="Email" type="text" :errorText="emailError"/>
    <button :disabled="email === ''" :class="{'disable':email === ''}" @click="send" class="gold-btn">Next</button>
  </div>
</template>

<script>
import TheInput from "../../tools/the-input";

export default {
  name: "the-forgetpassword",
  components: {TheInput},

  data() {
    return {
      email: '',
      emailError: '',

    }
  },


  methods:{

    //get email value
    emailvalue(value){
      this.email = value
    },

    //valid email
    validEmail(email) {
      let re = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
      return re.test(email);
    },
    receive(e) {
      this.email = e
      this.checkEmail(e)
    },

    //check email
    checkEmail() {
      if (!this.email) {
        this.emailError = 'Mandatory email';
      } else if (!this.validEmail(this.email)) {
        this.emailError = 'Enter the correct email';
      } else {
        this.emailError = ''
      }
      if (!this.emailError.length) {
        return true;
      }
    },

    //send data
    async send() {
      if (this.checkEmail(this.email)) {
        await this.checkEmail()
        this.state.loading = true
        const res = await this.$axios.post('/users/forget-password?email=' + this.email)
        this.$cookies.set('username', this.email)
        this.state.userInfo.email = this.email
        if (res.baseDTO) {
          this.state.userInfo.mobilenumber = res.baseDTO.starTwoStepAuthentication || '';
          this.state.userInfo.towStepAuthenticationType = res.baseDTO.towStepAuthenticationType;
        } else {
          this.state.userInfo.towStepAuthenticationType = 'EMAIL'
        }
        await this.$router.push({name:'OtpLogin'})
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.reset{
  display: flex;
  flex-direction: column;
  gap: 10px;

  .text{
    color: rgba(72, 77, 86, 1);
  }

  .gold-btn{
    color: white;
    margin: 20px 0;
  }

  .disable{
    opacity: 0.7;
    cursor: not-allowed;
  }
}
</style>
<template>
  <the-public-modal @close="$emit('close')">
    <div class="top">
      <img class="icon-alert" src="../../assets/public/Attention_perspective_matte.png" alt="">
      <span class="Alert">Alert</span>
    </div>

    <div>
      <p class="text-alert">Plugin construction is in progress, please refer at another time</p>
    </div>
  </the-public-modal>
</template>

<script>
import ThePublicModal from "./the-public-modal";
export default {
  name: "the-alert-modal",
  components: {ThePublicModal}
}
</script>

<style scoped>
.icon-alert{
  width: 50px;
}

.text-alert{
  font-size: clamp(15px , 2vw , 18px);
  font-weight: bold;
}

.Alert{
  font-size: clamp(15px , 2vw , 18px);
  font-weight: bold;
}

.top{
  display:flex;
  align-items: center;
}
</style>
<template>
  <div class="item bottom-table">
    <the-header-table selected="Market Trades" :items="itemsHeader" @selectitem="showtable"/>

    <table :key="type" v-if="show === 'Market Trades'">
      <tr>
        <th class="left-align">Price(USDT)</th>
        <th class="center-align">Amount(BTC)</th>
        <th class="right-align">Time</th>
      </tr>

      <tr v-for="(item , index) in dataTable" :key="index">
        <td :class="{'red' : item.wholePrice <= 0 , 'green':item.wholePrice > 0}" class="left-align">{{
            item.wholePrice.toLocaleString()
          }}
        </td>
        <td class="center-align">{{ item.amount.toLocaleString() }}</td>
        <td class="right-align">{{ $G2J(item.createdAtDateTime, 'time') }}</td>
        <td></td>
      </tr>

      <tr v-if="!dataTable.length">
        <td colspan="8">
          <p class="no-data"> Custom is not registered. </p>
        </td>
      </tr>

    </table>


    <table :key="type" v-if="show === 'My Trades'">
      <tr>
        <th class="left-align">Price(USDT)</th>
        <th class="center-align">Amount(BTC)</th>
        <th class="right-align">Time</th>
      </tr>

      <tr v-for="(item , index) in dataTable" :key="index">
        <td :class="{'red' : item.unitPrice <= 0 , 'green':item.unitPrice > 0}" class="left-align">{{
            item.unitPrice.toLocaleString()
          }}
        </td>
        <td class="center-align">{{ item.amount.toLocaleString() }}</td>
        <td class="right-align">{{ $G2J(item.createdAtDateTime, 'time') }}</td>
      </tr>

      <tr v-if="!dataTable.length">
        <td colspan="8">
          <p class="no-data"> Custom is not registered. </p>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import TheHeaderTable from "../../../../tools/the-headerTable";


export default {
  name: "bottom-table",

  components: {TheHeaderTable},

  data() {
    return {
      showTable: "Open Orders",
      show: "Market Trades",
      data: "",
      type: "",
      dataTable:[],
      itemsHeader: ["Market Trades", "My Trades"],
    }
  },


  methods: {

    //FILTERS TABLE
    showtable(e) {
      this.show = e;

      if (e === 'Market Trades') {
        this.getAllTrades()
      }


      if (e === 'My Trades') {
        this.getMyTrades()
      }

    },

    //GET MARKET TYPE
    marketType() {
      this.type = this.$coin2Snake[this.$route.params.coin] + '_TETHER';
    },

    //GET ALL MARKET TRADES
    async getMyTrades() {
      this.state.loading = true
      const res = await this.$axios.get('/trades', {
        params: {
          marketType: this.type,
          type:'BUY'
        }
      })
      this.dataTable = res.content
      this.state.loading = false
    },

    //GET ALL MY TRADES
    async getAllTrades() {
      this.state.loading = true
      const res = await this.$axios.get('/trades', {
        params: {
          marketType: this.type,
          type:'BUY'
        },

        headers: {
          Authorization: ''
        },

      })
      this.dataTable = res.content
      this.state.loading = false
    },

  },


  mounted() {
    this.marketType()
    this.showtable('Market Trades')

  }
}
</script>

<style lang="scss" scoped>
.red {
  color: #cc2d6a;
}

.green {
  color: #00A35F;
}

td {
  color: rgba(64, 64, 64, 1);
  padding: 5px !important;
  font-size: 12px;

}

table {
  margin: 10px auto;
  width: 93%;
  table-layout: auto;
  overflow: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.bottom-table {
  flex-grow: 2;
  table-layout: fixed;
  overflow: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  table {
    font-size: clamp(12px, 1vw, 14px);

    th {
      color: rgba(189, 189, 189, 1);
      font-size: clamp(10px, 1vw, 12px);
    }
  }
}

.no-data {
  color: #cc2d6a;
  font-weight: bold;
  text-align: center;
  width: 100%;
}
</style>
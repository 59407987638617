<template>
  <div class="main-modal">
    <div class="layer">
      <div class="modal">
        <slot></slot>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  props: ['value'],
  name: "the-modal",
  data() {
    return {}
  }
}
</script>

<style scoped>
.main-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  inset: 0;
  height: 100vh;
  background-image: url("../../assets/public/modal-bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.layer{
  display: flex;
  justify-content: center;
  align-items: center;
  inset: 0;
  height: 100vh;
  background-image: linear-gradient(180deg, #4613A3 0%, rgba(70, 19, 163, 0.4) 133.64%);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 10;
}

.modal {
  background-color:white;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  width:33%;
  padding: 10px;
  height: auto;
  position: relative;

}

@media (max-width: 992px) {
  .modal{
    width: 90%;
  }
}

</style>